import DocumentTitle from 'react-document-title';
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { selectCurrentLogbookDefects } from '../../store/features/logbooks.slice';
import DefectList from '../common/defect-list';
import userAddIcon from '../../assets/images/user-add.svg';
import userAddWhiteIcon from '../../assets/images/user-add-white.svg';
import { useState } from 'react';
import MoreMenu, {
	ADD_DEFECT_CLAIMS_RECIPIENT_MENU_ITEM,
	ADD_DEFECT_MENU_ITEM,
} from '../../components/more-menu';
import SetupBlock from '../../components/setup-block';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyRoutes } from '../../routes';
import ToggleOwnerAbilityToFileDefects from '../../components/toggle-defect-filing-ability';

export default function CompanyLogbookDefects() {
	const { logbookId } = useParams();
	const defects = useSelector(selectCurrentLogbookDefects);
	const [isHovering, setIsHovering] = useState(false);
	const navigate = useNavigate();

	const menuItems = [
		ADD_DEFECT_MENU_ITEM,
		ADD_DEFECT_CLAIMS_RECIPIENT_MENU_ITEM,
	];

	const addNewDefect = () => {
		navigate(
			'/' +
				CompanyRoutes.basePath.replace('/*', '') +
				'/' +
				CompanyRoutes.logbook.replace(':logbookId', logbookId) +
				'/' +
				CompanyRoutes.addLogbookDefect
		);
	};

	const actionSelectedMenuOption = (value) => {
		switch (value) {
			case ADD_DEFECT_MENU_ITEM.value: {
				// add defect
				addNewDefect();
				break;
			}

			case ADD_DEFECT_CLAIMS_RECIPIENT_MENU_ITEM.value: {
				//
				break;
			}

			default:
				break;
		}
	};

	return (
		<DocumentTitle title="Logbook Issue Log | inndox">
			<Container className="folder">
				<Row className="margin-bottom-2">
					<Col xs={8} sm={6} className="flex">
						<Hidden xs>
							<h2 className="name">Logbook Issue Log</h2>
						</Hidden>

						<Visible xs>
							<h2 className="name">Logbook Issue Log</h2>
						</Visible>
					</Col>
					<Col xs={4} sm={6} className="flex end">
						<Hidden xs>
							<button
								className="button button-secondary"
								onMouseEnter={() => setIsHovering(true)}
								onMouseLeave={() => setIsHovering(false)}
								onClick={() =>
									navigate(
										CompanyRoutes.addRecipientForLogbookDefects.replace('defects/', '')
									)
								}>
								<img
									src={isHovering ? userAddWhiteIcon : userAddIcon}
									alt="user-add"
									style={{ marginRight: 8 }}
								/>
								Issue log recipient
							</button>
							<button
								className="button button-primary margin-left-1"
								onClick={addNewDefect}>
								New issue
							</button>
						</Hidden>

						<Visible xs>
							<MoreMenu options={menuItems} onSelect={actionSelectedMenuOption} />
						</Visible>
					</Col>

					<Col xs={12} className="margin-top-2">
						<ToggleOwnerAbilityToFileDefects />
					</Col>
				</Row>

				{/* Defects List */}
				{defects.length ? (
					<DefectList defects={defects} isCurrentUserACompany source="logbook" />
				) : (
					<SetupBlock description="This logbook has no filed issues on record." />
				)}
			</Container>
		</DocumentTitle>
	);
}
