import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentTemplate } from '../../store/features/templates.slice';
import LogbookFolder from '../common/logbook-folder';

export default function TemplateFolder() {
	const { folderId: folderIdPathParam } = useParams();
	const folderId = parseInt(folderIdPathParam);
	const logbook = useSelector(selectCurrentTemplate);
	const folder = logbook.folders.find(
		(logbookFolder) => logbookFolder.id === folderId
	);
	const documents = logbook.documents.filter(
		(document) => document.folderId === folderId
	);

	return (
		<LogbookFolder folder={folder} documents={documents} isInsideATemplate />
	);
}
