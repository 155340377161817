import moment from "moment";
import { useState } from "react";
import { Col, Row } from "react-grid-system";
import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { PaymentsApi } from "../api/payments";
import {
  selectPaymentDetails,
  updatePaymentDetails,
} from "../store/features/subscription.slice";
import { isCurrentUserACompany } from "../store/features/user.slice";
import Modal from "./modal";
import cancelSubscriptionIcon from "../assets/images/cancel-subscription.svg";
import Select from "./form/select";
import subscriptionIcon from "../assets/images/subscription.svg";
import { AccountRoutes } from "../routes";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import TextField from "./form/text-field";
import { handleError } from "./helpers";
import { formatNumberInCurrency } from "../helpers/currency";

export default function ManageSubscription(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentDetails = useSelector(selectPaymentDetails);
  const [isManagingPlan, setIsManagingPlan] = useState(false);
  const [isPlanningToCancelSubscription, setIsPlanningToCancelSubscription] =
    useState(false);
  const [isReactivatingSubscription, setIsReactivatingSubscription] =
    useState(false);
  const isCompany = useSelector(isCurrentUserACompany);
  const hasNeverSubscribed = paymentDetails.planStatus === "None";
  const isOverdue =
    paymentDetails.planStatus === "Active" &&
    !!paymentDetails.nextPaymentOn &&
    paymentDetails.subscriptionDaysRemaining > 0;

  // methods
  const reactivateSubscription = async () => {
    setIsReactivatingSubscription(true);
    try {
      const updatedPaymentDetails = await PaymentsApi.updateSubscription({
        subscribeToPlan: true,
        interval: paymentDetails.planInterval,
      });

      dispatch(updatePaymentDetails(updatedPaymentDetails));
      setIsReactivatingSubscription(false);
    } catch (e) {
      setIsReactivatingSubscription(false);
      console.error("There was an error cancelling your subscription");
    }
  };

  const hasCancelledSubscription = !!paymentDetails.subscriptionDaysRemaining;

  // render
  if (hasNeverSubscribed) {
    if (!paymentDetails.creditCardIsValid) {
      return (
        <button
          className="button button-primary"
          onClick={() =>
            navigate(
              "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.addPaymentDetails
            )
          }
        >
          {props.text || "Subscribe"}
        </button>
      );
    } else {
      return (
        <button
          className="button button-primary"
          onClick={reactivateSubscription}
        >
          <ClipLoader
            loading={isReactivatingSubscription}
            size={16}
            color="#fff"
          />
          {isReactivatingSubscription ? null : props.text || "Subscribe"}
        </button>
      );
    }
  }

  if (isOverdue) {
    /**
     * For overdue, they need to update their credit card details
     * which when complete will automatically renew their plan.
     */
    return <noscript />;
  }

  const planMonthlyPriceAfterDiscount =
    Math.round(
      paymentDetails.planMonthlyPrice *
        (1 - paymentDetails.discount / 100) *
        100
    ) / 100;
  const planAnnualPriceAfterDiscount =
    Math.round(
      paymentDetails.planAnnualPrice * (1 - paymentDetails.discount / 100) * 100
    ) / 100;

  return (
    <>
      <p
        className={props.className || "button button-secondary-link"}
        style={{ padding: 0 }}
        onClick={() => setIsManagingPlan(true)}
      >
        {props.text || "Manage"}
      </p>

      {/* Manage Modal */}
      <Modal
        isOpen={isManagingPlan}
        onClose={() => setIsManagingPlan(false)}
        title={
          <>
            <img
              src={subscriptionIcon}
              style={{ marginRight: 8 }}
              alt="subscription icon"
            />

            {hasCancelledSubscription
              ? "Reactivate my subscription"
              : "Subscriptions"}
          </>
        }
      >
        {hasCancelledSubscription ? (
          <Row>
            <Col xs={12} className="text-center">
              {isCompany ? (
                <p>
                  Reactivate your account and start back on the{" "}
                  <b>{paymentDetails.plan} plan</b> for{" "}
                  <b>
                    {paymentDetails.planCurrency}{" "}
                    {paymentDetails.planInterval === "Annual"
                      ? formatNumberInCurrency(
                          planAnnualPriceAfterDiscount,
                          paymentDetails.planCurrency
                        )
                      : formatNumberInCurrency(
                          planMonthlyPriceAfterDiscount,
                          paymentDetails.planCurrency
                        )}
                    /
                    {paymentDetails.planInterval === "Annual"
                      ? "year"
                      : "month"}
                  </b>{" "}
                  {paymentDetails.planLimit ? (
                    paymentDetails.planLimit > 1 ? (
                      <>
                        for up to <b>{paymentDetails.planLimit} logbooks</b>.
                      </>
                    ) : (
                      <>
                        for up to <b>{paymentDetails.planLimit} logbook</b>.
                      </>
                    )
                  ) : (
                    "."
                  )}
                </p>
              ) : (
                <p>
                  Reactivate your account and start back on the{" "}
                  <b>Standard plan</b> for <b>$19/month</b> for up to{" "}
                  <b>10 logbooks</b>.
                </p>
              )}

              <button
                className="button button-primary margin-top-3"
                onClick={reactivateSubscription}
              >
                {isReactivatingSubscription ? (
                  <ClipLoader loading color="#fff" size={16} />
                ) : (
                  "Reactivate my subscription"
                )}
              </button>

              <p
                className="helper-text margin-top-3"
                style={{ color: "#2d3540", lineHeight: "22px", fontSize: 14 }}
              >
                Your card will be billed {paymentDetails.planCurrency}{" "}
                {paymentDetails.planInterval === "Annual"
                  ? formatNumberInCurrency(
                      planAnnualPriceAfterDiscount,
                      paymentDetails.planCurrency
                    )
                  : formatNumberInCurrency(
                      planMonthlyPriceAfterDiscount,
                      paymentDetails.planCurrency
                    )}
                /{paymentDetails.planInterval === "Annual" ? "year" : "month"}.
                Payments cover access for both the company and the owner. Find
                out more in our{" "}
                <a
                  className="link-style-elem"
                  href="https://www.inndox.com/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and conditions
                </a>
                .
              </p>
            </Col>
          </Row>
        ) : (
          <>
            <p>Manage your subscription tied to this account</p>

            <Row className="margin-top-2">
              <Col xs={5}>
                <b>Plan</b>
              </Col>

              <Col xs={7}>
                <p>{paymentDetails.plan}</p>
              </Col>
            </Row>
            <Row className="margin-top-1">
              <Col xs={5}>
                <b>Billing Cycle</b>
              </Col>

              <Col xs={7}>
                <p>
                  <span>{paymentDetails.planInterval}</span>
                </p>
              </Col>
            </Row>
            <Row className="margin-top-1 margin-bottom-4">
              <Col xs={5}>
                <b>Next payment</b>
              </Col>
              <Col xs={7}>
                <span>
                  {" " +
                    moment(paymentDetails.nextPaymentOn).format(
                      "MMMM D, YYYY"
                    ) +
                    ` - ${paymentDetails.planCurrency} ` +
                    formatNumberInCurrency(
                      paymentDetails.nextPaymentAmount,
                      paymentDetails.planCurrency
                    )}
                </span>
                <button
                  className="button button-alt margin-top-3"
                  onClick={() => {
                    setIsPlanningToCancelSubscription(true);
                    setIsManagingPlan(false);
                  }}
                >
                  Cancel subscription
                </button>
              </Col>
            </Row>
          </>
        )}
      </Modal>

      {/* Cancel Modal */}
      <Modal
        isOpen={isPlanningToCancelSubscription}
        onClose={() => setIsPlanningToCancelSubscription(false)}
        title={
          <>
            <img
              src={cancelSubscriptionIcon}
              alt="cancel subscription"
              style={{ marginRight: 8 }}
            />
            Cancel your subscription
          </>
        }
      >
        <p>We are sorry to see you go. We hope that you will be back soon.</p>

        <Form
          onSubmit={async (values) => {
            try {
              const updatedPaymentDetails =
                await PaymentsApi.cancelSubscription(values);
              dispatch(updatePaymentDetails(updatedPaymentDetails));
              setIsPlanningToCancelSubscription(false);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <Select
                name="cancelReason"
                label="Why have you decided to cancel?"
                className="margin-top-2"
                items={[
                  {
                    label: "I have another Inndox account",
                    value: "Another",
                  },
                  {
                    label: "I am temporarily cancelling, but plan to come back",
                    value: "Temporary",
                  },
                  {
                    label: "I am going to use another service",
                    value: "Competitor",
                  },
                  {
                    label: "I no longer need Inndox",
                    value: "Unnecessary",
                  },
                  {
                    label: "Other",
                    value: "Other",
                  },
                ]}
              />

              {props.values.cancelReason === "Other" ? (
                <TextField
                  name="otherCancelReason"
                  label="Specify a reason"
                  className="margin-top-2"
                />
              ) : (
                <noscript />
              )}

              <p className="margin-top-3">
                <strong>Need to save your files?</strong>
              </p>
              <p>Head to your logbook to download all the files you uploaded</p>

              <button
                className="button button-danger margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Cancel my subscription"
                )}
              </button>
            </>
          )}
        />

        <p className="margin-top-2" style={{ fontSize: 14 }}>
          {isCompany
            ? "If your account is not re-activated within 30 days. You will lose access to the completed handover logbooks and will be taken over by the owner. You can come back and re-activate at any time."
            : "You can come back and re-activate at any time."}
        </p>
      </Modal>
    </>
  );
}
