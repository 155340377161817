import DocumentTitle from 'react-document-title';
import { Col, Container, Row } from 'react-grid-system';
import { Route, Routes } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { SharedRoutes } from '.';
import useOwnerData from '../hooks/owner-data';
import AddDocuments from '../pages/common/add-documents';
import AddOrEditLink from '../pages/common/add-or-edit-link';
import SharedLogbookFolder from '../pages/shared/folder';
import SharedLogbooksHome from '../pages/shared/home';

export default function SharedSiteMap() {
	const isFetchingData = useOwnerData();

	if (isFetchingData) {
		return (
			<DocumentTitle title="Loading...">
				<Container>
					<Row>
						<Col xs={12} className="flex center">
							<ClipLoader loading size={16} color="#bdd23f" />
						</Col>
					</Row>
				</Container>
			</DocumentTitle>
		);
	}

	return (
		<Routes>
			<Route path={SharedRoutes.home} element={<SharedLogbooksHome />} />
			<Route path={SharedRoutes.folder} element={<SharedLogbookFolder />} />
			<Route path={SharedRoutes.addDocuments} element={<AddDocuments />} />
			<Route path={SharedRoutes.addLink} element={<AddOrEditLink />} />
		</Routes>
	);
}
