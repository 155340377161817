import DocumentTitle from 'react-document-title';
import { Col, Container, Row } from 'react-grid-system';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { TemplateRoutes } from '.';
import { useTemplateData } from '../hooks/template-data';
import TemplateDetails from '../pages/company/template';
import TemplateContacts from '../pages/company/template-contacts';
import TemplateFolder from '../pages/company/template-folder';
import TemplateMaintenanceItems from '../pages/company/template-maintenance-items';
import AddDocuments from '../pages/common/add-documents';
import AddOrEditTemplateContact from '../pages/company/add-or-edit-template-contact';
import ViewContact from '../pages/common/view-contact';
import AddFolder from '../pages/common/add-folder';
import AddOrEditLink from '../pages/common/add-or-edit-link';
import AddOrEditTemplateMaintenanceItem from '../pages/company/add-or-edit-template-maintenance-item';
import ViewTemplateMaintenanceItem from '../pages/company/view-template-maintenance-item';
import { selectCurrentTemplate } from '../store/features/templates.slice';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PersistentFooter = styled.div`
	background-color: #000;
	position: sticky;
	bottom: 0;
`;

export default function TemplatesSiteMap() {
	const { templateId: templateLogbookIdPathParam } = useParams();
	const templateLogbookId = parseInt(templateLogbookIdPathParam);
	const isFetchingData = useTemplateData(templateLogbookId);
	const templateLogbook = useSelector(selectCurrentTemplate);

	if (isFetchingData) {
		return (
			<DocumentTitle title="Loading...">
				<Container>
					<Row>
						<Col xs={12} className="flex center">
							<ClipLoader loading size={16} color="#bdd23f" />
						</Col>
					</Row>
				</Container>
			</DocumentTitle>
		);
	}

	return (
		<>
			<Routes>
				{/* Template details */}
				<Route path="" element={<TemplateDetails />} />
				{/* Add document */}
				<Route
					path={TemplateRoutes.addDocumentsToTemplate}
					element={<AddDocuments />}
				/>
				<Route path={TemplateRoutes.addTemplateLink} element={<AddOrEditLink />} />
				{/* folder */}
				<Route path={TemplateRoutes.newTemplateFolder} element={<AddFolder />} />
				<Route path={TemplateRoutes.templateFolder} element={<TemplateFolder />} />
				<Route
					path={TemplateRoutes.addDocumentsToTemplateFolder}
					element={<AddDocuments />}
				/>
				<Route
					path={TemplateRoutes.addTemplateFolderLink}
					element={<AddOrEditLink />}
				/>
				{/* Contacts */}
				<Route
					path={TemplateRoutes.templateContacts}
					element={<TemplateContacts />}
				/>
				<Route
					path={TemplateRoutes.addTemplateContact}
					element={<AddOrEditTemplateContact />}
				/>
				<Route
					path={TemplateRoutes.viewTemplateContact}
					element={<ViewContact />}
				/>
				<Route
					path={TemplateRoutes.editTemplateContact}
					element={<AddOrEditTemplateContact />}
				/>
				{/* Maintenance */}
				<Route
					path={TemplateRoutes.templateMaintenanceItems}
					element={<TemplateMaintenanceItems />}
				/>
				<Route
					path={TemplateRoutes.addTemplateMaintenanceItem}
					element={<AddOrEditTemplateMaintenanceItem />}
				/>
				<Route
					path={TemplateRoutes.viewLogbookMaintenanceItem}
					element={<ViewTemplateMaintenanceItem />}
				/>
				<Route
					path={TemplateRoutes.editTemplateMaintenanceItem}
					element={<AddOrEditTemplateMaintenanceItem />}
				/>
				{/* Redirect from everything else */}
				<Route path="*" element={<Navigate to="" />} />
			</Routes>

			<PersistentFooter id="persistent-template-footer">
				<Container className="margin-top-3">
					<Row>
						<Col xs={12} className="flex center" style={{ height: 56 }}>
							<p style={{ color: '#fff', fontSize: 14, fontWeight: 600 }}>
								You are viewing {templateLogbook.address} template
							</p>
						</Col>
					</Row>
				</Container>
			</PersistentFooter>
		</>
	);
}
