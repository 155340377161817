import { FiftyFifty } from "../../components/fifty-fifty";
import { UserSessionsApi } from "../../api/user-sessions";
import { setSession, updateUser } from "../../store/features/user.slice";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import QueryString from "query-string";
import { ClipLoader } from "react-spinners";
import TextField from "../../components/form/text-field";
import DocumentTitle from "react-document-title";
import { AccountRoutes } from "../../routes";
import { Form } from "react-final-form";
import { requiredValidator } from "../../components/form/validators";
import { useDispatch } from "react-redux";
import accountSideImage from "../../assets/images/account-side.jpg";
import { handleError } from "../../components/helpers";
import { useEffect } from "react";
import { UsersApi } from "../../api/users";
import { toast } from "react-toastify";
import Toast from "../../components/toast";
import { Col, Row } from "react-grid-system";
import { styled } from "styled-components";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function AcceptInvitation() {
  const { search } = useLocation();
  const {
    Email: email,
    Token: token,
    FirstName: firstName,
    LastName: lastName,
    Code: code,
  } = QueryString.parse(search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function checkInvitationLinkIsNotUsed() {
      try {
        const account = await UsersApi.validateToken(token, { email });
        if (account.status === "Activated") {
          toast.error(
            <Toast title="Your account is already activated. Please login to continue" />
          );
          navigate(
            "/" +
              AccountRoutes.basePath.replace("/*", "") +
              "/" +
              AccountRoutes.login
          );
        }
      } catch (e) {
        handleError(e);
      }
    }
    checkInvitationLinkIsNotUsed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DocumentTitle title="Login | inndox">
      <FiftyFifty backgroundImage={accountSideImage}>
        <Content>
          <h1>You've been invited to inndox</h1>
          <p
            className="margin-top-1"
            style={{
              maxWidth: 326,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 20,
            }}
          >
            We just need a few more details to finish creating your account
            before using inndox.
          </p>

          <Form
            initialValues={{
              firstName,
              lastName,
              email,
              code,
              token,
            }}
            onSubmit={async (values) => {
              try {
                const account = await UsersApi.acceptInvitation(
                  values.token,
                  values
                );
                const session = await UserSessionsApi.login({
                  email: values.email,
                  password: values.password,
                });

                dispatch(updateUser(account));
                dispatch(setSession(session));
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <>
                <Row style={{ marginTop: 0 }}>
                  <Col xs={6}>
                    <TextField
                      name="firstName"
                      label="First name"
                      placeholder="John"
                      className="margin-top-4"
                      type="text"
                      required
                      validate={requiredValidator}
                      autoComplete="given-name"
                    />
                  </Col>

                  <Col xs={6}>
                    <TextField
                      name="lastName"
                      label="Last name"
                      placeholder="Doe"
                      className="margin-top-4"
                      type="text"
                      required
                      validate={requiredValidator}
                      autoComplete="family-name"
                    />
                  </Col>
                </Row>

                {/* Email */}
                <TextField
                  name="email"
                  label="Email address"
                  placeholder="john@example.com"
                  className="margin-top-2"
                  disabled
                  required
                  autoComplete="email"
                />

                {/* Password */}
                <TextField
                  name="password"
                  label="Set Password"
                  className="margin-top-2"
                  placeholder="Enter a password of your choice here"
                  type="password"
                  required
                  validate={requiredValidator}
                  autoComplete="new-password"
                />

                <p className="helper-text margin-top-2">
                  By creating an account, you agree to our{" "}
                  <a
                    href="https://www.inndox.com/privacy-policy/"
                    className="link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and our{" "}
                  <a
                    href="https://www.inndox.com/terms-conditions/"
                    className="link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  .
                </p>

                <button
                  className="button button-primary button-big full-width margin-top-4 button-large flex center"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader size={16} color="#fff" loading />
                  ) : (
                    "Get started!"
                  )}
                </button>

                <p
                  className="helper-text margin-top-2"
                  style={{ textAlign: "center" }}
                >
                  Already have an account?{" "}
                  <NavLink
                    to={
                      "/" +
                      AccountRoutes.basePath.replace("/*", "") +
                      "/" +
                      AccountRoutes.login
                    }
                    className="link"
                  >
                    Log in
                  </NavLink>
                </p>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
