import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { FiftyFifty } from "../../components/fifty-fifty";
import addContactSideImage from "../../assets/images/add-contact-bg.png";
import { Col, Container, Row } from "react-grid-system";
import TextField from "../../components/form/text-field";
import Checkbox from "../../components/form/checkbox";
import { ClipLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../../routes";
import {
  emailValidator,
  requiredValidator,
} from "../../components/form/validators";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

/**
 *
 * @typedef {Object} AddOrEditContactProps
 * @property {Record<string,any>} initialValues
 * @property {(values:Record<string,any>) => Promise} onSubmit
 */

/**
 *
 * @param {AddOrEditContactProps} props
 */
export default function AddOrEditContact(props) {
  const navigate = useNavigate();
  const { pathname, key } = useLocation();
  const isAddingContact = !props.initialValues?.["id"];
  const isAddingOrEditingContactForLogbook = pathname.includes("logbooks");
  const isAddingOrEditingContactForTemplate = pathname.includes("templates");

  let backPath;
  let title;
  let description;
  if (key !== "default") {
    backPath = -1;
  }
  if (isAddingContact) {
    if (isAddingOrEditingContactForLogbook) {
      // adding to logbook
      title = "Add a Contact to Logbook";
      if (pathname.includes("company")) {
        backPath =
          backPath ||
          pathname.replace("/" + CompanyRoutes.addLogbookContact, "");
        description =
          "Easily add contacts from your account or create a new one by entering the details.";
      } else {
        backPath =
          backPath || pathname.replace("/" + OwnerRoutes.addLogbookContact, "");
        description = "Create a new one by entering the details.";
      }
    } else if (isAddingOrEditingContactForTemplate) {
      // adding to template
      title = "Add a Contact to Template";
      backPath =
        backPath || pathname.replace("/" + CompanyRoutes.addLogbookContact, "");
      description =
        "Easily add contacts to automatically add them to future logbooks created with this template";
    } else {
      // adding to company
      backPath =
        backPath || pathname.replace("/" + CompanyRoutes.addCompanyContact, "");
      title = "Add a Contact";
      description =
        "Enter contacts only once. Select them when you setup a new logbook or add them to an existing logbook.";
    }
  } else {
    // editing contact
    description = "Update contact details";

    if (isAddingOrEditingContactForLogbook) {
      title = "Edit Logbook Contact";
      if (pathname.includes("company")) {
        backPath =
          backPath ||
          pathname.replace(
            "/" +
              CompanyRoutes.editLogbookContact.replace(
                ":contactId",
                props.initialValues?.["id"]
              ),
            ""
          );
      } else {
        backPath =
          backPath ||
          pathname.replace(
            "/" +
              OwnerRoutes.editLogbookContact.replace(
                ":contactId",
                props.initialValues?.["id"]
              ),
            ""
          );
      }
    } else if (isAddingOrEditingContactForTemplate) {
      title = "Edit Template Contact";
      backPath =
        backPath ||
        pathname.replace(
          "/" +
            TemplateRoutes.editTemplateContact.replace(
              ":contactId",
              props.initialValues?.["id"]
            ),
          ""
        );
    } else {
      title = "Edit Company Contact";
      backPath =
        backPath ||
        pathname.replace(
          "/" +
            CompanyRoutes.editCompanyContact.replace(
              ":contactId",
              props.initialValues?.["id"]
            ),
          ""
        );
    }
  }

  const initialValues = props.initialValues
    ? {
        ...props.initialValues,
        isPropertyOwner: props.initialValues.contactType === "Owner",
      }
    : undefined;

  return (
    <>
      <DocumentTitle
        title={
          isAddingContact ? "Add new contact | inndox" : "Edit contact | inndox"
        }
      >
        <FiftyFifty backgroundImage={addContactSideImage}>
          <Content>
            <h1>{title}</h1>

            <p className="margin-top-1">{description}</p>

            <Form
              initialValues={initialValues}
              onSubmit={(values) => {
                const updatedValues = { ...values };

                // Update contact type
                updatedValues.contactType =
                  values.isPropertyOwner === true ? "Owner" : "Other";
                delete updatedValues["isPropertyOwner"];

                return props.onSubmit(updatedValues);
              }}
              render={({ values, handleSubmit, submitting }) => (
                <Container>
                  <Row className="margin-top-4">
                    <TextField type="hidden" name="id" />

                    <Col xs={12} md={6}>
                      {/* First name */}
                      <TextField
                        name="firstName"
                        label="First name"
                        className="margin-top-2"
                        required
                        validate={requiredValidator}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <TextField
                        name="lastName"
                        label="Last name"
                        className="margin-top-2"
                        required
                        validate={requiredValidator}
                      />
                    </Col>

                    <Col xs={12}>
                      <Checkbox
                        name="isPropertyOwner"
                        label={
                          isAddingContact
                            ? "Add as logbook owner"
                            : "Mark as logbook owner"
                        }
                        className="margin-top-1"
                      />
                    </Col>

                    <Col xs={12}>
                      <TextField
                        name="email"
                        label="Email"
                        type="email"
                        className="margin-top-2"
                        required
                        validate={emailValidator}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <TextField
                        name="phone"
                        label="Phone"
                        className="margin-top-2"
                      />
                    </Col>

                    {values.isPropertyOwner ? (
                      <noscript />
                    ) : (
                      <Col xs={12} md={6}>
                        <TextField
                          name="typeOther"
                          label="Job Description"
                          className="margin-top-2"
                        />
                      </Col>
                    )}

                    <Col xs={12}>
                      <TextField
                        name="address"
                        label="Address"
                        className="margin-top-2"
                      />
                    </Col>

                    {values.isPropertyOwner ? (
                      <noscript />
                    ) : (
                      <Col xs={12}>
                        <TextField
                          name="company"
                          label="Company"
                          className="margin-top-2"
                        />
                      </Col>
                    )}

                    <Col xs={12}>
                      <button
                        className="button button-primary button-large button-big margin-top-4 full-width"
                        onClick={submitting ? undefined : handleSubmit}
                      >
                        {submitting ? (
                          <ClipLoader loading size={16} color="#fff" />
                        ) : isAddingContact ? (
                          "Add contact"
                        ) : (
                          "Update contact"
                        )}
                      </button>

                      <button
                        className="button button-secondary-link margin-top-1 button-big button-large full-width"
                        onClick={() => navigate(backPath)}
                      >
                        Back
                      </button>
                    </Col>
                  </Row>
                </Container>
              )}
            />
          </Content>
        </FiftyFifty>
      </DocumentTitle>

      {isAddingOrEditingContactForTemplate ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `#persistent-template-footer { display: none; }`,
          }}
        />
      ) : (
        <noscript />
      )}
    </>
  );
}
