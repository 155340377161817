import { Form } from 'react-final-form';
import TextField from './form/text-field';
import { useDispatch } from 'react-redux';
import {
	selectLogbookContentSearchResults,
	setSearchResults,
} from '../store/features/logbooks.slice';
import { LogbooksApi } from '../api/properties';
import styled from 'styled-components';
import rightArrowIcon from '../assets/images/arrow-right.svg';
import { ClipLoader } from 'react-spinners';
import { Col, Row } from 'react-grid-system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import FolderList from '../pages/common/folder-list';
import { isCurrentUserACompany } from '../store/features/user.slice';
import DocumentList from '../pages/common/document-list';
import closeIcon from '../assets/images/close.svg';
import searchIcon from '../assets/images/search.svg';
import SetupBlock from './setup-block';

const ClearButton = styled.div`
	position: absolute;
	top: 3px;
	right: 3px;
	width: 48px;
	height: 48px;
	background-color: #d0d5de;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 150ms linear;
	overflow: hidden;
	margin-left: 0;
	margin-right: auto;
	cursor: pointer;
`;

const SearchIcon = styled.div`
	position: absolute;
	left: 14px;
	top: 18px;
`;

const SearchFieldContainer = styled.div`
	width: 100%;
	max-width: 375px;
	position: relative;

	@media only screen and (max-width: 576px) {
		max-width: 100%;
	}
`;

const SearchButton = styled.div`
	position: absolute;
	top: 27px;
	right: 27px;
	width: 0;
	height: 0;
	background-color: #bdd23f;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 150ms linear;
	overflow: hidden;
	cursor: pointer;

	&.visible {
		width: 48px;
		height: 48px;
		top: 3px;
		right: 3px;
	}

	img {
		max-width: 50%;
	}
`;

export default function SearchLogbookForm({
	logbookId,
	children,
	isInsideATemplate,
}) {
	const dispatch = useDispatch();
	const [isEnteringQuery, setIsEnteringQuery] = useState(false);
	const [isSearchingForFilesAndFolders, setIsSearchingForFilesAndFolders] =
		useState(false);
	const searchResults = useSelector(selectLogbookContentSearchResults);
	const isCompanyUser = useSelector(isCurrentUserACompany);

	const hasResultsAfterSearch =
		searchResults?.folders?.length || searchResults?.documents?.length;

	return (
		<Form
			onSubmit={async (values) => {
				try {
					const newSearchResults = await LogbooksApi.searchLogbookContents(
						logbookId,
						values.query
					);
					dispatch(setSearchResults(newSearchResults));
					setIsEnteringQuery(false);
				} catch (e) {}
			}}
			mutators={{
				setFieldValue: ([field, value], state, { changeValue }) => {
					changeValue(state, field, () => value);
				},
			}}
			render={(props) => (
				<>
					<Row className="margin-top-1">
						<Col xs={12} className="flex end">
							<SearchFieldContainer>
								<TextField
									name="query"
									placeholder="Search file name..."
									className="search"
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											setIsSearchingForFilesAndFolders(true);
											props.handleSubmit();
										}
									}}
									onFocus={() => setIsEnteringQuery(true)}
								/>

								<SearchIcon>
									<img src={searchIcon} alt="search" />
								</SearchIcon>

								{isEnteringQuery || props.submitting ? (
									<SearchButton
										className={props.values?.query?.length > 0 ? 'visible' : undefined}
										onClick={
											props.submitting
												? undefined
												: () => {
														setIsSearchingForFilesAndFolders(true);
														props.handleSubmit();
												  }
										}>
										{props.submitting ? (
											<ClipLoader loading size={16} color="#fff" />
										) : (
											<img src={rightArrowIcon} alt="search" />
										)}
									</SearchButton>
								) : props.values?.query?.length > 0 ? (
									<ClearButton
										onClick={() => {
											props.form.mutators.setFieldValue('query', '');
											setIsSearchingForFilesAndFolders(false);
										}}>
										<img src={closeIcon} alt="clear" />
									</ClearButton>
								) : (
									<noscript />
								)}
							</SearchFieldContainer>
						</Col>
					</Row>

					{isSearchingForFilesAndFolders ? (
						props.submitting ? (
							<Row>
								<Col xs={12} className="flex">
									<ClipLoader loading size={16} color="#bdd23f" />
								</Col>
							</Row>
						) : !hasResultsAfterSearch ? (
							<>
								<br />
								<SetupBlock description="No matching files or folders found" />
							</>
						) : (
							<>
								<FolderList
									folders={searchResults?.folders}
									logbookId={logbookId}
									isCurrentUserACompany={isCompanyUser}
									showLabel={searchResults?.folders?.length}
									canEdit
								/>

								<DocumentList
									documents={searchResults?.documents}
									showTitle={searchResults?.documents?.length}
									isInsideATemplate={isInsideATemplate}
								/>
							</>
						)
					) : (
						children
					)}
				</>
			)}
		/>
	);
}
