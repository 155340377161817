import { useParams } from 'react-router-dom';
import { selectCurrentLogbookDefects } from '../../store/features/logbooks.slice';
import { useSelector } from 'react-redux';
import AddOrEditClaim from '../common/add-or-edit-claim';

export default function AddOrEditCompanyLogbookDefect() {
	const { defectId: defectIdPathParam, logbookId: logbookIdPathParam } =
		useParams();
	const defectId = parseInt(defectIdPathParam);
	const logbookId = parseInt(logbookIdPathParam);
	const defects = useSelector(selectCurrentLogbookDefects);
	const defect = defectId
		? defects.find((defect) => defect.id === defectId)
		: undefined;

	return <AddOrEditClaim defect={defect} logbookId={logbookId} />;
}
