import DocumentTitle from "react-document-title";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { OwnerRoutes } from "../../routes";
import MaintenanceItemList from "../../components/maintenance-item-list";
import { useAuth } from "../../components/authentication";

export default function OwnerLogbookMaintenanceItems() {
  const logbook = useSelector(selectCurrentLogbook);
  const navigate = useNavigate();
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook =
    session.userId !== logbook.ownerId;

  return (
    <DocumentTitle title="Logbook Maintenance | inndox">
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={12} md={6} className="flex margin-bottom-2">
            <h2 className="name">Logbook Maintenance Items</h2>
          </Col>

          <Col xs={12} md={6} className="flex end margin-bottom-2">
            {!isCurrentUserAReadOnlyMemberForLogbook ? (
              <button
                className="button button-secondary margin-right-2"
                onClick={() =>
                  navigate(
                    "/" +
                      OwnerRoutes.basePath.replace("/*", "") +
                      "/" +
                      OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
                      "/" +
                      OwnerRoutes.addLogbookMaintenanceItem
                  )
                }
              >
                Add maintenance
              </button>
            ) : (
              <noscript />
            )}
          </Col>
        </Row>

        <MaintenanceItemList
          contacts={logbook.contacts}
          items={logbook.maintenanceItems}
          logbookIsTransferred
        />
      </Container>
    </DocumentTitle>
  );
}
