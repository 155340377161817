import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../../components/fifty-fifty";
import logbookSideImage from "../../../assets/images/bg2.jpg";
import styled from "styled-components";
import { Form } from "react-final-form";
import NewOwnerDetails from "./new-owner-details";
import { useState } from "react";
import ModifyTransferContents from "./modify-transfer-contents";
import { isCurrentUserACompany } from "../../../store/features/user.slice";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentLogbook,
  setLogbooks,
  updateCurrentLogbook,
} from "../../../store/features/logbooks.slice";
import { handleError } from "../../../components/helpers";
import { LogbooksApi } from "../../../api/properties";
import { CompanyRoutes, OwnerRoutes } from "../../../routes";
import { useNavigate } from "react-router-dom";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

/**
 * Logbook handover is different based on whether
 * an owner is transferring or a company is
 */

export default function LogbookHandover() {
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const [needsToEnterOwnerDetails, setNeedsToEnterOwnerDetails] = useState(
    isCompanyUser ? true : false
  );
  const logbook = useSelector(selectCurrentLogbook);
  const ownerContact = logbook.contacts.find(
    (contact) => contact.contactType === "Owner"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Contact Details | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <Form
            initialValues={isCompanyUser ? ownerContact : undefined}
            onSubmit={async (values) => {
              const { folderIdsToExclude = {} } = values;

              const formattedValues = isCompanyUser
                ? { ...values }
                : {
                    ...values,
                    folderIdsToExclude: Object.keys(folderIdsToExclude).map(
                      (_) => _.replace("folder-", "")
                    ),
                  };

              try {
                const updatedLogbook = await LogbooksApi.handoverLogbook(
                  logbook.id,
                  {
                    ...formattedValues,
                    type: "Owner",
                  }
                );
                dispatch(updateCurrentLogbook(updatedLogbook));

                const logbooks = await LogbooksApi.fetchLogbooks();
                dispatch(setLogbooks(logbooks));

                navigate(
                  isCompanyUser
                    ? "/" +
                        CompanyRoutes.basePath.replace("/*", "") +
                        "/" +
                        CompanyRoutes.logbook.replace(":logbookId", logbook.id)
                    : "/" +
                        OwnerRoutes.basePath.replace("/*", "") +
                        "/" +
                        OwnerRoutes.logbook.replace(":logbookId", logbook.id)
                );
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) =>
              needsToEnterOwnerDetails ? (
                <NewOwnerDetails
                  submitting={props.submitting}
                  onContinue={props.handleSubmit}
                  onBack={() => setNeedsToEnterOwnerDetails(false)}
                />
              ) : (
                <ModifyTransferContents
                  onContinue={() => setNeedsToEnterOwnerDetails(true)}
                />
              )
            }
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
