import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes, TemplateRoutes } from '../../routes';
import MaintenanceItemList from '../../components/maintenance-item-list';
import { selectCurrentTemplate } from '../../store/features/templates.slice';

export default function TemplateMaintenanceItems() {
	const logbook = useSelector(selectCurrentTemplate);
	const navigate = useNavigate();

	return (
		<DocumentTitle title="Template Maintenance | inndox">
			<Container className="folder">
				<Row className="margin-bottom-3">
					<Col xs={12} md={6} className="flex">
						<h2 className="name">Template Maintenance Items</h2>
					</Col>

					<Col xs={12} md={6} className="flex end">
						<button
							className="button button-secondary margin-right-2"
							onClick={() =>
								navigate(
									'/' +
										CompanyRoutes.basePath.replace('/*', '') +
										'/' +
										TemplateRoutes.template.replace(':templateId', logbook.id) +
										'/' +
										TemplateRoutes.addTemplateMaintenanceItem
								)
							}>
							Add maintenance
						</button>
					</Col>
				</Row>

				<MaintenanceItemList
					contacts={logbook.contacts}
					items={logbook.maintenanceItems}
					logbookIsTransferred={!!logbook.transfer?.acceptedOn}
					isPartOfATemplate
				/>
			</Container>
		</DocumentTitle>
	);
}
