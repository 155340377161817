import { useEffect } from "react";
import { createPortal } from "react-dom";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

const ModalHeader = styled.div`
  justify-content: space-between;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 8px;
`;

const Title = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 100%;

  img {
    margin-right: 16px;
  }

  h3 {
    font-size: 15px;
    line-height: 36px;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 576px) {
    max-width: calc(100% - 40px);

    .title {
      white-space: normal !important;
      overflow: visible !important;
      line-height: 24px;
    }
  }
`;

const modalRoot = document.getElementById("root");

/** Class PortalModal */
export default function Modal({
  isOpen,
  icon,
  title,
  children,
  onClose,
  explanation,
  classes,
}) {
  const modalContent = (
    <div
      className={`modal flex center ${classes}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="modal-content">
        <Container fluid>
          <Row>
            {title ? (
              <Col xs={12} className="header">
                <ModalHeader>
                  <Title>
                    {icon && <img src={icon} alt="icon" />}
                    <h3 className="title">{title}</h3>
                  </Title>
                </ModalHeader>
              </Col>
            ) : (
              <noscript />
            )}

            {explanation ? (
              <Col xs={12}>
                <div className="full-width flex margin-top-1">
                  <p>{explanation}</p>
                </div>
              </Col>
            ) : (
              <noscript />
            )}
          </Row>

          <Row style={{ overflow: "visible" }}>
            <Col className="margin-top-2" style={{ overflow: "visible" }}>
              {children && children}
            </Col>
          </Row>
        </Container>

        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          className="close"
          onClick={() => {
            Modal.isAModalOpen = false;
            onClose();
          }}
        >
          <g
            id="Sprint-7"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Group-19" transform="translate(-8.000000, -8.000000)">
              <rect id="Rectangle" x="0" y="0" width="32" height="32" />
              <path
                d="M23.55356,8.44629689 C22.9583066,7.85123437 21.9730597,7.85123437 21.3778063,8.44629689 L16,13.822379 L10.6221937,8.44629689 C10.0269403,7.85123437 9.04169339,7.85123437 8.44644003,8.44629689 C7.85118666,9.04135941 7.85118666,10.0262905 8.44644003,10.621353 L13.8242463,15.9974351 L8.44644003,21.3735172 C7.85118666,21.9685797 7.85118666,22.9535107 8.44644003,23.5485733 C8.7543297,23.8563642 9.14432328,24 9.53431687,24 C9.92431046,24 10.314304,23.8563642 10.6221937,23.5485733 L16,18.1724912 L21.3778063,23.5485733 C21.685696,23.8563642 22.0756895,24 22.4656831,24 C22.8556767,24 23.2456703,23.8563642 23.55356,23.5485733 C24.1488133,22.9535107 24.1488133,21.9685797 23.55356,21.3735172 L18.1757537,15.9974351 L23.55356,10.621353 C24.1488133,10.0262905 24.1488133,9.04135941 23.55356,8.44629689 Z"
                id="Shape"
                fill="#ADADB0"
                fillRule="nonzero"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
      Modal.isAModalOpen = true;
    } else {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
      Modal.isAModalOpen = false;
    }

    return () => {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
      Modal.isAModalOpen = false;
    };
  }, [isOpen]);

  if (isOpen) {
    if (!onClose) {
      throw Error(
        "Component \"PortalModal\" requires 'onClose' parameter to be defined"
      );
    }

    return createPortal(modalContent, modalRoot);
  }

  return <noscript />;
}

Modal.isAModalOpen = false;
