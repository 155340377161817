import { useParams } from 'react-router-dom';
import { selectCurrentLogbook } from '../../store/features/logbooks.slice';
import LogbookFolder from '../common/logbook-folder';
import { useSelector } from 'react-redux';

export default function CompanyLogbookFolder() {
	const { folderId: folderIdPathParam } = useParams();
	const folderId = parseInt(folderIdPathParam);
	const logbook = useSelector(selectCurrentLogbook);
	const folder = logbook.folders.find(
		(logbookFolder) => logbookFolder.id === folderId
	);
	const documents = logbook.documents.filter(
		(document) => document.folderId === folderId && !document.dateArchived
	);

	return <LogbookFolder folder={folder} documents={documents} />;
}
