import { selectCurrentLogbook } from '../../../store/features/logbooks.slice';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Checkbox from '../../../components/form/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { OwnerRoutes } from '../../../routes';

const InfoContainer = styled.div`
	text-align: left;
	padding: 8px 16px;
	background-color: rgba(191, 214, 47, 0.14);
	border-top: 1px solid #bfd62f;
	border-bottom: 1px solid #bfd62f;
`;

const FolderListContainer = styled.div`
	padding: 12px 8px;
	border-top: 1px solid #dde0e3;
	border-bottom: 1px solid #dde0e3;
	max-height: 360px;
	overflow-y: scroll;
	overflow-x: hidden;
`;

const StepCount = styled.label`
	font-size: 13px;
	font-weight: 900;
	line-height: 1.69;
	color: #bfd62f;
	margin-top: 24px;
	display: block;
`;

export default function ModifyTransferContents({ onContinue }) {
	const logbook = useSelector(selectCurrentLogbook);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<>
			<StepCount>Step 1 of 2</StepCount>
			<h1>Transfer logbook ownership</h1>
			<p className="margin-top-2">
				<strong>Things to keep in mind</strong>
			</p>
			<InfoContainer className="margin-top-1">
				Transferring the logbook will transfer <strong> all the contents</strong> of
				the logbooks except for files stored in <strong>‘Private’</strong> folders.
				{!logbook?.folders.length ? (
					<noscript />
				) : (
					<span>&nbsp;If you wish to exclude any folders, select these below.</span>
				)}
			</InfoContainer>

			<FolderListContainer>
				{logbook?.folders
					.filter((folder) => !folder.isPrivate)
					.map((folder) => (
						<Checkbox
							key={folder.id}
							name={`folderIdsToExclude.folder-${folder.id}`}
							label={folder.name}
							className="margin-top-1"
						/>
					))}
			</FolderListContainer>

			<button
				className="button button-primary button-large button-big full-width margin-top-4"
				onClick={onContinue}>
				Continue
			</button>

			<button
				className="button button-tertiary button-large button-big full-width margin-top-2"
				onClick={() =>
					navigate(pathname.replace('/' + OwnerRoutes.logbookTransfer, ''))
				}>
				Cancel
			</button>
		</>
	);
}
