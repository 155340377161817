import { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { OwnerRoutes } from ".";
import useLogbookData from "../hooks/logbook-data";
import AddDocuments from "../pages/common/add-documents";
import AddFolder from "../pages/common/add-folder";
import AddOrEditLink from "../pages/common/add-or-edit-link";
import EditFolder from "../pages/common/edit-folder";
import ViewContact from "../pages/common/view-contact";
import AddOrEditOwnerLogbookContact from "../pages/owner/add-or-edit-logbook-contact";
import AddOrEditOwnerLogbookDefect from "../pages/owner/add-or-edit-logbook-defect";
import AddOrEditOwnerLogbookWarranty from "../pages/owner/add-or-edit-logbook-warranty";
import OwnerLogbookArchive from "../pages/owner/archive";
import OwnerLogbookContacts from "../pages/owner/contacts";
import OwnerLogbookDefects from "../pages/owner/defects";
import AddOrEditOwnerLogbookMaintenanceItem from "../pages/owner/add-or-edit-logbook-maintenance-item";
import EditOwnerLogbook from "../pages/owner/edit-owner-logbook";
import OwnerLogbook from "../pages/owner/logbook";
import OwnerLogbookFolder from "../pages/owner/logbook-folder";
import OwnerLogbookWarranties from "../pages/owner/logbook-warranties";
import OwnerLogbookMaintenanceItems from "../pages/owner/maintenance-items";
import OwnerLogbookTransfer from "../pages/owner/transfer";
import ViewOwnerLogbookDefect from "../pages/owner/view-logbook-defect";
import ViewOwnerLogbookMaintenanceItem from "../pages/owner/view-logbook-maintenance-item";
import { setCurrentLogbook } from "../store/features/logbooks.slice";
import { useDispatch } from "react-redux";

export default function OwnerLogbookSiteMap() {
  const { logbookId: logbookIdPathParam } = useParams();
  const logbookId = parseInt(logbookIdPathParam);
  const isFetchingData = useLogbookData(logbookId);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setCurrentLogbook());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingData) {
    return (
      <DocumentTitle title="Loading...">
        <Container>
          <Row>
            <Col xs={12} className="flex center">
              <ClipLoader loading size={16} color="#bdd23f" />
            </Col>
          </Row>
        </Container>
      </DocumentTitle>
    );
  }

  return (
    <Routes>
      <Route path="" element={<OwnerLogbook />} />
      <Route path={OwnerRoutes.editLogbook} element={<EditOwnerLogbook />} />
      <Route path={OwnerRoutes.addFolder} element={<AddFolder />} />
      <Route
        path={OwnerRoutes.addLogbookDocuments}
        element={<AddDocuments />}
      />
      <Route path={OwnerRoutes.addLogbookLink} element={<AddOrEditLink />} />
      <Route path={OwnerRoutes.editLogbookLink} element={<AddOrEditLink />} />

      {/* Contacts */}
      <Route
        path={OwnerRoutes.logbookContacts}
        element={<OwnerLogbookContacts />}
      />
      <Route
        path={OwnerRoutes.addLogbookContact}
        element={<AddOrEditOwnerLogbookContact />}
      />
      <Route path={OwnerRoutes.viewLogbookContact} element={<ViewContact />} />
      <Route
        path={OwnerRoutes.editLogbookContact}
        element={<AddOrEditOwnerLogbookContact />}
      />

      {/* Defects */}
      <Route
        path={OwnerRoutes.logbookDefects}
        element={<OwnerLogbookDefects />}
      />
      <Route
        path={OwnerRoutes.logbookDefect}
        element={<ViewOwnerLogbookDefect />}
      />
      <Route
        path={OwnerRoutes.addLogbookDefect}
        element={<AddOrEditOwnerLogbookDefect />}
      />
      <Route
        path={OwnerRoutes.editLogbookDefect}
        element={<AddOrEditOwnerLogbookDefect />}
      />

      {/* Folder */}
      <Route
        path={OwnerRoutes.logbookFolder}
        element={<OwnerLogbookFolder />}
      />
      <Route path={OwnerRoutes.editFolder} element={<EditFolder />} />
      <Route
        path={OwnerRoutes.addLogbookFolderLink}
        element={<AddOrEditLink />}
      />
      <Route
        path={OwnerRoutes.editLogbookFolderLink}
        element={<AddOrEditLink />}
      />
      <Route
        path={OwnerRoutes.addLogbookFolderDocuments}
        element={<AddDocuments />}
      />

      {/* Maintenance Items */}
      <Route
        path={OwnerRoutes.logbookMaintenanceItems}
        element={<OwnerLogbookMaintenanceItems />}
      />
      <Route
        path={OwnerRoutes.addLogbookMaintenanceItem}
        element={<AddOrEditOwnerLogbookMaintenanceItem />}
      />
      <Route
        path={OwnerRoutes.viewLogbookMaintenanceItem}
        element={<ViewOwnerLogbookMaintenanceItem />}
      />
      <Route
        path={OwnerRoutes.editLogbookMaintenanceItem}
        element={<AddOrEditOwnerLogbookMaintenanceItem />}
      />

      {/* Warranty */}
      <Route
        path={OwnerRoutes.warranties}
        element={<OwnerLogbookWarranties />}
      />
      {/* <Route
				path={OwnerRoutes.newWarranty}
				element={<AddOrEditOwnerLogbookWarranty />}
			/> */}
      <Route
        path={OwnerRoutes.editWarranty}
        element={<AddOrEditOwnerLogbookWarranty />}
      />

      {/* Transfer */}
      <Route
        path={OwnerRoutes.logbookTransfer}
        element={<OwnerLogbookTransfer />}
      />

      {/* Archive */}
      <Route
        path={OwnerRoutes.logbookArchive}
        element={<OwnerLogbookArchive />}
      />

      {/* Redirect from everything else */}
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
