import { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { FoldersApi } from '../api/folders';
import {
	selectCurrentLogbook,
	updateLogbookFolder,
} from '../store/features/logbooks.slice';
import { handleError } from './helpers';
import MoreMenu, {
	GRANT_FOLDER_UPLOAD_PERMISSION_ACCESS_MENU_ITEM,
	GRANT_FOLDER_VIEW_PERMISSION_ACCESS_MENU_ITEM,
	REMOVE_FOLDER_PERMISSION_MENU_ITEM,
} from './more-menu';
import { useAuth } from './authentication';
import { toast } from 'react-toastify';
import { ShareFolder } from './share-logbook-or-content';

/**
 * @typedef {Object} SharedFolderPermissionProps
 * @property {inndox.FolderSharePermission} permission
 * @property {boolean} currentUserIsPropertyOwner
 */

/**
 *
 * @param {SharedFolderPermissionProps} props
 */

function SharedFolderPermission({ permission, currentUserIsPropertyOwner }) {
	const canUpload = permission.permissions.some((x) => x === 'Create');
	const dispatch = useDispatch();

	const grantViewAccess = async () => {
		try {
			const updatedFolder = await FoldersApi.updateFolderSharePermission(
				permission.id,
				false
			);
			dispatch(updateLogbookFolder(updatedFolder));
		} catch (e) {
			handleError(e);
		}
	};

	const grantUploadAcess = async () => {
		try {
			const updatedFolder = await FoldersApi.updateFolderSharePermission(
				permission.id,
				true
			);
			dispatch(updateLogbookFolder(updatedFolder));
		} catch (e) {
			handleError(e);
		}
	};

	const removeAccess = async () => {
		try {
			const updatedFolder = await FoldersApi.removeFolderSharePermission(
				permission.id
			);
			dispatch(updateLogbookFolder(updatedFolder));
		} catch (e) {
			handleError(e);
		}
	};

	const actionSelectedMenuOption = (value) => {
		switch (value) {
			case GRANT_FOLDER_VIEW_PERMISSION_ACCESS_MENU_ITEM.value: {
				grantViewAccess();
				break;
			}

			case GRANT_FOLDER_UPLOAD_PERMISSION_ACCESS_MENU_ITEM.value: {
				grantUploadAcess();
				break;
			}

			case REMOVE_FOLDER_PERMISSION_MENU_ITEM.value: {
				removeAccess();
				break;
			}

			default:
				break;
		}
	};

	const menuItems = [
		GRANT_FOLDER_VIEW_PERMISSION_ACCESS_MENU_ITEM,
		GRANT_FOLDER_UPLOAD_PERMISSION_ACCESS_MENU_ITEM,
		REMOVE_FOLDER_PERMISSION_MENU_ITEM,
	];

	return (
		<Col xs={12} sm={4}>
			<div
				className="margin-bottom-1"
				style={{
					backgroundColor: '#FFF',
					borderRadius: 10,
					borderLeft: `8px solid ${canUpload ? '#009599' : '#ffb300'}`,
				}}>
				<Row>
					<Col
						xs={currentUserIsPropertyOwner ? 9 : 12}
						className="flex col start"
						style={{ height: 72, padding: '8px 8px 8px 24px' }}>
						<div className="shared-access-header" style={{ maxWidth: '100%' }}>
							<p className="truncate">
								{permission.sharedWith.name || permission.sharedWith.email}
							</p>
						</div>
						<div
							className="shared-access-subheader helper-text truncate"
							style={{ fontSize: '85%' }}>
							{canUpload ? 'Upload Access' : 'View Only'}
						</div>
					</Col>
					{currentUserIsPropertyOwner && (
						<Col xs={3} className="flex end">
							<div
								className="edit-folder-sharing-settings-menu"
								style={{ marginRight: 16 }}>
								<MoreMenu options={menuItems} onSelect={actionSelectedMenuOption} />
							</div>
						</Col>
					)}
				</Row>
			</div>
		</Col>
	);
}

/**
 * @typedef {Object} SharedFolderPermissionsProps
 * @property {inndox.FolderSharePermission[]} permissions
 * @property {string} folderName
 * @property {number} folderId
 */

/**
 *
 * @param {SharedFolderPermissionsProps} props
 */
export default function SharedFolderPermissions({
	permissions,
	folderId,
	folderName,
}) {
	const [isExportingToCsv, setIsExportingToCsv] = useState(false);
	const [isViewingSharingSettings, setIsViewingSharingSettings] =
		useState(false);
	const logbook = useSelector(selectCurrentLogbook);
	const { session } = useAuth();

	const exportSharedPermissionsToCsv = async () => {
		setIsExportingToCsv(true);

		try {
			const response = await FoldersApi.downloadSharePermissionForFolder(
				logbook.id,
				folderId
			);
			const csvData = 'data:text/csv;charset=utf-8,' + response.data;
			downloadCsv(csvData);
		} catch (e) {
			toast.error(
				'There was an unexpected error trying to download the list. Please try again later.'
			);
			console.error(e);
		} finally {
			setIsExportingToCsv(false);
		}
	};

	const downloadCsv = (csvContent) => {
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute(
			'download',
			'Share list - ' + logbook.description + ' - ' + folderName + '.csv'
		);
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file named "my_data.csv".
		document.body.removeChild(link);
	};

	if (!permissions?.length) {
		return <noscript />;
	}

	const currentUserIsPropertyOwner =
		logbook.ownerId === session.userId ||
		(!logbook.ownerId &&
			session.roles.length &&
			session.roles[0] === 'CompanyAdmin' &&
			logbook.companyId === session.companyId);
	return (
		<Row>
			{/* Header */}
			<Col xs={4}>
				<label>Shared with</label>
			</Col>

			<Col xs={8} className="flex end">
				<button
					className="button button-secondary-link"
					onClick={() => setIsViewingSharingSettings(true)}>
					Sharing settings
				</button>

				<button
					className="button button-secondary-link"
					onClick={exportSharedPermissionsToCsv}>
					{isExportingToCsv ? (
						<ClipLoader size={16} loading color="#68c9d6" />
					) : (
						'Export to CSV'
					)}
				</button>
			</Col>

			{/* Content */}
			{permissions.slice(0, 3).map((permission) => {
				return (
					<SharedFolderPermission
						permission={permission}
						currentUserIsPropertyOwner={currentUserIsPropertyOwner}
						key={permission.id}
					/>
				);
			})}

			{/* Sharing settings for folder */}
			<ShareFolder
				folder={logbook.folders.find((folder) => folder.id === folderId)}
				isOpen={isViewingSharingSettings}
				onClose={() => setIsViewingSharingSettings(false)}
			/>
		</Row>
	);
}
