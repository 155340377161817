import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../components/fifty-fifty";
import logbookSideImage from "../../assets/images/bg2.jpg";
import { useSelector, useDispatch } from "react-redux";
import { isCurrentUserACompany } from "../../store/features/user.slice";
import {
  CompanyRoutes,
  OwnerRoutes,
  SharedRoutes,
  TemplateRoutes,
} from "../../routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Form } from "react-final-form";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import { ClipLoader } from "react-spinners";
import { handleError } from "../../components/helpers";
import { LinksApi } from "../../api/links";
import {
  addDocumentToLogbook,
  addDocumentToSharedLogbook,
  incrementLogbookFolderStatistics,
  incrementSharedLogbookFolderStatistics,
  selectCurrentLogbook,
  selectSharedLogbooks,
  updateDocumentToSharedFolder,
  updateLogbookDocument,
} from "../../store/features/logbooks.slice";
import {
  addDocumentToCurrentTemplate,
  incrementTemplateFolderStatistics,
  updateTemplateDocument,
} from "../../store/features/templates.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function AddOrEditLink() {
  const {
    logbookId: logbookIdPathParam,
    templateId: templateLogbookIdPathParam,
    folderId: folderIdPathParam,
    documentId: documentIdPathParam,
  } = useParams();
  const logbookId = parseInt(logbookIdPathParam || templateLogbookIdPathParam);
  const folderId = parseInt(folderIdPathParam);
  const documentId = parseInt(documentIdPathParam);
  const { pathname, key } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAddingOrEditingAsPartOfTemplate = pathname.includes("templates");
  const isViewingASharedFolder = folderId
    ? pathname.includes("/" + SharedRoutes.basePath.replace("/*", ""))
    : false;
  const currentLogbook = useSelector(selectCurrentLogbook);
  const sharedLogbooks = useSelector(selectSharedLogbooks);
  const currentSharedLogbook = sharedLogbooks?.find(
    (logbook) => logbook.id === logbookId
  );
  const isCompanyUser = useSelector(isCurrentUserACompany);

  const document = documentId
    ? isViewingASharedFolder
      ? currentSharedLogbook.folders
          .find((folder) => folder.id === folderId)
          ?.documents.find((document) => document.id === documentId)
      : currentLogbook.documents.find((document) => document.id === documentId)
    : undefined;

  const backPath =
    key !== "default"
      ? -1
      : isAddingOrEditingAsPartOfTemplate
      ? pathname.replace("/" + TemplateRoutes.addTemplateLink)
      : isCompanyUser
      ? pathname.replace("/" + CompanyRoutes.addLogbookLink, "")
      : pathname.replace("/" + OwnerRoutes.addLogbookLink, "");

  return (
    <DocumentTitle title="Add Link | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <h1 className="margin-bottom-4">
            {documentId
              ? "Edit Link"
              : `Add Link to ${folderId ? "Folder" : "Logbook"}`}
          </h1>
          <p className="margin-top-1">
            {documentId
              ? "Update link properties"
              : "Add a link to an external document or resource. Link must include http or https prefix."}
          </p>

          <Form
            initialValues={
              document
                ? document
                : {
                    folderId,
                    propertyId: logbookId,
                  }
            }
            onSubmit={async (values) => {
              if (documentId) {
                // editing link
                try {
                  const updatedDocument = await LinksApi.updateLink(
                    logbookId,
                    documentId,
                    values
                  );

                  if (isViewingASharedFolder) {
                    dispatch(updateDocumentToSharedFolder(updatedDocument));
                  } else if (isAddingOrEditingAsPartOfTemplate) {
                    dispatch(updateTemplateDocument(updatedDocument));
                  } else {
                    dispatch(updateLogbookDocument(updatedDocument));
                  }

                  navigate(backPath);
                } catch (e) {
                  handleError(e);
                }
              } else {
                // adding link
                try {
                  const newDocument = await LinksApi.createNewLink(
                    logbookId,
                    values
                  );

                  if (isViewingASharedFolder) {
                    dispatch(addDocumentToSharedLogbook(newDocument));
                    if (folderId) {
                      dispatch(
                        incrementSharedLogbookFolderStatistics({
                          id: folderId,
                        })
                      );
                    }
                  } else if (isAddingOrEditingAsPartOfTemplate) {
                    dispatch(addDocumentToCurrentTemplate(newDocument));
                    if (folderId) {
                      dispatch(
                        incrementTemplateFolderStatistics({
                          id: folderId,
                        })
                      );
                    }
                  } else {
                    dispatch(addDocumentToLogbook(newDocument));

                    if (folderId) {
                      dispatch(
                        incrementLogbookFolderStatistics({
                          id: folderId,
                        })
                      );
                    }
                  }

                  navigate(backPath);
                } catch (e) {
                  handleError(e);
                }
              }
            }}
            render={(props) => (
              <Content>
                <TextField
                  name="name"
                  label="Name"
                  required
                  validate={requiredValidator}
                  className="margin-top-2"
                />

                <TextField
                  name="link"
                  label="Link"
                  required
                  validate={requiredValidator}
                  className="margin-top-2"
                />

                <button
                  className="button button-primary button-large button-big full-width margin-top-4"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Update link"
                  )}
                </button>
                <button
                  className="button button-link button-large button-big full-width"
                  onClick={() => navigate(backPath)}
                >
                  Cancel
                </button>
              </Content>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
