import { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { UserSessionsApi } from '../../api/user-sessions';
import { UsersApi } from '../../api/users';
import {
	setSession,
	updateUser,
	selectSession,
} from '../../store/features/user.slice';

export default function ImpersonateAUser() {
	const dispatch = useDispatch();
	const session = useSelector(selectSession);

	useEffect(() => {
		async function impersonateUser() {
			const session = await UserSessionsApi.fetchSessionForCurrentUser();
			dispatch(setSession(session));

			const account = await UsersApi.fetchCurrentUser();
			dispatch(updateUser(account));
		}
		impersonateUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!session) {
		return (
			<DocumentTitle title="Loading...">
				<Container>
					<Row>
						<Col xs={12} className="flex center">
							<ClipLoader loading size={16} color="#bdd23f" />
						</Col>
					</Row>
				</Container>
			</DocumentTitle>
		);
	}

	return (
		<DocumentTitle title="Impersonate User">
			<Container>
				<Row>
					<Col xs={12}>
						<div className="document-list">
							<h1>Impersonating User</h1>
							<div className="margin-top-3">
								<b>Account Id:</b> {session.userId}
								<br />
								<b>First Name:</b> {session.firstName}
								<br />
								<b>Last Name:</b> {session.lastName}
								<br />
								<b>Email:</b> {session.email}
								<br />
								<b>Company Id:</b> {session.companyId}
								<br />
								<b>Impersonator:</b>{' '}
								{session.impersonator ? session.impersonator.email : '-'}
								<br />
							</div>
							<div className="margin-top-3">
								<a href="/admin" className="button">
									Admin Page
								</a>
							</div>
							<div className="margin-top-3">
								<b>Raw:</b>
								<pre
									style={{
										opacity: 0.5,
										fontSize: '80%',
									}}>
									{JSON.stringify(session, null, 3)}
								</pre>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</DocumentTitle>
	);
}
