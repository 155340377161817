import Modal from "../../components/modal";
import warrantyIcon from "../../assets/images/warranty-colored.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentLogbook,
  updateLogbookDocument,
} from "../../store/features/logbooks.slice";
import { Form } from "react-final-form";
import Select from "../../components/form/select";
import { DocumentsApi } from "../../api/documents";
import { requiredValidator } from "../../components/form/validators";
import TextField from "../../components/form/text-field";
import DatePicker from "../../components/form/date-picker";
import { ClipLoader } from "react-spinners";
import { handleError } from "../../components/helpers";
import { styled } from "styled-components";

const Container = styled.div`
  .react-select__indicators {
    margin-right: 8px !important;
  }
`;

const Separator = styled.hr`
  display: block;
  margin-top: 32px;
  margin-bottom: 16px;
  height: 1px;
  background-color: #d8d8d8;
  border: none;
`;

export default function AddOrEditWarrantyModal({ isOpen, onClose, document }) {
  const dispatch = useDispatch();
  const isEditingExistingWarranty = !!document?.warrantyExpiresOn;
  const currentLogbook = useSelector(selectCurrentLogbook);
  const documents = currentLogbook?.documents;

  if (!currentLogbook || !documents) {
    return <noscript />;
  }

  const unarchivedDocuments = documents.filter(
    (document) => !document.dateArchived
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      icon={warrantyIcon}
      title={isEditingExistingWarranty ? "Edit warranty" : "New warranty"}
    >
      <Container>
        <Form
          initialValues={{
            selectedDocument: document?.id,
            title: document?.name,
            expiryDate: document?.warrantyExpiresOn,
          }}
          onSubmit={async (values) => {
            try {
              const updatedDocument = await DocumentsApi.setWarrantyOnDocument(
                currentLogbook.id,
                values.selectedDocument,
                {
                  id: values.selectedDocument,
                  propertyId: currentLogbook.id,
                  name: values.title,
                  warrantyExpiresOn: values.expiryDate,
                }
              );

              dispatch(updateLogbookDocument(updatedDocument));

              onClose();
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <Select
                name="selectedDocument"
                label="Select a document"
                items={unarchivedDocuments.map((document) => ({
                  label: document.filename,
                  value: document.id,
                }))}
                className="margin-top-4"
                required
                validate={requiredValidator}
              />

              <TextField
                name="title"
                label="Title"
                className="margin-top-2"
                required
                validate={requiredValidator}
              />

              <DatePicker
                label="Expiry Date"
                name="expiryDate"
                className="margin-top-2"
                required
                validators={requiredValidator}
              />

              <p className="margin-top-2">
                The logbook owner will be notified when a warranty is going to
                expire. A reminder will be sent 3 months before the expire date.
              </p>

              <button
                className="button button-primary button-large button-big full-width margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : isEditingExistingWarranty ? (
                  "Update warranty"
                ) : (
                  "Set warranty"
                )}
              </button>
            </>
          )}
        />

        {isEditingExistingWarranty ? (
          <>
            <Separator />

            <Form
              onSubmit={async () => {
                try {
                  const updatedDocument =
                    await DocumentsApi.setWarrantyOnDocument(
                      currentLogbook.id,
                      document.id,
                      {
                        id: document.id,
                        propertyId: currentLogbook.id,
                        warrantyExpiresOn: null,
                      }
                    );
                  dispatch(updateLogbookDocument(updatedDocument));
                } catch (e) {
                  handleError(e);
                }
              }}
              render={({ handleSubmit, submitting }) => (
                <button
                  className="button button-remove button-large button-big full-width"
                  onClick={submitting ? null : handleSubmit}
                >
                  {submitting ? (
                    <ClipLoader loading size={16} color="#FF3E3E" />
                  ) : (
                    "Remove this warranty"
                  )}
                </button>
              )}
            />
          </>
        ) : (
          <noscript />
        )}
      </Container>
    </Modal>
  );
}
