import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-grid-system";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import ContactsList, { sortContacts } from "../../components/contact-list";
import { useNavigate } from "react-router-dom";
import { OwnerRoutes } from "../../routes";
import styled from "styled-components";
import { useAuth } from "../../components/authentication";

const StyledHeading = styled.h2`
  @media only screen and (max-width: 576px) {
    margin-bottom: 16px;
  }
`;

export default function OwnerLogbookContacts() {
  const logbook = useSelector(selectCurrentLogbook);
  const contacts = logbook.contacts;
  const navigate = useNavigate();
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook =
    session.userId !== logbook.ownerId;

  return (
    <DocumentTitle title="Logbook Contacts | inndox">
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={12} md={6} className="flex">
            <StyledHeading className="name">Logbook Contacts</StyledHeading>
          </Col>

          <Col xs={12} md={6} className="flex end">
            {isCurrentUserAReadOnlyMemberForLogbook ? (
              <noscript />
            ) : (
              <button
                className="button button-secondary margin-right-2"
                onClick={() =>
                  navigate(
                    "/" +
                      OwnerRoutes.basePath.replace("/*", "") +
                      "/" +
                      OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
                      "/" +
                      OwnerRoutes.addLogbookContact
                  )
                }
              >
                Add a new contact
              </button>
            )}
          </Col>
        </Row>

        <ContactsList
          contacts={sortContacts(contacts)}
          canEdit={!isCurrentUserAReadOnlyMemberForLogbook}
          showCompany
        />
      </Container>
    </DocumentTitle>
  );
}
