import DocumentTitle from "react-document-title";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FoldersApi } from "../../api/folders";
import { Col, Row } from "react-grid-system";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, setSession } from "../../store/features/user.slice";
import { FiftyFifty } from "../../components/fifty-fifty";
import styled from "styled-components";
import { Form } from "react-final-form";
import TextField from "../../components/form/text-field";
import {
  emailValidator,
  requiredValidator,
} from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import { UsersApi } from "../../api/users";
import { UserSessionsApi } from "../../api/user-sessions";
import { toast } from "react-toastify";
import Toast from "../../components/toast";
import { SharedRoutes } from "../../routes";
import { trackWithPixel } from "../../helpers/meta-pixel";
import { trackUser } from "../../helpers/user-identification";
import sideImage from "../../assets/images/bg4.jpg";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
  padding-top: 56px;
`;

export default function PublicFolder() {
  const { accessCode } = useParams();
  const [sharedFolderDetails, setSharedFolderDetails] = useState();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifyAndNavigateToFolder = () => {
    toast.success(
      <Toast title="Successfully registered for access to shared folder" />
    );

    const newPath =
      "/" +
      SharedRoutes.basePath.replace("*", "") +
      SharedRoutes.folder
        .replace(":logbookId", sharedFolderDetails.property.id)
        .replace(":folderId", sharedFolderDetails.id);

    console.log(newPath);

    navigate(newPath);
  };

  useEffect(() => {
    async function prepareForAccess() {
      try {
        const response = await FoldersApi.fetchSharedFolderViaAccessCode(
          accessCode
        );
        setSharedFolderDetails(response);

        if (isUserLoggedIn) {
          await FoldersApi.acceptInvitationToSharedFolder(accessCode);
        }
      } catch (e) {
      } finally {
        setIsFetchingData(false);
      }
    }
    prepareForAccess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingData) {
    return (
      <DocumentTitle title={`Loading folder... | inndox`}>
        <FiftyFifty backgroundImage={sideImage}>
          <ClipLoader loading size={32} color="#bfd62f" />
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  if (isUserLoggedIn) {
    notifyAndNavigateToFolder();
    return <noscript />;
  }

  return (
    <DocumentTitle title={`${sharedFolderDetails.name} | inndox`}>
      <FiftyFifty backgroundImage={sideImage}>
        <Content>
          <h1>Create an account for folder access</h1>

          <div
            style={{
              maxWidth: 320,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            You are requesting access to{" "}
            <strong>{sharedFolderDetails.property.addressFormatted}</strong>.
            Use your email address and create a password to access the files at
            any time.
          </div>

          <div className="margin-top-2">
            <Form
              initialValues={{
                accessToken: accessCode,
              }}
              onSubmit={async (values) => {
                try {
                  const { accessToken, ...remainingValues } = values;

                  // register user
                  await UsersApi.register({
                    ...remainingValues,
                    acceptTermsConditions: true,
                  });

                  trackWithPixel("Lead");

                  // login user
                  const session = await UserSessionsApi.login({
                    email: remainingValues.email,
                    password: remainingValues.password,
                  });

                  trackUser(session);
                  dispatch(setSession(session));

                  // Get access to shared folder via link
                  await FoldersApi.acceptInvitationToSharedFolder(accessToken);

                  // Navigate to folder
                  notifyAndNavigateToFolder();
                } catch (e) {
                  handleError(e);
                }
              }}
              render={(props) => {
                return (
                  <>
                    {/* Access Token */}
                    <TextField name="accessToken" type="hidden" />

                    {/* First Name */}
                    <Row style={{ marginTop: 0 }}>
                      <Col xs={12} sm={6}>
                        <TextField
                          name="firstName"
                          label="First name"
                          placeholder="John"
                          className="margin-top-4"
                          type="text"
                          required
                          validate={requiredValidator}
                          autoComplete="given-name"
                        />
                      </Col>

                      {/* Last Name */}
                      <Col xs={12} sm={6}>
                        <TextField
                          name="lastName"
                          label="Last name"
                          placeholder="Doe"
                          className="margin-top-4"
                          type="text"
                          required
                          validate={requiredValidator}
                          autoComplete="family-name"
                        />
                      </Col>

                      {/* Email */}
                      <Col xs={12}>
                        <TextField
                          name="email"
                          label="Email address"
                          placeholder="john@example.com"
                          className="margin-top-2"
                          type="email"
                          required
                          autoComplete="email"
                          validate={emailValidator}
                        />
                      </Col>

                      {/* Phone */}
                      <Col xs={12}>
                        <TextField
                          name="phone"
                          label="Phone"
                          className="margin-top-2"
                        />
                      </Col>

                      {/* Postcode */}
                      <Col xs={12}>
                        <TextField
                          name="postcode"
                          label="Post code"
                          placeholder="2000"
                          className="margin-top-2"
                        />
                      </Col>

                      {/* Password */}
                      <Col xs={12}>
                        <TextField
                          name="password"
                          label="Set Password"
                          className="margin-top-2"
                          placeholder="Enter a password of your choice here"
                          type="password"
                          required
                          validate={requiredValidator}
                          autoComplete="new-password"
                        />
                      </Col>

                      {/* Actions */}
                      <Col xs={12}>
                        <button
                          className="button button-primary button-large button-big margin-top-4 full-width"
                          onClick={
                            props.submitting ? undefined : props.handleSubmit
                          }
                        >
                          {props.submitting ? (
                            <ClipLoader loading size={16} color="#fff" />
                          ) : (
                            "Get access!"
                          )}
                        </button>
                      </Col>

                      <Col xs={12}>
                        <p
                          className="helper-text margin-top-3"
                          style={{ textAlign: "center" }}
                        >
                          Already have an account?{" "}
                          <NavLink
                            to={`/account/login?accessToken=${props.initialValues.accessToken}`}
                            className="link"
                          >
                            Log in
                          </NavLink>
                          <br />
                          <a
                            href="https://www.inndox.com/terms-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-style-elem"
                          >
                            Terms and Conditions
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </>
                );
              }}
            />
          </div>
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
