import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UsersApi } from "../../api/users";
import checkIcon from "../../assets/images/circle-check-solid.svg";
import crossIcon from "../../assets/images/circle-xmark-solid.svg";
import registerImage from "../../assets/images/register-side.png";
import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../components/fifty-fifty";
import { ClipLoader } from "react-spinners";
import { AccountRoutes } from "../../routes";
import Link from "../../components/form/link";
import styled from "styled-components";

const RegularFontLink = styled(Link)`
  font-size: 16px;
  line-height: 24px;
`;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function ConfirmEmail() {
  const query = useQuery();
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [account, setAccount] = useState(null);
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(true);

  const email = query?.get("Email");
  const token = query?.get("Token");

  useEffect(() => {
    async function confirmEmail() {
      try {
        if (email && token) {
          const accountDetails = await UsersApi.validateToken(token, { email });
          setAccount(accountDetails);
          setIsVerifyingEmail(false);
          setHasError(false);
        } else {
          navigate(
            "/" +
              AccountRoutes.basePath.replace("/*", "") +
              "/" +
              AccountRoutes.login
          );
        }
      } catch (e) {
        setHasError(true);
      }
    }
    confirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, token]);

  if (isVerifyingEmail) {
    return (
      <DocumentTitle title="Confirm Email | inndox">
        <FiftyFifty backgroundImage={registerImage}>
          <ClipLoader loading size={80} color="#bdd23f" />

          <h1 style={{ marginTop: 32 }}>Please wait...</h1>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  if (hasError) {
    return (
      <DocumentTitle title="Confirm Email | inndox">
        <FiftyFifty backgroundImage={registerImage}>
          <img src={crossIcon} style={{ width: 80 }} alt="check" />

          <h1 style={{ marginTop: 32 }}>Error verifying email</h1>
          <p
            className="margin-top-1"
            style={{
              maxWidth: 326,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 20,
            }}
          >
            Please click the link from the confirmation email again. If this
            issue persists, please{" "}
            <RegularFontLink
              link="https://www.inndox.com/contact-us"
              title="get in touch"
            />
            .
          </p>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="Confirm Email | inndox">
      <FiftyFifty backgroundImage={registerImage}>
        <img src={checkIcon} style={{ width: 80 }} alt="check" />

        <h1 style={{ marginTop: 32 }}>Email verified</h1>
        <p
          className="margin-top-1"
          style={{
            maxWidth: 326,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
          }}
        >
          Thank you for verifying your email {account?.firstName}. You can now
          continue to login.
        </p>
        <button
          className="button button-primary button-large button-big margin-top-4"
          style={{ width: 250 }}
          onClick={() =>
            navigate(
              "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.login +
                "/" +
                `email=${email}`
            )
          }
        >
          Login
        </button>
      </FiftyFifty>
    </DocumentTitle>
  );
}
