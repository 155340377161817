import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { OwnerRoutes, SharedRoutes } from '../../routes';
import {
	selectLogbooks,
	selectSharedLogbooks,
} from '../../store/features/logbooks.slice';

export default function OwnerLanding() {
	const logbooks = useSelector(selectLogbooks);
	const sharedLogbooks = useSelector(selectSharedLogbooks);

	if (!logbooks?.length && sharedLogbooks?.length) {
		return (
			<Navigate
				to={'/' + SharedRoutes.basePath.replace('/*', '') + '/' + SharedRoutes.home}
			/>
		);
	}

	return (
		<Navigate
			to={'/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.home}
		/>
	);
}
