import { styled } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectCompany } from '../../../store/features/company.slice';
import RexIntegration from '../../../components/rex-integration';
import XeroIntegration from '../../../components/xero-integration';
import { Row } from 'react-grid-system';
import StripeIntegration from '../../../components/stripe-integration';

const Header = styled.p`
	font-size: 20px;
	font-weight: bold;

	@media only screen and (max-width: 576px) {
		font-size: 16px;
		margin-top: 16px;
	}
`;

export default function IntegrationSettings() {
	const company = useSelector(selectCompany);

	return (
		<>
			<Header>Integrations</Header>

			<Row>
				<RexIntegration companyId={company.id} />

				<XeroIntegration />

				<StripeIntegration />
			</Row>
		</>
	);
}
