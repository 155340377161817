import QueryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DefectsApi } from "../../api/defects";
import { handleError } from "../../components/helpers";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { ClipLoader } from "react-spinners";
import circleCrossIcon from "../../assets/images/circle-xmark-solid.svg";
import declineIcon from "../../assets/images/decline.svg";
import logo from "../../assets/images/logo-dark.svg";
import styled from "styled-components";
import { toast } from "react-toastify";
import Toast from "../../components/toast";
import { FormlessSelect } from "../../components/form/select";
import moment from "moment";
import ReactTimeAgo from "react-time-ago";
import { UserSessionsApi } from "../../api/user-sessions";

const Icon = styled.img`
  width: 144px;
  height: 144px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const Title = styled.h2`
  font-size: 32px;
  line-height: 40px;
  color: #626163;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-top: 64px;
  text-align: center;
`;

const Body = styled.p`
  font-size: 20px;
  color: #000;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  margin-top: 32px;
  text-align: center;
`;

const AuthorComment = styled.div``;

const Author = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #2d3540;
`;

const AuthorCommentTime = styled.p`
  font-size: 13px;
  line-height: 16px;

  time {
    color: #adadb0;
  }
`;

const AuthorCommentText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #2d3540;
  margin-top: 16px;
`;

const NonAuthorComment = styled.div``;

const NonAuthorCommentText = styled.p`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #adadb0;
`;
const NonAuthorCommentTime = styled.p`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  time {
    color: #adadb0;
  }
`;

const DefectComments = styled.div`
  background: #f6f7fb80;
  border-radius: 8px;
  padding: 8px 16px 16px 16px;
`;

export default function PublicDefect() {
  const { search } = useLocation();
  const { DefectId: defectToken, Token: token } = QueryString.parse(search);
  /**
   * @type {[inndox.LogbookDefect | undefined, (defect:inndox.LogbookDefect) => void]} Loading
   */
  const [defect, setDefect] = useState();
  const [defectNotFound, setDefectNotFound] = useState();
  const [defectId, setDefectId] = useState();
  const [logbookId, setLogbookId] = useState();
  // const [showLightBox, setShowLightBox] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(void 0);
  const dispatch = useDispatch();

  // fetches defectId & logbookId from backend
  useEffect(() => {
    if (defectToken && token) {
      async function fetchDefect() {
        try {
          // login the assigned contact
          // that will set the cookie which
          // is used by subsequent API calls
          await UserSessionsApi.loginAsAContact(token);

          // Defect Token
          const tokenResponse = await DefectsApi.fetchDefectIdentifersWithToken(
            defectToken
          );
          const { defectId, propertyId } = tokenResponse;
          setLogbookId(propertyId);
          setDefectId(defectId);
          setDefectNotFound(false);
        } catch (e) {
          setDefectNotFound(true);
        }
      }
      fetchDefect();
    }
  }, [defectToken, dispatch, token]);

  // fetches defect once the above is complete
  useEffect(() => {
    if (defectId && logbookId) {
      async function fetchDefect() {
        try {
          const fetchedDefect = await DefectsApi.fetchDefect(
            logbookId,
            defectId
          );
          setDefect(fetchedDefect);
        } catch (e) {
          handleError(e);
        }
      }
      fetchDefect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defectId, logbookId]);

  useEffect(() => {
    return () =>
      dispatch({
        type: "CLEAR_OUT",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!defect) {
    if (defectNotFound === true) {
      return (
        <Container>
          <Row>
            <Col xs={12} style={{ textAlign: "center" }}>
              <Icon src={circleCrossIcon} alt="error" />
              <Title className="name">Issue Not Found or Unassigned</Title>
              <Body>
                It seems that either the issue does not exist or it is no longer
                assigned to you.
              </Body>
              <Body>
                Please request the person to shared this issue to re-assign it
                to you.
              </Body>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        <Row>
          <Col xs={12} style={{ textAlign: "center" }}>
            <ClipLoader loading={true} size={16} color="#bdd23f" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <PublicHeader defect={defect} />

      <Container>
        <Row>
          <Col xs={12}>
            <div style={{ marginTop: 54 }}>
              {defect.status === "Completed" ? (
                <DefectLabel className="completed">Completed</DefectLabel>
              ) : defect.status === "Resolved" ? (
                <DefectLabel className="resolved">Resolved</DefectLabel>
              ) : defect.isUrgent ? (
                <DefectLabel className="urgent">Urgent</DefectLabel>
              ) : (
                <DefectLabel>In progress</DefectLabel>
              )}
            </div>
            <DefectTitle>{defect.subject}</DefectTitle>

            <DefectDetailsContainer>
              {/* What Logbook Is It */}
              <Label>Asset</Label>
              <Item>{defect.property.addressFormatted}</Item>

              {/* Who Files It */}
              <Label className="margin-top-3">Reported by</Label>
              <Item>{defect.reportedBy}</Item>

              {/* Date of Filing */}
              <Label className="margin-top-3">Reported on</Label>
              <Item>
                {moment(defect.createdOn).format("MMMM D, YYYY") +
                  " at " +
                  moment(defect.createdOn).format("H:MMa")}
              </Item>

              {/* Description */}
              {defect.description ? (
                <>
                  <Label className="margin-top-3">Description</Label>
                  <Item>{defect.description}</Item>
                </>
              ) : (
                <noscript />
              )}

              {/* Files */}
              {defect.files.length > 0 ? (
                <>
                  <Label className="margin-top-3">Files</Label>
                  <ItemFiles>
                    {defect.files.map((file, index) => (
                      <ItemFile
                        key={file.id}
                        src={
                          file.filePath +
                          `?w=${ITEM_FILE_SIZE * 3}&h=${ITEM_FILE_SIZE * 3}`
                        }
                        title={file.fileName}
                        // onClick={() => {
                        //   setSelectedIndex(index);
                        //   setShowLightBox(true);
                        // }}
                      />
                    ))}
                  </ItemFiles>
                </>
              ) : (
                <noscript />
              )}

              {/* Comments */}
              {defect.comments.length > 0 ? (
                <>
                  <Label className="margin-top-3">Comments</Label>
                  <DefectComments>
                    {defect.comments
                      .slice()
                      .reverse()
                      .map((comment, index) => {
                        if (comment.authorId) {
                          return (
                            <Col xs={12} key={index} style={{ marginTop: 24 }}>
                              <AuthorComment>
                                <Author>{comment.author}</Author>
                                <AuthorCommentTime>
                                  <ReactTimeAgo
                                    date={new Date(comment.createdOn)}
                                  />
                                </AuthorCommentTime>

                                <AuthorCommentText>
                                  {comment.text}
                                </AuthorCommentText>
                              </AuthorComment>
                            </Col>
                          );
                        }

                        return (
                          <Col xs={12} key={index} style={{ marginTop: 24 }}>
                            <NonAuthorComment>
                              <NonAuthorCommentText>
                                {comment.text}
                              </NonAuthorCommentText>
                              <NonAuthorCommentTime>
                                <ReactTimeAgo
                                  date={new Date(comment.createdOn)}
                                />
                              </NonAuthorCommentTime>
                            </NonAuthorComment>
                          </Col>
                        );
                      })
                      .slice()
                      .reverse()}
                  </DefectComments>
                </>
              ) : (
                <noscript />
              )}
            </DefectDetailsContainer>
          </Col>
        </Row>
      </Container>

      <style
        dangerouslySetInnerHTML={{ __html: "header { display: none; }" }}
      />
    </>
  );
}

const DefectTitle = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
`;

const DefectDetailsContainer = styled.div`
  background: #ffffff;
  border-radius: 10px;
  margin-top: 14px;
  padding: 46px 42px;

  @media only screen and (max-width: 576px) {
    margin-bottom: 64px;
  }
`;

const Label = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #bfbfbf;
`;

const Item = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-top: 6px;
`;

const ItemFiles = styled.div`
  margin-top: 6px;
`;

const ITEM_FILE_SIZE = 130;

const ItemFile = styled.img`
  width: ${ITEM_FILE_SIZE}px;
  height: ${ITEM_FILE_SIZE}px;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
`;

const Header = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #dde0e3;
  margin-top: -40px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Content = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DefectSummary = styled.div`
  margin-left: 54px;
`;

const HeaderDefectTitle = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
`;

const DefectDescription = styled.p`
  margin-top: 3px;
  font-size: 13px;
  line-height: 16px;
  color: #adadb0;
`;

const ActionsContainer = styled.div`
  margin-left: auto;
  margin-right: 9px;
  display: flex;
  align-items: center;
  position: relative;

  .rw-dropdown-list {
    width: 160px;
  }

  @media only screen and (max-width: 576px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: #fff;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    z-index: 1;
  }
`;

const DefectLabel = styled.span`
  padding: 2px 4px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #ffaa00;
  color: #fff;

  &.urgent {
    background-color: #d44215;
    color: #fff;
  }

  &.resolved {
    background-color: #bfd62f;
    color: #fff;
  }

  &.completed {
    background-color: #dfdee3;
    color: #fff;
  }
`;

/**
 * @typedef {Object} PublicHeaderProps
 * @property {inndox.LogbookDefect} defect
 * @property {(defect: inndox.LogbookDefect) => void} updateDefect
 */

/**
 *
 * @param {PublicHeaderProps} props
 * @returns
 */
function PublicHeader({ defect, updateDefect }) {
  const downloadDefectFiles = () => {
    DefectsApi.downloadDefectFiles(defect.property.id, defect.id);
  };

  const resolveDefect = async () => {
    try {
      await DefectsApi.updateDefectStatus(defect.property.id, defect.id, {
        id: defect.id,
        status: 1,
      });

      updateDefect();

      toast.success(<Toast title="The issue has been marked as completed." />);
    } catch (e) {
      toast.error(
        <Toast
          details="There was an error marking the issue as completed. Please try again later."
          title="Error completing issue"
        />
      );
    }
  };

  const onDefectActionChosen = (action) => {
    switch (action) {
      case "print": {
        window.setTimeout(window.print, 500);
        break;
      }

      case "download": {
        downloadDefectFiles();
        break;
      }

      case "decline": {
        break;
      }

      case "resolve": {
        resolveDefect();
        break;
      }

      default: {
        break;
      }
    }
  };

  const getActions = (dropUp = false) => (
    <ActionsContainer>
      <FormlessSelect
        input={{ onChange: (option) => onDefectActionChosen(option.value) }}
        items={[
          {
            label: "Print",
            value: "print",
          },
          defect.files.length > 0 && {
            label: "Download",
            value: "download",
          },
          {
            label: "Decline",
            value: "decline",
            destructive: true,
            icon: declineIcon,
          },
        ]}
      />

      <button
        className="button button-primary"
        style={{ borderRadius: 4, height: 48, marginLeft: 8 }}
        onClick={() => onDefectActionChosen("resolve")}
      >
        Mark as resolved
      </button>
    </ActionsContainer>
  );

  const defectDate = moment(defect.createdOn);
  return (
    <Header>
      <Container>
        <Row>
          <Col xs={12}>
            <Content>
              <img src={logo} alt="logo" />

              {defect.subject || defect.description ? (
                <Hidden xs>
                  <DefectSummary>
                    <HeaderDefectTitle>{defect.subject}</HeaderDefectTitle>
                    <DefectDescription>{`By ${
                      defect.reportedBy
                    } on ${defectDate.format(
                      "MMMM D, YYYY"
                    )} at ${defectDate.format("h:mma")}`}</DefectDescription>
                  </DefectSummary>

                  {getActions()}
                </Hidden>
              ) : (
                <noscript />
              )}

              <Visible xs>{getActions(true)}</Visible>
            </Content>
          </Col>
        </Row>
      </Container>
    </Header>
  );
}
