import { axiosInstance } from './base';

export const DocumentsApi = {
	/**
	 * Same as opening a document.
	 * @param {number} propertyId
	 * @param {number} documentId
	 * @returns {void}
	 */
	downloadDocument: (propertyId, documentId) =>
		window.open(
			`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_EXTENSION}/properties/${propertyId}/documents/${documentId}?download=true`,
			'_blank'
		),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} documentId
	 * @param {string} name
	 * @returns {Promise<inndox.LogbookDocument>}
	 */
	updateDocumentName: (propertyId, documentId, name) =>
		axiosInstance.put(`/properties/${propertyId}/documents/${documentId}`, {
			id: documentId,
			propertyId,
			name,
		}),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} documentId
	 * @returns {Promise<void>}
	 */
	removeDocument: (propertyId, documentId) =>
		axiosInstance.delete(`/properties/${propertyId}/documents/${documentId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @returns
	 */
	downloadDocumentsForProperty: (propertyId) =>
		window.open(
			process.env.REACT_APP_BASE_URL +
				process.env.REACT_APP_API_EXTENSION +
				`/properties/${propertyId}/documents/zip?&apikey=${process.env.REACT_APP_API_KEY}`,
			'_blank'
		),
	/**
	 *
	 * @param {number} propertyId
	 * @param {FormData} data
	 * @returns {Promise<inndox.LogbookDocument>}
	 */
	uploadDocumentsToProperty: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/documents`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	/**
	 *
	 * @param {*} propertyId
	 * @param {*} documentId
	 * @returns {Promise<inndox.LogbookDocument>}
	 */
	moveDocumentToDashboard: (propertyId, documentId) =>
		axiosInstance.put(
			`/properties/${propertyId}/documents/${documentId}/moveToDashboard`
		),
	/**
	 *
	 * @param {*} propertyId
	 * @param {*} documentId
	 * @returns {Promise<inndox.Folder>}
	 */
	moveDocumentToFolder: (propertyId, documentId, folderId) =>
		axiosInstance.put(`/properties/${propertyId}/documents/${documentId}/move`, {
			id: documentId,
			propertyId,
			folderId,
		}),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} documentId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookDocument>}
	 */
	setWarrantyOnDocument: (propertyId, documentId, data) =>
		axiosInstance.put(
			`/properties/${propertyId}/documents/${documentId}/warranty`,
			data
		),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookDocument[]>}
	 */
	searchForDocuments: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/documents/search`, data),
};
