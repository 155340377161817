import { styled } from 'styled-components';
import { isNull, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCompany } from '../../../store/features/company.slice';
import { Col, Row } from 'react-grid-system';

const isNullOrUndefined = (value) => isNull(value) || isUndefined(value);

const Header = styled.p`
	font-size: 20px;
	font-weight: bold;

	@media only screen and (max-width: 576px) {
		font-size: 16px;
		margin-top: 16px;
	}
`;

const Title = styled.p`
	margin-top: 40px;
	font-size: 16px;
	font-weight: 700;
`;

const Description = styled.p`
	font-size: 16px;
`;

const Statistic = styled.div`
	height: 88px;
	width: 100%;
	background-color: #f6f7fb;
	border-radius: 8px;
	padding: 16px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;

	@media only screen and (max-width: 576px) {
		flex-direction: column;
		height: 128px;
	}
`;

const StatisticType = styled.p`
	@media only screen and (max-width: 576px) {
		font-size: 14px;
	}
`;

const StatisticValue = styled.p`
	font-size: 24px;
	font-weight: bold;
`;

export default function CompanyStatistics() {
	const company = useSelector(selectCompany);
	const statistics = company?.statistics;

	const isMasterCompany =
		!isNullOrUndefined(statistics?.subcompanyCount) &&
		!isNullOrUndefined(statistics.subcompanyStarterCount) &&
		!isNullOrUndefined(statistics.subcompanyProfessionalCount) &&
		!isNullOrUndefined(statistics.subcompanyEnterpriseCount);

	return (
		<>
			<Header>Statistics</Header>

			<Row>
				{isMasterCompany ? (
					<Col xs={12}>
						<Title>Overall</Title>
					</Col>
				) : (
					<noscript />
				)}

				{/* Number of properties */}
				<Col xs={6} sm={4}>
					<Statistic>
						<StatisticType>Logbooks</StatisticType>
						<StatisticValue>{statistics.propertyCount}</StatisticValue>
					</Statistic>
				</Col>
				{/* Number of handovers */}
				<Col xs={6} sm={4}>
					<Statistic>
						<StatisticType>Handovers</StatisticType>
						<StatisticValue>{statistics.handoverCount}</StatisticValue>
					</Statistic>
				</Col>
				{/* Number of properties being transferred */}
				<Col xs={6} sm={4}>
					<Statistic>
						<StatisticType>Logbooks being transferred</StatisticType>
						<StatisticValue>{statistics.transferringCount}</StatisticValue>
					</Statistic>
				</Col>
				{/* Number of files */}
				<Col xs={6} sm={4}>
					<Statistic>
						<StatisticType>Files</StatisticType>
						<StatisticValue>{statistics.fileCount}</StatisticValue>
					</Statistic>
				</Col>
				{/* Number of contacts */}
				<Col xs={6} sm={4}>
					<Statistic>
						<StatisticType>Contacts</StatisticType>
						<StatisticValue>{statistics.contactCount}</StatisticValue>
					</Statistic>
				</Col>
				{/* Number of maintenance reminders */}
				<Col xs={6} sm={4}>
					<Statistic>
						<StatisticType>Maintenance Reminders</StatisticType>
						<StatisticValue>{statistics.maintenanceCount}</StatisticValue>
					</Statistic>
				</Col>
				{/* Number of warranty items */}
				<Col xs={6} sm={4}>
					<Statistic>
						<StatisticType>Warranty Items</StatisticType>
						<StatisticValue>{statistics.warrantyItemsCount}</StatisticValue>
					</Statistic>
				</Col>

				{isMasterCompany ? (
					<>
						<Col xs={12}>
							<Title>Sub Companies</Title>
							<Description>
								The subcompanies on each payment plan will show up once they complete
								their first handover.
							</Description>
						</Col>
						<Col xs={6} sm={4}>
							{/* Number of sub cmompanies */}
							<Statistic>
								<StatisticType>Companies</StatisticType>
								<StatisticValue>{statistics.subcompanyCount}</StatisticValue>
							</Statistic>
						</Col>

						{/* Number of sub companies on Starter Plan */}
						<Col xs={6} sm={4}>
							<Statistic>
								<StatisticType>Companies on Starter Plan</StatisticType>
								<StatisticValue>{statistics.subcompanyStarterCount}</StatisticValue>
							</Statistic>
						</Col>

						{/* Number of sub companies on Professional Plan */}
						<Col xs={6} sm={4}>
							<Statistic>
								<StatisticType>Companies on Professional Plan</StatisticType>
								<StatisticValue>
									{statistics.subcompanyProfessionalCount}
								</StatisticValue>
							</Statistic>
						</Col>

						{/* Number of sub companies on Enterprise Plan */}
						<Col xs={6} sm={4}>
							<Statistic>
								<StatisticType>Companies on Enterprise Plan</StatisticType>
								<StatisticValue>{statistics.subcompanyEnterpriseCount}</StatisticValue>
							</Statistic>
						</Col>
					</>
				) : (
					<noscript />
				)}
			</Row>
		</>
	);
}
