import { useLocation } from "react-router-dom";
import QueryString from "query-string";
import circleCheckIcon from "../../assets/images/circle-check-solid.svg";
import circleCrossIcon from "../../assets/images/circle-xmark-solid.svg";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

const Icon = styled.img`
  width: 144px;
  height: 144px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #626163;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-top: 64px;
  text-align: center;
`;

const Body = styled.p`
  font-size: 20px;
  color: #000;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  margin-top: 32px;
  text-align: center;
`;

export default function SnoozeOrUnsnoozeMaintenanceItemFromEmail() {
  const { search } = useLocation();
  const { error } = QueryString.parse(search);

  if (error !== undefined && error !== null && error.length) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Icon src={circleCrossIcon} alt="error" />
            <Title className="name">Snooze Unsuccessful</Title>
            <Body>There was an error snoozing your maintenance reminder.</Body>
            <Body>
              Please click on the link in the notification email to try again.
            </Body>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Icon src={circleCheckIcon} alt="success" />
          <Title className="name">Snooze Successful</Title>
          <Body>Your notification has been snoozed.</Body>
          <Body>
            If you believe this was in error, you can login to your account and
            re-enable notifications for this reminder.
          </Body>
        </Col>
      </Row>
    </Container>
  );
}
