import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import closeIcon from "../assets/images/close.svg";
import closeRedIcon from "../assets/images/circle-xmark-solid.svg";
import { ClipLoader } from "react-spinners";
import { getFileTypeIcon } from "./helpers";
import { useState } from "react";
import { useEffect } from "react";

/**
 * @typedef {Object} SingleFilePreviewProps
 * @property {File} file
 * @property {() => void} removeFile
 */

/**
 * files, removeFile
 * @param {SingleFilePreviewProps} props
 */
export function SingleFilePreview({ file, isUploading, removeFile }) {
  // if a url
  if (typeof file === "string") {
    return (
      <UrlPreview
        file={file}
        removeFile={isUploading ? undefined : removeFile}
      />
    );
  }

  // actual file
  return (
    <FilePreview
      file={file}
      removeFile={isUploading ? undefined : removeFile}
    />
  );
}

const MultipleFilePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

/**
 * @typedef {Object} MultipleFilePreviewProps
 * @property {File[]} files
 * @property {(files: File[]) => void} updateSelectedFiles
 */

/**
 * files, removeFile
 * @param {MultipleFilePreviewProps} props
 */
export function MultipleFilePreview({
  files,
  isUploading,
  allowUnzip,
  updateSelectedFiles,
}) {
  const removeFile = (fileIndex) => {
    const updatedFiles = [
      ...files.slice(0, fileIndex),
      ...files.slice(fileIndex + 1),
    ];
    updateSelectedFiles(updatedFiles);
  };

  return (
    <MultipleFilePreviewContainer>
      {files.map((file, index) => (
        <MultipleFilesFilePreview
          key={index}
          file={file}
          allowUnzip={allowUnzip}
          removeFile={isUploading ? undefined : () => removeFile(index)}
        />
      ))}
    </MultipleFilePreviewContainer>
  );
}

/************************************
 ************************************
 **** INTERNAL HELPER COMPONENTS ****
 ************************************
 ************************************/

function UrlPreview({ file, removeFile }) {
  return (
    <Row className={`margin-bottom-2 file-preview-container`}>
      <Col xs={10}>
        <div className="preview">
          <div className="file-preview">
            <img
              src={file + "?w=401&h=200"}
              alt="Upload file type preview"
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </Col>
      <Col xs={2} className="flex center">
        {removeFile ? (
          <RemoveButton onClick={removeFile}>
            <img src={closeRedIcon} alt="delete" />
          </RemoveButton>
        ) : (
          <noscript />
        )}
      </Col>
    </Row>
  );
}

const FilePreviewContainer = styled.div`
  img {
    box-shadow: 0 0 4px 2px #d8d8d850;
    border-radius: 8px;
    margin-right: 16px;
  }
`;

const RemoveButton = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

function FileIconPreview({ file, removeFile }) {
  return (
    <Row className="margin-bottom-2">
      <Col xs={8} className="preview flex start">
        <img src={getFileTypeIcon(file.type)} alt="file-type-icon" />
        <p
          className="truncate"
          style={{
            marginBottom: 0,
            marginLeft: 16,
          }}
        >
          &nbsp;{file.name}
        </p>
      </Col>
      <Col xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        {removeFile ? (
          <img src={closeIcon} alt="remove" onClick={removeFile} width={12} />
        ) : (
          <noscript />
        )}
      </Col>
    </Row>
  );
}

export function FilePreview({ file, removeFile, status }) {
  const image = ["image/png", "image/jpg", "image/jpeg"].includes(file?.type)
    ? URL.createObjectURL(file)
    : null;

  if (image) {
    return (
      <Row
        className={`margin-bottom-2 file-preview-container ${
          status === 200 ? "file-preview-success" : null
        }`}
      >
        <Col>
          <div className="preview flex">
            <FilePreviewContainer>
              <img
                src={image}
                alt="Upload file type preview"
                style={{ maxWidth: "80%", maxHeight: "50%" }}
              />
            </FilePreviewContainer>

            {removeFile ? (
              <RemoveButton onClick={removeFile}>
                <img src={closeIcon} alt="remove" />
              </RemoveButton>
            ) : (
              <noscript />
            )}
          </div>
        </Col>
      </Row>
    );
  }

  return <FileIconPreview file={file} removeFile={removeFile} />;
}

const MultipleFilePreviewItemContainer = styled.div`
  width: calc(33.34% - 11px);
  height: 100px;
  flex-shrink: 0;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
  margin-right: 10px;
  position: relative;
`;

const MultipleFilePreviewImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const MultipleFilePreviewOther = styled.img`
  width: 100%;
  height: 100%;
  max-height: 60%;
  margin-bottom: 20px;
`;

const MultipleFilePreviewOtherName = styled.div`
  height: 24px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8d8d850;
  padding: 0 8px;
  font-size: 13px;
`;

const CloseButtonContainer = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

const UploadingButtonContainer = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #bdd23f;
  border-radius: 50%;
  cursor: pointer;
`;

/**
 * @typedef {Object} MultipleFilesFilePreviewProps
 * @property {File | string | inndox.LogbookDefectAttachedFile} file
 * @property {() => void} removeFile
 */

/**
 *
 * @param {MultipleFilesFilePreviewProps} props
 * @returns
 */
function MultipleFilesFilePreview({ file, allowUnzip, removeFile }) {
  // if a url, display a favicon of the website if possible
  if (typeof file === "string") {
    return <UrlPreview file={file} removeFile={removeFile} />;
  }

  if (file.filePath) {
    // this is an attached file
    const fileNameParts = file.fileName.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1];

    if (["jpg", "jpeg", "png", "gif", "tiff"].includes(fileExtension)) {
      return (
        <MultipleFilePreviewItemContainer onClick={removeFile}>
          <MultipleFilePreviewImage
            style={{ backgroundImage: `url(${file.filePath}?w=337&h=184)` }}
          />

          {removeFile ? (
            <CloseButtonContainer onClick={removeFile}>
              <img src={closeRedIcon} alt="close" />
            </CloseButtonContainer>
          ) : (
            <UploadingButtonContainer className="flex center">
              <ClipLoader loading size={16} color="#fff" />
            </UploadingButtonContainer>
          )}
        </MultipleFilePreviewItemContainer>
      );
    }

    return (
      <MultipleFilePreviewItemContainer onClick={removeFile}>
        <MultipleFilePreviewOther src={getFileTypeIcon(fileExtension)} />

        <MultipleFilePreviewOtherName>
          {file.fileName}
        </MultipleFilePreviewOtherName>

        {removeFile ? (
          <CloseButtonContainer>
            <img src={closeRedIcon} alt="close" />
          </CloseButtonContainer>
        ) : (
          <UploadingButtonContainer className="flex center">
            <ClipLoader loading size={16} color="#fff" />
          </UploadingButtonContainer>
        )}
      </MultipleFilePreviewItemContainer>
    );
  }

  // get file preview url if possible
  const image = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/tiff",
  ].includes(file?.type)
    ? URL.createObjectURL(file)
    : null;

  if (image) {
    return (
      <MultipleFilePreviewItemContainer onClick={removeFile}>
        <MultipleFilePreviewImage
          style={{ backgroundImage: `url(${image})` }}
        />

        {removeFile ? (
          <CloseButtonContainer>
            <img src={closeRedIcon} alt="close" />
          </CloseButtonContainer>
        ) : (
          <UploadingButtonContainer className="flex center">
            <ClipLoader loading size={16} color="#fff" />
          </UploadingButtonContainer>
        )}
      </MultipleFilePreviewItemContainer>
    );
  }

  if (file.type === "application/zip") {
    return (
      <ZipFilePreview
        file={file}
        allowUnzip={allowUnzip}
        removeFile={removeFile}
      />
    );
  }

  return (
    <MultipleFilePreviewItemContainer>
      <MultipleFilePreviewOther src={getFileTypeIcon(file.type)} />

      <MultipleFilePreviewOtherName>{file.name}</MultipleFilePreviewOtherName>

      {removeFile ? (
        <CloseButtonContainer onClick={removeFile}>
          <img src={closeRedIcon} alt="close" />
        </CloseButtonContainer>
      ) : (
        <UploadingButtonContainer className="flex center">
          <ClipLoader loading size={16} color="#fff" />
        </UploadingButtonContainer>
      )}
    </MultipleFilePreviewItemContainer>
  );
}

const InlineUnpackButton = styled.span`
  color: #68c9d6;
  cursor: pointer;
  font-weight: bold;
`;

const InlineCancelButton = styled.span`
  color: #ff3e3e;
  cursor: pointer;
  font-weight: bold;
`;

function ZipFilePreview({ file, allowUnzip, removeFile }) {
  const [unpackZip, setUnpackZip] = useState(false);

  useEffect(() => {
    file.shouldUnpack = unpackZip;
  }, [unpackZip, file]);

  return (
    <MultipleFilePreviewItemContainer
      style={{
        width: allowUnzip ? "calc(66.67% - 11px)" : "calc(33.34% - 11px)",
      }}
    >
      <MultipleFilePreviewOther src={getFileTypeIcon(file.type)} />

      <MultipleFilePreviewOtherName
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>{file.name}</span>

        {allowUnzip ? (
          unpackZip ? (
            <div>
              Will unpack.{" "}
              <InlineCancelButton onClick={() => setUnpackZip((_) => !_)}>
                Cancel
              </InlineCancelButton>
            </div>
          ) : (
            <InlineUnpackButton onClick={() => setUnpackZip((_) => !_)}>
              Unpack
            </InlineUnpackButton>
          )
        ) : (
          <noscript />
        )}
      </MultipleFilePreviewOtherName>

      {removeFile ? (
        <CloseButtonContainer onClick={removeFile}>
          <img src={closeRedIcon} alt="close" />
        </CloseButtonContainer>
      ) : (
        <UploadingButtonContainer className="flex center">
          <ClipLoader loading size={16} color="#fff" />
        </UploadingButtonContainer>
      )}
    </MultipleFilePreviewItemContainer>
  );
}
