import { Col, Row } from "react-grid-system";
import { styled } from "styled-components";
import {
  selectPaymentDetails,
  updatePaymentDetails,
} from "../../../store/features/subscription.slice";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Modal from "../../../components/modal";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Form } from "react-final-form";
import { isCurrentUserACompany } from "../../../store/features/user.slice";
import { toast } from "react-toastify";
import Toast from "../../../components/toast";
import { PaymentsApi } from "../../../api/payments";
import { handleError } from "../../../components/helpers";
import { requiredValidator } from "../../../components/form/validators";
import TextField from "../../../components/form/text-field";
import { ClipLoader } from "react-spinners";
import questionIcon from "../../../assets/images/question.svg";
import PastPayments from "../../../components/past-payments";
import ManageSubscription from "../../../components/manage-subscription";
import SwitchBillingCycle from "../../../components/switch-billing-cycle";
import { formatNumberInCurrency } from "../../../helpers/currency";

const DEFAULT_CREDIT_CARD_TEXT = "A credit card has not yet been supplied..";

const Header = styled.p`
  font-size: 20px;
  font-weight: bold;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    margin-top: 16px;
  }
`;

const InfoRow = styled(Row)`
  min-height: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dde0e3;
`;

const InfoItem = styled(Col)`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const Value = styled.p`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCardElement = styled(CardElement)`
  width: 100%;
  background-color: transparent;
  border: 1px solid #bfbfbf;
  font-size: 16px;
  color: #2d3540;
  padding: 15px 8px;
  line-height: 20px;
  caret-color: #bdd23f;
  border-radius: 4px;

  &:focus {
    border-color: #a0b42a;
  }
`;

const ExpiredCardContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${Value} {
    white-space: nowrap;
  }
`;

const ExpiredCardLabel = styled.label`
  background-color: #ff3e3e;
  border-radius: 2px;
  padding: 4px 8px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 24px;
`;

export default function BillingSettings() {
  const paymentDetails = useSelector(selectPaymentDetails);
  const [isUpdatingCreditCardDetails, setIsUpdatingCreditCardDetails] =
    useState(false);
  const [isUpdatingPromoCode, setIsUpdatingPromoCode] = useState(false);
  const dispatch = useDispatch();
  const isCompanyUser = useSelector(isCurrentUserACompany);

  const hasCancelledPlan = !!paymentDetails.subscriptionDaysRemaining;
  const hasOverduePlan =
    paymentDetails.subscriptionDaysRemaining > 0 &&
    !!paymentDetails.nextPaymentOn &&
    paymentDetails.planStatus === "Active";

  const hasNeverSubscribed =
    paymentDetails.planStatus === "None" &&
    paymentDetails.creditCardText === DEFAULT_CREDIT_CARD_TEXT;
  // const subscriptionPlan = paymentDetails.plan;
  const [shouldShowSponsoredInfo, setShouldShowSponsoredInfo] = useState(false);
  const [wantsToChangeBillingInterval, setWantsToChangeBillingInterval] =
    useState(false);

  const planMonthlyPriceAfterDiscount =
    Math.round(
      paymentDetails.planMonthlyPrice *
        (1 - paymentDetails.discount / 100) *
        100
    ) / 100;
  const planAnnualPriceAfterDiscount =
    Math.round(
      paymentDetails.planAnnualPrice * (1 - paymentDetails.discount / 100) * 100
    ) / 100;

  return (
    <>
      <Header>Billing</Header>

      {/* {hasNeverSubscribed ||
      subscriptionPlan === "Master" ||
      !isCompanyUser ? null : (
        <p>
          Choose the right solution for you at our{" "}
          <a
            href="https://www.inndox.com/pricing-companies"
            target="_blank"
            className="button button-secondary-link"
            rel="noopener noreferrer"
          >
            Pricing page
          </a>
          .
        </p>
      )} */}

      {/* Plan */}
      {paymentDetails.plan === null ? (
        <InfoRow className="margin-top-3"></InfoRow>
      ) : (
        <InfoRow className="margin-top-3">
          <InfoItem xs={5} md={3}>
            <Label>Plan</Label>
          </InfoItem>

          {hasNeverSubscribed ? (
            <InfoItem xs={7} md={9}>
              <Value className="margin-bottom-2">
                {!isCompanyUser ? (
                  <>
                    Got another logbook you would like to add to this account?
                    Subscribe now for{" "}
                    <b>
                      {paymentDetails.planCurrency}
                      {paymentDetails.planMonthlyPrice}/month with a 30 day free
                      trial
                    </b>{" "}
                    for up to <b>10 logbooks</b>.
                  </>
                ) : (
                  <>
                    Start on the{" "}
                    <b>
                      {paymentDetails.plan} plan for just{" "}
                      {paymentDetails.planCurrency}{" "}
                      {paymentDetails.planInterval === "Annual"
                        ? `${formatNumberInCurrency(
                            planAnnualPriceAfterDiscount,
                            paymentDetails.planCurrency
                          )}/year`
                        : `${formatNumberInCurrency(
                            planMonthlyPriceAfterDiscount,
                            paymentDetails.planCurrency
                          )}/month`}
                      {paymentDetails.planLimit === null
                        ? " for "
                        : paymentDetails.planLimit === 1
                        ? " up until your first logbook handover"
                        : " for up to "}
                      {paymentDetails.planLimit === null
                        ? "unlimited"
                        : paymentDetails.planLimit === 1
                        ? null
                        : paymentDetails.planLimit}{" "}
                      {!isCompanyUser
                        ? "logbooks"
                        : paymentDetails.planLimit === 1
                        ? null
                        : "handovers"}
                    </b>
                    .
                  </>
                )}
                {/* {` Have a look at our `}
                <span>
                  <a
                    href="https://www.inndox.com/pricing-companies"
                    className="link-style-elem"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#a0b42a" }}
                  >
                    pricing
                  </a>
                </span> */}

                {!isCompanyUser ? (
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img
                      className="clickable"
                      src={questionIcon}
                      style={{ width: 18, marginBottom: -4, marginLeft: -8 }}
                      onClick={() => setShouldShowSponsoredInfo(true)}
                      alt="question"
                    />
                  </span>
                ) : null}
              </Value>
            </InfoItem>
          ) : hasOverduePlan || hasCancelledPlan ? (
            <>
              <InfoItem xs={7} md={4}>
                <Value>
                  <b>{`${paymentDetails.plan} Plan `}</b>
                </Value>
              </InfoItem>

              {/* Additional Copy */}
              <InfoItem xs={12} md={5}>
                {paymentDetails.subscriptionDaysRemaining &&
                paymentDetails.subscriptionDaysRemaining >= 0 ? (
                  <Value className="helper-text">
                    {paymentDetails.subscriptionDaysRemaining}{" "}
                    {!isCompanyUser
                      ? "days remaining until you lose access to your logbooks."
                      : "days remaining until you lose access."}
                  </Value>
                ) : (
                  <noscript />
                )}
              </InfoItem>
            </>
          ) : (
            <InfoItem xs={7} md={9}>
              <Value>
                <b>{`${paymentDetails.plan} Plan `}</b>
              </Value>
            </InfoItem>
          )}
        </InfoRow>
      )}

      {/* Plan Status */}
      <InfoRow>
        <InfoItem xs={5} md={3}>
          <Label>Status</Label>
        </InfoItem>
        {hasNeverSubscribed ? (
          <InfoItem xs={7} md={7}>
            <Value>
              <b>Inactive</b>
            </Value>
          </InfoItem>
        ) : (
          <InfoItem
            xs={7}
            md={7}
            style={
              hasOverduePlan
                ? { flexDirection: "column", alignItems: "flex-start" }
                : {}
            }
          >
            {hasOverduePlan ? (
              <>
                <Value>
                  <b style={{ color: "#FF3E3E" }}>Overdue</b>
                </Value>

                <Value className="helper-text">
                  Please update your credit card details to continue your
                  subscription.
                </Value>
              </>
            ) : hasCancelledPlan ? (
              <Value>
                <b style={{ color: "#FF3E3E" }}>Cancelled</b>
              </Value>
            ) : !hasNeverSubscribed && !paymentDetails.creditCardIsValid ? (
              <Value>
                <b>Inactive</b>
              </Value>
            ) : (
              <>
                <Value>
                  <b>Active</b>
                  &nbsp;&nbsp;
                  <span>
                    ( {paymentDetails.planInterval === 0 ? "Monthly" : "Annual"}{" "}
                    )
                  </span>
                </Value>
              </>
            )}
          </InfoItem>
        )}

        {paymentDetails.plan && (
          <InfoItem xs={7} md={2} offset={{ xs: 5, md: 0 }}>
            <ManageSubscription />
          </InfoItem>
        )}
      </InfoRow>

      {/* Plan Price */}
      {paymentDetails.plan === null ? (
        <noscript />
      ) : hasNeverSubscribed ? (
        <noscript />
      ) : (
        <InfoRow>
          <InfoItem xs={5} md={3}>
            <Label>Price</Label>
          </InfoItem>
          <InfoItem xs={7} md={6}>
            <Value>
              {paymentDetails.planCurrency}{" "}
              {paymentDetails.planInterval === "Annual"
                ? `${formatNumberInCurrency(
                    planAnnualPriceAfterDiscount,
                    paymentDetails.planCurrency
                  )}/year`
                : `${formatNumberInCurrency(
                    planMonthlyPriceAfterDiscount,
                    paymentDetails.planCurrency
                  )}/month`}
            </Value>
          </InfoItem>
          <InfoItem xs={7} md={3} offset={{ xs: 5, md: 0 }}>
            {/* Button to switch between monthly and annual plans */}
            <button
              className="button button-secondary-link"
              onClick={() => setWantsToChangeBillingInterval(true)}
            >
              Switch to{" "}
              {paymentDetails.planInterval === "Annual" ? "Monthly" : "Annual"}
            </button>
          </InfoItem>
        </InfoRow>
      )}

      <Modal
        title="Sponsored Logbooks"
        explanation="If a company is sponsoring a logbook for you on inndox then their name will be shown in a banner at the bottom of the Property dashboard."
        isOpen={shouldShowSponsoredInfo}
        onClose={() => setShouldShowSponsoredInfo(false)}
      ></Modal>

      <SwitchBillingCycle
        paymentDetails={paymentDetails}
        isOpen={wantsToChangeBillingInterval}
        onClose={() => setWantsToChangeBillingInterval(false)}
      />

      {/* Handovers Completed */}
      <InfoRow>
        <InfoItem xs={5} md={3}>
          <Label>{!isCompanyUser ? "Logbooks" : "Handovers"}</Label>
        </InfoItem>
        <InfoItem xs={7} md={7}>
          <Value>
            {paymentDetails.planLimit
              ? `${paymentDetails.handoverCount}/${paymentDetails.planLimit}`
              : paymentDetails.handoverCount}
          </Value>
        </InfoItem>
      </InfoRow>

      {/* Credit Card */}
      <InfoRow>
        <InfoItem xs={5} md={3}>
          <Label>Credit Card</Label>
        </InfoItem>
        <InfoItem xs={7} md={7}>
          {!hasNeverSubscribed && !paymentDetails.creditCardIsValid ? (
            <ExpiredCardContent>
              <Value>{paymentDetails.creditCardText}</Value>
              <ExpiredCardLabel>Expired</ExpiredCardLabel>
            </ExpiredCardContent>
          ) : (
            <Value>{paymentDetails.creditCardText}</Value>
          )}
        </InfoItem>
        <InfoItem xs={7} md={2} offset={{ xs: 5, md: 0 }}>
          {hasNeverSubscribed ? (
            <noscript />
          ) : (
            <button
              className="button button-secondary-link"
              style={{ padding: 0 }}
              onClick={() => setIsUpdatingCreditCardDetails(true)}
            >
              Update
            </button>
          )}
        </InfoItem>
      </InfoRow>
      <Modal
        title="Update Payment Details"
        isOpen={isUpdatingCreditCardDetails}
        onClose={() => setIsUpdatingCreditCardDetails(false)}
      >
        <Elements stripe={stripePromise}>
          <PaymentForm
            paymentDetails={paymentDetails}
            onFinish={() => setIsUpdatingCreditCardDetails(false)}
          />
        </Elements>
      </Modal>

      {/* Promo Code */}
      <InfoRow>
        <InfoItem xs={5} md={3}>
          <Label>Promotion</Label>
        </InfoItem>
        <InfoItem xs={7} md={7}>
          <Value>{paymentDetails.promoName || "-"}</Value>
        </InfoItem>
        <InfoItem xs={7} md={2} offset={{ xs: 5, md: 0 }}>
          <button
            className="button button-secondary-link"
            style={{ padding: 0 }}
            onClick={() => setIsUpdatingPromoCode(true)}
          >
            {paymentDetails.promoName ? "Update" : "Add"}
          </button>
        </InfoItem>
      </InfoRow>
      <Modal
        title="Update Promotional Code"
        explanation="Provide the promotional code that you'd like applied to any future purchases."
        isOpen={isUpdatingPromoCode}
        onClose={() => setIsUpdatingPromoCode(false)}
      >
        <Form
          initialValues={{ couponId: paymentDetails.promoCode }}
          onSubmit={async (values) => {
            try {
              const updatedPaymentDetails =
                await PaymentsApi.updateSubscription(values);
              dispatch(updatePaymentDetails(updatedPaymentDetails));
              toast.success(
                <Toast
                  title="Success!"
                  details="The promocode has been added to your account and will be applied to future subscription payments."
                />
              );
              setIsUpdatingPromoCode(false);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <TextField
                name="couponId"
                label="Promo Code"
                placeholder="Promotional Code"
                className="margin-top-2"
                type="text"
                required
                validate={requiredValidator}
              />

              <button
                className="button button-primary margin-top-4 flex center"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader size={16} color="#fff" loading={true} />
                ) : (
                  "Update"
                )}
              </button>
            </>
          )}
        />
      </Modal>
      {/* Past Payments */}
      {paymentDetails?.payments ? <PastPayments /> : <noscript />}
    </>
  );
}

// this loads stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function PaymentForm({ paymentDetails, onFinish }) {
  const dispatch = useDispatch();
  const { discount = 0, planMonthlyPrice, plan, planStatus } = paymentDetails;
  const planMonthlyPriceAfterDiscount = Math.round(
    planMonthlyPrice * (1 - discount / 100)
  );
  const stripe = useStripe();
  const elements = useElements();
  const isCompanyUser = useSelector(isCurrentUserACompany);

  return (
    <Form
      onSubmit={async (values) => {
        try {
          const createTokenResponse = await stripe.createToken(
            elements.getElement(CardElement, {
              name: values.cardholderName,
            })
          );

          if (createTokenResponse.error) {
            throw createTokenResponse.error;
          }

          if (!createTokenResponse.token || !createTokenResponse.token.id) {
            // TODO: Remove this log
            console.error(createTokenResponse.token);
            throw new Error(
              "An unexpected error occurred processing your payment. Please try again later."
            );
          }

          const data = {
            token: createTokenResponse.token.id,
            subscribeToPlan: planStatus === "None",
          };
          if (values.couponId?.length > 0) {
            data.couponId = values.couponId;
          }
          const updatedPaymentDetails = await PaymentsApi.updateSubscription(
            data
          );
          toast.success(<Toast title="Payment details updated" />);

          dispatch(updatePaymentDetails(updatedPaymentDetails));

          if (onFinish) {
            onFinish();
          }
        } catch (e) {
          handleError(e);
        }
      }}
      render={(props) => (
        <>
          {/* Card Holder Name */}
          <TextField
            name="cardholderName"
            label="Cardholder's name"
            className="margin-top-2"
            required
            validate={requiredValidator}
          />

          {/* Card Details */}
          <div className="form-element margin-top-2">
            <div className="form-label">
              <label>
                Card details
                <span>*</span>
              </label>
            </div>

            {/* Input */}
            <div className="form-input">
              <StyledCardElement
                className="stripe-input"
                // onReady={() => setIsLoading(false)}
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "inherit",
                      fontWeight: "normal",
                      color: "inherit",
                    },
                  },
                }}
              />
            </div>
          </div>

          {/* Promo */}
          {paymentDetails.discount === 0 ? (
            <TextField
              name="couponId"
              label="Promotion"
              className="margin-top-2"
            />
          ) : (
            <noscript />
          )}

          {/* Copy */}
          <p className="margin-top-4 text-center" style={{ fontSize: "13px" }}>
            Your card will be billed AUD {planMonthlyPriceAfterDiscount}/month.
            Find out more in our{" "}
            <a
              href={
                plan !== "Master"
                  ? "https://www.inndox.com/terms-conditions"
                  : "https://www.inndox.com/enterprise-terms-conditions"
              }
              rel="noopener noreferrer"
              target="_blank"
              className="link-style-elem"
            >
              Terms and condition.
            </a>
          </p>

          <button
            className="button button-primary button-large button-big full-width margin-top-3"
            disabled={!stripe}
            onClick={
              props.submitting || !stripe ? undefined : props.handleSubmit
            }
          >
            {props.submitting ? (
              <ClipLoader size={16} color="#fff" loading />
            ) : paymentDetails.planStatus === "None" ? (
              isCompanyUser ? (
                "Process payment"
              ) : (
                "Start my free trial"
              )
            ) : (
              "Update credit card"
            )}
          </button>
        </>
      )}
    />
  );
}
