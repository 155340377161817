import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectCurrentLogbookDefects } from '../store/features/logbooks.slice';
import { useCallback, useEffect, useState } from 'react';
import warningYellowIcon from '../assets/images/warning-yellow.svg';
import warningRedIcon from '../assets/images/warning-red.svg';
import closeIcon from '../assets/images/close.svg';
import moment from 'moment';
import { Col, Container, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes } from '../routes';

const RecentNotification = styled.div`
	background-color: #ffffff;
	border-radius: 12px;
	padding: 24px;
	position: relative;
`;

const CloseButton = styled.img`
	width: 12px;
	height: 12px;
	position: absolute;
	top: 12px;
	right: 12px;
`;

export default function RecentDefectNotification() {
	const defects = useSelector(selectCurrentLogbookDefects);
	const [defect, setDefect] = useState();
	const navigate = useNavigate();

	const getDefectToDisplay = useCallback(() => {
		if (!defects || defects.length === 0) {
			return;
		}

		const sortedAndFilteredDefects = defects
			.filter((defect) => defect.status === 'Open')
			.filter(
				(defect) =>
					localStorage.getItem(defect.property.id + '/' + defect.id) !== 'yes'
			)
			.sort((a, b) => {
				const dateA = new Date(a.createdOn);
				const dateB = new Date(b.createdOn);

				return dateB - dateA;
			});

		if (!sortedAndFilteredDefects.length) {
			return;
		}

		return sortedAndFilteredDefects[0];
	}, [defects]);

	const closeDefect = () => {
		// update local storage
		localStorage.setItem(defect.property.id + '/' + defect.id, 'yes');

		// update local defects
		setDefect(getDefectToDisplay());
	};

	useEffect(() => {
		setDefect(getDefectToDisplay());
	}, [getDefectToDisplay]);

	if (!defect) {
		return <noscript />;
	}

	return (
		<Container>
			<Row>
				<Col>
					<RecentNotification className="flex center margin-bottom-2">
						<img
							src={defect.isUrgent ? warningRedIcon : warningYellowIcon}
							alt="warning"
							width={32}
							height={32}
						/>

						<div className="flex col margin-left-2" style={{ flexGrow: 1 }}>
							<p>
								<strong>
									{defect.isUrgent ? 'Urgent New Claim Added' : 'New Claim Added'}
								</strong>
							</p>

							<p>
								{defect.subject} has been added on{' '}
								{moment(defect.createdOn).format('MMM D, YYYY')}
							</p>
						</div>

						<button
							className={`margin-right-2 button button-warning${
								defect.isUrgent ? '-urgent' : ''
							}`}
							onClick={() =>
								navigate(
									// This is only for company and from the logbook itself.
									// So no special condition to check. Check the full logic
									// in DefectRowItem
									'/' +
										CompanyRoutes.basePath.replace('/*', '') +
										'/' +
										CompanyRoutes.logbook.replace(':logbookId', defect.property.id) +
										'/' +
										CompanyRoutes.logbookDefect.replace(':defectId', defect.id)
								)
							}>
							View more info
						</button>

						<CloseButton src={closeIcon} alt="close" onClick={closeDefect} />
					</RecentNotification>
				</Col>
			</Row>
		</Container>
	);
}
