import { Form } from 'react-final-form';
import { TemplatesApi } from '../api/templates';
import {
	addTemplate,
	selectTemplates,
} from '../store/features/templates.slice';
import Modal from './modal';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from './helpers';
import { ClipLoader } from 'react-spinners';

export default function CloneTemplateDialog({ isOpen, onClose, templateId }) {
	const dispatch = useDispatch();
	const templates = useSelector(selectTemplates);
	const template = templates.find((template) => template.id === templateId);

	return (
		<Modal title="Clone Template" isOpen={isOpen} onClose={onClose}>
			<p className="margin-top-2">
				Are you sure you want to clone the template -{' '}
				<strong>{template.name}</strong> ?
			</p>

			<div className="flex margin-top-3">
				<Form
					onSubmit={async () => {
						try {
							const clonedTemplate = await TemplatesApi.cloneTemplate(templateId);
							dispatch(addTemplate(clonedTemplate));

							onClose();
						} catch (e) {
							handleError(e);
						}
					}}
					render={(props) => (
						<button
							className="button button-primary"
							onClick={props.submitting ? undefined : props.handleSubmit}>
							{props.submitting ? (
								<ClipLoader loading size={16} color="#fff" />
							) : (
								'Clone template'
							)}
						</button>
					)}
				/>

				<button className="button button-alt margin-left-1" onClick={onClose}>
					Cancel
				</button>
			</div>
		</Modal>
	);
}
