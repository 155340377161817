import DocumentTitle from 'react-document-title';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import MoreMenu, {
	ADD_LINK_TO_FOLDER_MENU_ITEM,
	UPLOAD_FILES_TO_FOLDER_MENU_ITEM,
} from '../../components/more-menu';
import DocumentList from './document-list';
import uploadIcon from '../../assets/images/upload_icon.svg';
import viewIcon from '../../assets/images/upload_icon.svg';
import { SharedRoutes } from '../../routes';
import { useNavigate } from 'react-router-dom';

const PrivateFolderWarningContainer = styled.div`
	background-color: #203d5e;
	padding: 16px 24px;
	border-radius: 6px;
	color: #fff;
	margin-top: 16px;
	font-style: italic;
`;

const Permission = styled.div`
	margin-right: 16px;
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 1.38;
	color: #d7def0;
	width: 112px;
	flex-shrink: 0;
`;

const PermissionIcon = styled.div`
	width: 32px;
	height: 32px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
`;

/**
 * @typedef {Object} LogbookFolderProps
 * @property {inndox.SharedFolder} folder
 * @property {number} logbookId
 * @property {string} label
 */

/**
 *
 * @param {LogbookFolderProps} props
 */
export default function SharedLogbookFolder({ folder, logbookId, label }) {
	const navigate = useNavigate();
	const canUpload = folder.sharePermissions.indexOf('Create') > -1;

	const actionSelectedMenuOption = (value) => {
		switch (value) {
			case UPLOAD_FILES_TO_FOLDER_MENU_ITEM.value: {
				// add files to folder
				const path =
					'/' +
					SharedRoutes.basePath.replace('/*', '') +
					'/' +
					SharedRoutes.addDocuments
						.replace(':logbookId', logbookId)
						.replace(':folderId', folder.id);
				navigate(path);

				break;
			}

			case ADD_LINK_TO_FOLDER_MENU_ITEM.value: {
				// add link to folder
				const path =
					'/' +
					SharedRoutes.basePath.replace('/*', '') +
					'/' +
					SharedRoutes.addLink
						.replace(':logbookId', logbookId)
						.replace(':folderId', folder.id);
				navigate(path);

				break;
			}

			default:
				break;
		}
	};

	const menuOptions = [];
	if (canUpload) {
		menuOptions.push(UPLOAD_FILES_TO_FOLDER_MENU_ITEM);
		menuOptions.push(ADD_LINK_TO_FOLDER_MENU_ITEM);
	}

	const accessory = canUpload ? (
		<Permission style={{ justifyContent: 'flex-end' }}>
			<PermissionIcon>
				<img src={uploadIcon} alt="Upload icon" />
			</PermissionIcon>
			Upload
		</Permission>
	) : (
		<Permission style={{ justifyContent: 'flex-end' }}>
			<PermissionIcon>
				<img src={viewIcon} alt="View only icon" />
			</PermissionIcon>
			View only
		</Permission>
	);

	const contents = (
		<DocumentTitle title={`${folder.name} | inndox`}>
			<Container className="folder">
				<>
					<Row className="margin-bottom-4">
						<Col xs={12} md={6}>
							<h2 className="name">{folder.name}</h2>
						</Col>

						<Col xs={12} md={6} className="flex end">
							{canUpload ? (
								<button
									className="button button-secondary margin-right-2"
									onClick={() =>
										navigate(
											'/' +
												SharedRoutes.basePath.replace('/*', '') +
												'/' +
												SharedRoutes.addDocuments
													.replace(':logbookId', logbookId)
													.replace(':folderId', folder.id)
										)
									}>
									Upload file
								</button>
							) : (
								<noscript />
							)}

							<MoreMenu options={menuOptions} onSelect={actionSelectedMenuOption} />
						</Col>

						<Col xs={12}>
							{/* Info note about private folder */}
							{folder.isPrivate ? (
								<PrivateFolderWarningContainer>
									This is a private folder. The contents of this folder will not be
									transferred during a handover.
								</PrivateFolderWarningContainer>
							) : (
								<noscript />
							)}
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="flex center between">
							<label>{`${label}, Shared By ${folder?.sharedBy}`}</label>
							{accessory}
						</Col>
					</Row>
					<DocumentList
						documents={folder.documents}
						documentsAreInsideAFolder
						isInsideASharedFolder
					/>
				</>
			</Container>
		</DocumentTitle>
	);

	return contents;
}
