import { Navigate } from 'react-router-dom';
import { useAuth } from '../../components/authentication';
import { CompanyRoutes, OwnerRoutes } from '../../routes';

export default function RouteDecider() {
	const { isCompanyUser } = useAuth();

	if (isCompanyUser) {
		return (
			<Navigate
				to={
					'/' + CompanyRoutes.basePath.replace('/*', '') + '/' + CompanyRoutes.home
				}
			/>
		);
	}

	return (
		<Navigate
			to={'/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.landing}
		/>
	);
}
