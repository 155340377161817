import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { selectSharedLogbooks } from '../../store/features/logbooks.slice';
import { OwnerRoutes } from '../../routes';
import { useNavigate } from 'react-router-dom';
import SharedLogbooks from '../../components/shared-logbooks-list';
import { isCurrentUserACompany } from '../../store/features/user.slice';
import DocumentTitle from 'react-document-title';
import OwnerHomeToggle from '../../components/owner-home-toggle';

export default function SharedLogbooksHome() {
	const sharedLogbooks = useSelector(selectSharedLogbooks);
	const isCompanyUser = useSelector(isCurrentUserACompany);
	const navigate = useNavigate();

	const shouldShowViewSwitcher = !isCompanyUser && sharedLogbooks?.length > 0;

	return (
		<DocumentTitle title="Shared Folders | inndox">
			<Container className="folder">
				{/* Header */}
				<Row>
					{shouldShowViewSwitcher ? (
						// Radios for switching
						<Col xs={12} sm={6} className="margin-top-2 margin-bottom-1 flex">
							<OwnerHomeToggle
								currentOption={2}
								onOptionClick={(selected) => {
									if (selected === 2) {
										// do nothing
										// as we are already on shared home
									} else {
										// navigate away
										navigate(
											'/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.home
										);
									}
								}}
							/>
						</Col>
					) : (
						<Col xs={12}>
							<h2 className="name margin-bottom-4">Logbooks shared with me</h2>
						</Col>
					)}
				</Row>

				{/* Shared Folders */}
				<SharedLogbooks logbooks={sharedLogbooks} />
			</Container>
		</DocumentTitle>
	);
}
