import ViewClaim from '../common/view-claim';
import { useSelector } from 'react-redux';
import { selectCompanyDefects } from '../../store/features/company.slice';
import { useParams } from 'react-router-dom';

export default function ViewCompanyDefect() {
	const { defectId: defectIdPathParam } = useParams();
	const defectId = parseInt(defectIdPathParam);
	const defects = useSelector(selectCompanyDefects);
	const defect = defects?.find((defect) => defect.id === defectId);

	return <ViewClaim defect={defect} />;
}
