import DocumentTitle from 'react-document-title';
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system';
import MoreMenu, {
	IMPORT_CSV_CONTACTS_TO_ACCOUNT_MENU_ITEM,
	IMPORT_XERO_CONTACTS_TO_ACCOUNT_MENU_ITEM,
} from '../../components/more-menu';
import {
	selectCompany,
	selectCompanyContacts,
	setCompanyContacts,
} from '../../store/features/company.slice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes } from '../../routes';
import ContactsList, { sortContacts } from '../../components/contact-list';
import { useState } from 'react';
import Modal from '../../components/modal';
import { Form } from 'react-final-form';
import Dropzone from '../../components/form/dropzone';
import sampleCsvFile from '../../assets/files/sample.csv';
import { ClipLoader } from 'react-spinners';
import { ContactsApi } from '../../api/contacts';
import { handleError } from '../../components/helpers';
import styled from 'styled-components';

const Link = styled.a`
	display: block;
	margin-top: 8px;
	margin-bottom: 24px;
	color: #a0b42a;
	font-size: 14px;
	text-align: left;
`;

export default function CompanyContacts() {
	const dispatch = useDispatch();
	const company = useSelector(selectCompany);
	const contacts = useSelector(selectCompanyContacts);
	const navigate = useNavigate();
	const [wantsToImportContactsViaCsv, setWantsToImportContactsViaCsv] =
		useState(false);
	const [wantsToImportContactsFromXero, setWantsToImportContactsFromXero] =
		useState(false);

	const actionSelectedMenuOptions = (value) => {
		switch (value) {
			case IMPORT_XERO_CONTACTS_TO_ACCOUNT_MENU_ITEM.value: {
				// import from xero
				setWantsToImportContactsFromXero(true);
				break;
			}

			case IMPORT_CSV_CONTACTS_TO_ACCOUNT_MENU_ITEM.value: {
				// import via csv
				setWantsToImportContactsViaCsv(true);
				break;
			}

			default:
				break;
		}
	};

	const menuOptions = [];
	if (company?.integrations?.includes('Xero')) {
		menuOptions.push(IMPORT_XERO_CONTACTS_TO_ACCOUNT_MENU_ITEM);
	}
	menuOptions.push(IMPORT_CSV_CONTACTS_TO_ACCOUNT_MENU_ITEM);

	return (
		<DocumentTitle title="Company Contacts | inndox">
			<Container className="folder">
				<Row className="margin-bottom-2">
					<Col xs={12} md={8} className="flex">
						<Hidden xs>
							<h2 className="name">Account Contacts</h2>
						</Hidden>

						<Visible xs>
							<h2 className="name margin-bottom-2">Account Contacts</h2>
						</Visible>
					</Col>

					<Col xs={12} md={4} className="flex end">
						<button
							className="button button-secondary margin-right-2"
							onClick={() =>
								navigate(
									'/' +
										CompanyRoutes.basePath.replace('/*', '') +
										'/' +
										CompanyRoutes.addCompanyContact
								)
							}>
							Add a new contact
						</button>

						<MoreMenu options={menuOptions} onSelect={actionSelectedMenuOptions} />
					</Col>
				</Row>

				<ContactsList contacts={sortContacts(contacts)} canEdit showCompany />

				{/* Import via CSV  */}
				<Modal
					isOpen={wantsToImportContactsViaCsv}
					title="Import Contacts via CSV"
					onClose={() => setWantsToImportContactsViaCsv(false)}>
					<Form
						onSubmit={async (values) => {
							try {
								// gather form data
								const formData = new FormData();
								const files = values['csvFile'];
								formData.append('csvFile', files[0]);

								// send CSV file to backend
								await ContactsApi.importContactsFromCsv(formData);

								// fetch list of contacts
								const contacts = await ContactsApi.fetchContacts();
								dispatch(setCompanyContacts(contacts));
							} catch (e) {
								handleError(e);
							}
						}}
						render={(props) => (
							<>
								<Dropzone
									name="csvFile"
									placeholder="Drag a file here or click to browse"
									className="margin-top-4"
									accept=".csv"
								/>

								<Link download href={sampleCsvFile}>
									Download sample CSV file
								</Link>

								<button
									className="button button-primary margin-top-4"
									onClick={props.submitting ? undefined : props.handleSubmit}>
									{props.submitting ? (
										<ClipLoader loading size={16} color="#fff" />
									) : (
										'Upload'
									)}
								</button>
							</>
						)}
					/>
				</Modal>

				<Modal
					isOpen={wantsToImportContactsFromXero}
					title="Import Contacts from Xero"
					onClose={() => setWantsToImportContactsFromXero(false)}>
					<p style={{ marginTop: 16 }}>
						Are you sure you want to import contacts from Xero?
					</p>

					<Form
						onSubmit={async () => {
							try {
								await ContactsApi.importContactsFromXero();

								// fetch list of contacts
								const contacts = await ContactsApi.fetchContacts();
								dispatch(setCompanyContacts(contacts));
							} catch (e) {
								handleError(e);
							}
						}}
						render={(props) => (
							<>
								<button
									className="button button-primary margin-top-4"
									onClick={props.submitting ? undefined : props.handleSubmit}>
									{props.submitting ? (
										<ClipLoader loading size={16} color="#fff" />
									) : (
										'Import contacts'
									)}
								</button>
							</>
						)}
					/>
				</Modal>
			</Container>
		</DocumentTitle>
	);
}
