/**
 * @typedef {Object} DefectListProps
 * @property {inndox.LogbookDefect[]} defects
 * @property {'global' | 'logbook'} source
 * @property {boolean} isCurrentUserACompany
 */

import { Col, Hidden, Row } from 'react-grid-system';
import LogbookDefectRowItem from '../../components/defect-row-item';

/**
 *
 * @param {DefectListProps} props
 */
export default function DefectList({ defects, source, isCurrentUserACompany }) {
	return (
		<div id="document-list" className="margin-top-3 document-list">
			{/* Header */}
			<Row>
				{/* Status */}
				<Col xs={3} sm={2} className="col-head">
					Status
				</Col>

				{/* Created On */}
				<Hidden xs>
					<Col sm={3} className="col-head">
						Reported on
					</Col>
				</Hidden>

				{/* Address */}
				{/* <Hidden xs>
					<Col sm={4} className="col-head">
						Asset
					</Col>
				</Hidden> */}

				{/* Subject */}
				<Col xs={6} className="col-head">
					Subject
				</Col>

				<Col xs={3} sm={1} className="col-head">
					&nbsp;
				</Col>
			</Row>

			{/* List */}
			{defects.map((defect, index) => (
				<LogbookDefectRowItem
					key={index}
					defect={defect}
					propertyId={defect.property.id}
					source={source}
					isCurrentUserACompany={isCurrentUserACompany}
					showAddress
				/>
			))}
		</div>
	);
}
