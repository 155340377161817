import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../components/fifty-fifty";
import logbookSideImage from "../../assets/images/bg2.jpg";
import BackButton from "../../components/back-button";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import { selectCurrentTemplate } from "../../store/features/templates.slice";
import { useSelector } from "react-redux";
import { selectCompanyContacts } from "../../store/features/company.slice";

const Content = styled.div`
  margin-left: 0;
  margin-right: auto;
  max-width: 450px;
  width: 100%;
  text-align: left;
`;

export default function ViewContact() {
  const { contactId: contactIdPathParam } = useParams();
  const contactId = parseInt(contactIdPathParam);
  const { pathname } = useLocation();
  const isPartOfATemplate = pathname.includes("templates");
  const isPartOfALogbook = pathname.includes("logbooks");
  const isCompanyUser = pathname.includes("company");
  const isViewingAtCompanyLevel =
    isCompanyUser && !isPartOfALogbook && !isPartOfATemplate;

  const currentLogbook = useSelector(selectCurrentLogbook);
  const currentTemplate = useSelector(selectCurrentTemplate);
  const companyContacts = useSelector(selectCompanyContacts);

  const logbook = isPartOfATemplate ? currentTemplate : currentLogbook;
  const contact = isViewingAtCompanyLevel
    ? companyContacts.find((contact) => contact.id === contactId)
    : logbook.contacts.find((contact) => contact.id === contactId);

  return (
    <DocumentTitle title="Contact Details | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <BackButton />
          <h1>Contact Details</h1>

          <div className="margin-top-4">
            <label>Name</label>
            <p>{contact.firstName + " " + contact.lastName}</p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Email</label>
            <p>{contact.email || "-"}</p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Phone</label>
            <p>{contact.phone || "-"}</p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Type</label>
            <p>
              {contact.contactType
                ? contact.contactType === "Other"
                  ? contact.typeOther || "-"
                  : contact.contactType
                : "-"}
            </p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Address</label>
            <p>{contact.address || "-"}</p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Company</label>
            <p>{contact.company || "-"}</p>
          </div>
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
