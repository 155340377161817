import DocumentTitle from "react-document-title";
import LogbookHeader from "../../components/logbook-header";
import { useSelector } from "react-redux";
import { selectCompany } from "../../store/features/company.slice";
import { selectSharedLogbooks } from "../../store/features/logbooks.slice";
import { Col, Container, Row } from "react-grid-system";
import RecentlyUpdatedLogbooks from "../../components/recent-logbooks";
import TemplatesWidget from "../../components/templates-widget";
import SubCompaniesWidget from "../../components/sub-companies-widget";
import SharedLogbooksWidget from "../../components/shared-logbooks-widget";
import Tabs from "../../components/tabs";

export default function CompanyHome() {
  // store
  const company = useSelector(selectCompany);

  // computed
  const coverImage = company?.backgroundPath;

  return (
    <DocumentTitle title={`${company?.name} | inndox`}>
      <>
        <LogbookHeader
          isCompanyView
          companyId={company.id}
          isOwner={false}
          coverImage={coverImage}
        />

        {/* Hide breadcrumbs */}
        <style
          dangerouslySetInnerHTML={{
            __html: ".breadcrumbs-container { display: none; }",
          }}
        />

        <Container>
          <MainContent />
        </Container>
      </>
    </DocumentTitle>
  );
}

function MainContent() {
  const company = useSelector(selectCompany);
  const sharedLogbooks = useSelector(selectSharedLogbooks);

  const tabs = [
    {
      label: "Logbooks",
      content: <RecentlyUpdatedLogbooks />,
    },
    {
      label: "Templates",
      content: <TemplatesWidget />,
    },
  ];

  if (company?.isMasterCompany) {
    tabs.push({
      label: "Companies",
      content: <SubCompaniesWidget isTab={true} />,
    });
  }

  if (sharedLogbooks) {
    tabs.push({
      label: "Folders shared with me",
      content: <SharedLogbooksWidget logbooks={sharedLogbooks} />,
    });
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <Tabs tabs={tabs} />
        </Col>
      </Row>

      {/* Articles */}
      {/* <Articles max={3} tags={['builders']} /> */}
    </>
  );
}
