import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../components/fifty-fifty";
import { selectLogbooks } from "../../store/features/logbooks.slice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  EDIT_LOGBOOK_TYPE_TO_CLASS_MAPPING,
  LOGBOOK_TYPE_TO_SIDE_IMAGE_MAPPING,
} from "../../components/logbook-edit-forms";
import moment from "moment";
import { styled } from "styled-components";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function EditLogbook() {
  const { logbookId: logbookIdPathParam } = useParams();
  const logbookId = parseInt(logbookIdPathParam);
  const logbooks = useSelector(selectLogbooks);
  const logbook = logbooks.find((logbook) => logbook.id === logbookId);
  const LogbookForm = EDIT_LOGBOOK_TYPE_TO_CLASS_MAPPING[logbook.logbookType];

  const editLogbookInitialValues = {
    id: logbook.id,
    logbookType: logbook.logbookType,
    propertyType: logbook.propertyType,
    model: logbook.model,
    make: logbook.make,
    serialNumber: logbook.serialNumber,
    address: logbook.address,
    logbookDate: moment(logbook.logbookDate).year(),
    unit: logbook.unit,
    ...(logbook.isManualAddress
      ? {
          streetAddress: logbook.streetAddress,
          state: logbook.state,
          country: logbook.country,
          postcode: logbook.postcode,
          suburb: logbook.suburb,
        }
      : {}),
  };

  return (
    <DocumentTitle title="Edit logbook | inndox">
      <FiftyFifty
        backgroundImage={
          LOGBOOK_TYPE_TO_SIDE_IMAGE_MAPPING[logbook.logbookType]
        }
      >
        <Content>
          <h1>Edit logbook</h1>

          <p
            className="margin-top-1"
            style={{
              maxWidth: 326,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 20,
            }}
          >
            Update your logbook details
          </p>

          <LogbookForm initialValues={editLogbookInitialValues} />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
