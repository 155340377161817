import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../components/fifty-fifty";
import addDocumentsSideImage from "../../assets/images/upload-images-bg.png";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isCurrentUserACompany } from "../../store/features/user.slice";
import { CompanyRoutes, OwnerRoutes, SharedRoutes } from "../../routes";
import styled from "styled-components";
import Dropzone from "../../components/form/dropzone";
import { ClipLoader } from "react-spinners";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import Toast from "../../components/toast";
import { DocumentsApi } from "../../api/documents";
import {
  addDocumentToLogbook,
  addDocumentToSharedLogbook,
  incrementLogbookFolderStatistics,
} from "../../store/features/logbooks.slice";
import {
  addDocumentToCurrentTemplate,
  incrementTemplateFolderStatistics,
} from "../../store/features/templates.slice";
import { handleError } from "../../components/helpers";

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const allowedFileTypes = {
  "image/*": [".png", ".gif", ".jpeg", ".jpg", ".tiff"],
  "application/*": [".doc", ".docx", ".pdf", ".xlsx", ".xls", ".zip"],
  "text/*": [".txt"],
  "video/*": [".mp4"],
};

export default function AddDocuments() {
  const {
    logbookId: logbookIdPathParam,
    folderId,
    templateId: templateLogbookIdPathParam,
  } = useParams();
  const logbookId = parseInt(logbookIdPathParam || templateLogbookIdPathParam);
  const { pathname, key } = useLocation();
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const navigate = useNavigate();
  const isViewingASharedFolder = folderId
    ? pathname.includes("/" + SharedRoutes.basePath.replace("/*", ""))
    : false;
  const backPath =
    key !== "default"
      ? -1
      : isCompanyUser
      ? pathname.replace("/" + CompanyRoutes.addLogbookDocuments, "")
      : pathname.replace("/" + OwnerRoutes.addLogbookDocuments, "");
  const isAddingToATemplate = pathname.includes("/templates");
  const dispatch = useDispatch();

  return (
    <DocumentTitle title="Add Documents | inndox">
      <FiftyFifty backgroundImage={addDocumentsSideImage}>
        <Form
          onSubmit={async (values) => {
            if (!values.documentFiles?.length) {
              toast.error(<Toast title="Please add at least one file" />);

              return;
            }

            const documentFiles = values.documentFiles;
            const documentUploadRequests = documentFiles.map((file) => {
              const formData = new FormData();
              formData.append("propertyId", logbookId);
              if (folderId) {
                formData.append("folderId", folderId);
              }
              formData.append("documentFile", file);
              if (file.shouldUnpack) {
                formData.append("unpackZipFile", file.shouldUnpack);
              }

              return DocumentsApi.uploadDocumentsToProperty(
                logbookId,
                formData
              );
            });

            try {
              const uploadedDocuments = await Promise.all(
                documentUploadRequests
              );
              uploadedDocuments.forEach((document) => {
                if (isViewingASharedFolder) {
                  dispatch(addDocumentToSharedLogbook(document));
                } else {
                  if (isAddingToATemplate) {
                    dispatch(addDocumentToCurrentTemplate(document));
                    if (folderId) {
                      dispatch(
                        incrementTemplateFolderStatistics({
                          id: folderId,
                        })
                      );
                    }
                  } else {
                    dispatch(addDocumentToLogbook(document));
                    if (folderId) {
                      dispatch(
                        incrementLogbookFolderStatistics({
                          id: folderId,
                        })
                      );
                    }
                  }
                }
              });

              navigate(backPath);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <Content>
              <h1 className="margin-bottom-6">Upload new file(s)</h1>

              <Dropzone
                name="documentFiles"
                placeholder="Drag file(s) here or click to browse"
                multiple
                accept={allowedFileTypes}
                isUploading={props.submitting}
                allowUnzip={!folderId}
              />

              <button
                className={`button button-primary button-large button-big margin-top-4 full-width ${
                  props.values.documentFiles?.length ? null : "button-disabled"
                }`}
                onClick={
                  props.values.documentFiles?.length
                    ? props.submitting
                      ? undefined
                      : props.handleSubmit
                    : null
                }
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Upload"
                )}
              </button>

              <button
                className="button button-remove button-big margin-top-1 full-width"
                onClick={() => navigate(backPath)}
              >
                Cancel
              </button>
            </Content>
          )}
        />
      </FiftyFifty>
    </DocumentTitle>
  );
}
