import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../components/fifty-fifty";
import logbookSideImage from "../../assets/images/bg2.jpg";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../../routes";
import { Form } from "react-final-form";
import { ClipLoader } from "react-spinners";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import Checkbox from "../../components/form/checkbox";
import { handleError } from "../../components/helpers";
import { FoldersApi } from "../../api/folders";
import { useDispatch } from "react-redux";
import { addFolderToLogbook } from "../../store/features/logbooks.slice";
import { addFolderToTemplate } from "../../store/features/templates.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function AddFolder() {
  const { pathname } = useLocation();
  const {
    logbookId: logbookIdPathParam,
    templateId: templateLogbookIdPathParam,
  } = useParams();
  const logbookId = parseInt(logbookIdPathParam || templateLogbookIdPathParam);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAddingToATemplate = pathname.includes("templates");
  const backPath = isAddingToATemplate
    ? pathname.replace("/" + TemplateRoutes.newTemplateFolder, "")
    : pathname.includes("/" + CompanyRoutes.basePath.replace("/*", ""))
    ? pathname.replace("/" + CompanyRoutes.addFolder, "")
    : pathname.replace("/" + OwnerRoutes.addFolder, "");

  return (
    <DocumentTitle title="Add Folder | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content className="margin-top-1">
          <h1 className="margin-bottom-4">Add Folder</h1>
          <p className="margin-top-1">
            Easily manage your documents by adding a new folder below.
          </p>

          <Form
            initialValues={{
              propertyId: logbookId,
            }}
            onSubmit={async (values) => {
              try {
                const newFolder = await FoldersApi.createNewFolder(
                  logbookId,
                  values
                );

                if (isAddingToATemplate) {
                  dispatch(addFolderToTemplate(newFolder));
                } else {
                  dispatch(addFolderToLogbook(newFolder));
                }

                navigate(backPath);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => {
              return (
                <>
                  <TextField
                    name="name"
                    label="Name"
                    required
                    validate={requiredValidator}
                    className="margin-top-2"
                  />

                  <TextField
                    name="description"
                    label="Description"
                    className="margin-top-2"
                  />

                  <Checkbox
                    label="Is this a private folder?"
                    name="isPrivate"
                    className="margin-top-1"
                  />

                  <button
                    className="button button-primary button-large button-big full-width margin-top-4"
                    onClick={props.submitting ? undefined : props.handleSubmit}
                  >
                    {props.submitting ? (
                      <ClipLoader loading size={16} color="#fff" />
                    ) : (
                      "Add folder"
                    )}
                  </button>

                  <button
                    className="button button-secondary-link button-big full-width margin-top-2"
                    onClick={() => navigate(backPath)}
                  >
                    Back
                  </button>
                </>
              );
            }}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
