import DocumentTitle from 'react-document-title';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { selectCurrentLogbook } from '../../store/features/logbooks.slice';
import DocumentList from '../common/document-list';
import noFiles from '../../assets/images/no-files.svg';

export default function OwnerLogbookArchive() {
	const logbook = useSelector(selectCurrentLogbook);
	const documents = logbook.documents;
	const archivedDocuments = documents.filter(
		(document) => !!document.dateArchived
	);

	if (!archivedDocuments?.length) {
		return (
			<DocumentTitle title={`Archived Files | inndox`}>
				<Container>
					<Row>
						<Col xs={12} className="document-empty-state">
							<img src={noFiles} alt="no-files-img" />

							<div className="flex col">
								<h1>No files archived</h1>
								<p className="margin-top-2">
									There has been no files archived from your logbook
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</DocumentTitle>
		);
	}

	return (
		<DocumentTitle title={`Archived Files | inndox`}>
			<Container className="folder">
				<Row className="margin-bottom-3">
					<Col xs={12}>
						<h2 className="name">Archived Files</h2>
					</Col>
				</Row>

				<DocumentList documents={archivedDocuments} />
			</Container>
		</DocumentTitle>
	);
}
