import { useNavigate, useParams } from 'react-router-dom';
import { ContactsApi } from '../../api/contacts';
import { handleError } from '../../components/helpers';
import AddOrEditContact from '../common/add-or-edit-contact';
import { useDispatch, useSelector } from 'react-redux';
import {
	addCompanyContact,
	selectCompanyContacts,
	updateCompanyContact,
} from '../../store/features/company.slice';

/**
 *
 * @param {AddOrEditCompanyContactProps} props
 */
export default function AddOrEditCompanyContact(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { contactId: contactIdPathParam } = useParams();
	const contactId = parseInt(contactIdPathParam);

	const isUpdatingContact = !!contactId;
	const contacts = useSelector(selectCompanyContacts);
	const contact = contacts?.find((_) => _.id === contactId);

	const addOrUpdateCompanyContact = async (values) => {
		// delete propertyId, just in case
		delete values['propertyId'];

		if (isUpdatingContact) {
			try {
				const updatedContact = await ContactsApi.updateContact(contactId, values);
				dispatch(updateCompanyContact(updatedContact));

				navigate(-1);
			} catch (e) {
				handleError(e);
			}
		} else {
			try {
				const newContact = await ContactsApi.createNewContact(values);
				dispatch(addCompanyContact(newContact));

				navigate(-1);
			} catch (e) {
				handleError(e);
			}
		}
	};

	return (
		<AddOrEditContact
			initialValues={contact}
			onSubmit={addOrUpdateCompanyContact}
		/>
	);
}
