import { selectSharedLogbooks } from '../../store/features/logbooks.slice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SharedLogbookFolder from '../common/shared-logbook-folder';

export default function LogbookFolder() {
	const { logbookId: logbookIdPathParam, folderId: folderIdPathParam } =
		useParams();
	const logbookId = parseInt(logbookIdPathParam);
	const folderId = parseInt(folderIdPathParam);

	const sharedLogbooks = useSelector(selectSharedLogbooks);
	const sharedLogbook = sharedLogbooks.find(
		(logbook) => logbook.id === logbookId
	);
	const sharedFolder = sharedLogbook?.folders.find(
		(folder) => folder.id === folderId
	);

	return (
		<SharedLogbookFolder
			folder={sharedFolder}
			logbookId={logbookId}
			label={sharedLogbook?.description}
		/>
	);
}
