import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPersonSymbol, handleError } from "../../components/helpers";
import AddOrEditContact from "../common/add-or-edit-contact";
import { useDispatch, useSelector } from "react-redux";
import { PropertyContactsApi } from "../../api/property-contacts";
import {
  addLogbookContact,
  selectCurrentLogbook,
  setCurrentLogbook,
  setLogbookContacts,
  updateLogbookContact,
} from "../../store/features/logbooks.slice";
import { useState } from "react";
import { FiftyFifty } from "../../components/fifty-fifty";
import DocumentTitle from "react-document-title";
import registerSideImage from "../../assets/images/register-side.png";
import styled from "styled-components";
import { Col, Container, Row } from "react-grid-system";
import Checkbox from "../../components/form/checkbox";
import { LogbooksApi } from "../../api/properties";
import { ContactsApi } from "../../api/contacts";
import { Form } from "react-final-form";
import { ClipLoader } from "react-spinners";
import TextField from "../../components/form/text-field";
import {
  emailValidator,
  requiredValidator,
} from "../../components/form/validators";
import {
  addCompanyContact,
  selectCompanyContacts,
} from "../../store/features/company.slice";
import searchIcon from "../../assets/images/search.svg";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: 100%;
  padding-top: 72px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const SearchBar = styled.input`
  height: 52px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dde0e3;
  padding: 16px;
  padding-left: 52px;
  font-size: 16px;
`;

const FormContent = styled(Container)`
  overflow: auto;
  flex-grow: 1;
  width: 100%;
`;

/**
 *
 * @param {AddOrEditCompanyContactProps} props
 */
export default function AddOrEditCompanyLogbookContact(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logbookId: logbookIdPathParam, contactId: contactIdPathParam } =
    useParams();
  const logbookId = parseInt(logbookIdPathParam);
  const contactId = parseInt(contactIdPathParam);

  const isUpdatingContact = !!contactId;
  const logbook = useSelector(selectCurrentLogbook);
  const contacts = logbook.contacts;
  const contact = contacts?.find((_) => _.id === contactId);
  const [wantsToAddANewContact, setWantsToAddANewContact] = useState(false);
  const { key, pathname } = useLocation();
  const [filterText, setFilterText] = useState("");
  const companyContacts = useSelector(selectCompanyContacts);

  const backPath = key !== "default" ? -1 : pathname.replace("/new", "");

  const addOrUpdateContact = async (values) => {
    if (isUpdatingContact) {
      try {
        const updatedContact = await PropertyContactsApi.updateLogbookContact(
          logbookId,
          contactId,
          values
        );
        dispatch(updateLogbookContact(updatedContact));

        navigate(-1);
      } catch (e) {
        handleError(e);
      }
    } else {
      try {
        const newContact = await PropertyContactsApi.createLogbookContact(
          logbookId,
          values
        );
        dispatch(addLogbookContact(newContact));

        navigate(-1);
      } catch (e) {
        handleError(e);
      }
    }
  };

  const updateFilterText = (e) => {
    const val = e.target.value;
    setFilterText(val);
  };

  if (isUpdatingContact) {
    return (
      <AddOrEditContact
        initialValues={contact || { propertyId: logbookId }}
        onSubmit={addOrUpdateContact}
      />
    );
  }

  // custom view for logbook
  return (
    <>
      <DocumentTitle title={"Add new contact | inndox"}>
        <FiftyFifty backgroundImage={registerSideImage}>
          <Content>
            <h1>Add a Contact to Logbook</h1>

            <p className="margin-top-1">
              Easily add contacts from your account or create a new one by
              entering the details.
            </p>

            {wantsToAddANewContact ? (
              <Row>
                <Col>
                  <button
                    className="button button-secondary full-width margin-top-4"
                    onClick={() => setWantsToAddANewContact(false)}
                  >
                    Choose from existing contacts
                  </button>

                  <hr
                    className="margin-top-3 margin-bottom-3"
                    style={{
                      border: "none",
                      height: 1,
                      backgroundColor: "#dde0e3",
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <button
                    className="button button-secondary full-width margin-top-4"
                    onClick={() => setWantsToAddANewContact(true)}
                  >
                    Add a new contact
                  </button>

                  <hr
                    className="margin-top-3 margin-bottom-3"
                    style={{
                      border: "none",
                      height: 1,
                      backgroundColor: "#dde0e3",
                    }}
                  />
                </Col>
              </Row>
            )}

            {wantsToAddANewContact ? (
              <Form
                initialValues={{ propertyId: logbookId }}
                onSubmit={async (values) => {
                  const updatedValues = { ...values };

                  // Update contact type
                  updatedValues.contactType =
                    values.isPropertyOwner === true ? "Owner" : "Other";

                  delete updatedValues.isSaveToCompanyAccount;
                  delete updatedValues.isPropertyOwner;
                  delete updatedValues.propertyId;

                  if (values.isSaveToCompanyAccount === true) {
                    const newCompanyContact =
                      await ContactsApi.createNewContact(updatedValues);
                    dispatch(addCompanyContact(newCompanyContact));

                    const updatedLogbookContacts =
                      await ContactsApi.bulkAddContactsToProperty(
                        values.propertyId,
                        [newCompanyContact.id]
                      );
                    dispatch(setLogbookContacts(updatedLogbookContacts));

                    navigate(backPath);
                  } else {
                    const newTemplateContact =
                      await PropertyContactsApi.createLogbookContact(
                        values.propertyId,
                        {
                          ...updatedValues,
                          propertyId: values.propertyId,
                        }
                      );

                    dispatch(addLogbookContact(newTemplateContact));

                    navigate(backPath);
                  }
                }}
                render={({ submitting, handleSubmit, values }) => (
                  <FormContent>
                    <Row>
                      <TextField type="hidden" name="id" />

                      <Col xs={12} md={6}>
                        {/* First name */}
                        <TextField
                          name="firstName"
                          label="First name"
                          className="margin-top-2"
                          required
                          validate={requiredValidator}
                        />
                      </Col>

                      <Col xs={12} md={6}>
                        <TextField
                          name="lastName"
                          label="Last name"
                          className="margin-top-2"
                          required
                          validate={requiredValidator}
                        />
                      </Col>

                      <Col xs={12}>
                        <Checkbox
                          name="isPropertyOwner"
                          label={"Add as logbook owner"}
                          className="margin-top-1"
                        />
                      </Col>

                      <Col xs={12}>
                        <TextField
                          name="email"
                          label="Email"
                          type="email"
                          className="margin-top-2"
                          required
                          validate={emailValidator}
                        />
                      </Col>

                      <Col xs={12}>
                        <TextField
                          name="phone"
                          label="Phone"
                          className="margin-top-2"
                        />
                      </Col>

                      {values.isPropertyOwner ? (
                        <noscript />
                      ) : (
                        <Col xs={12}>
                          <TextField
                            name="typeOther"
                            label="Type"
                            className="margin-top-2"
                          />
                        </Col>
                      )}

                      <Col xs={12}>
                        <TextField
                          name="address"
                          label="Address"
                          className="margin-top-2"
                        />
                      </Col>

                      {values.isPropertyOwner ? (
                        <noscript />
                      ) : (
                        <Col xs={12}>
                          <TextField
                            name="company"
                            label="Company"
                            className="margin-top-2"
                          />
                        </Col>
                      )}

                      <Col xs={12}>
                        <Checkbox
                          label="Save contact to my company account"
                          name="isSaveToCompanyAccount"
                          className="margin-top-1"
                        />
                      </Col>

                      <Col xs={12}>
                        <button
                          className="button button-primary button-large button-big margin-top-4 full-width"
                          onClick={submitting ? undefined : handleSubmit}
                        >
                          {submitting ? (
                            <ClipLoader loading size={16} color="#fff" />
                          ) : (
                            "Add contact"
                          )}
                        </button>

                        <button
                          className="button button-link margin-top-1 button-big button-large full-width"
                          onClick={() => navigate(backPath)}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </FormContent>
                )}
              />
            ) : (
              <Form
                initialValues={{ propertyId: logbookId }}
                onSubmit={async (values) => {
                  // add selected contacts
                  const selectedContactsObject = values?.contacts || {};
                  const selectedContactIds = Object.keys(selectedContactsObject)
                    .filter((key) => selectedContactsObject[key] === true)
                    .map((key) => key.replace("contact-", ""));

                  await ContactsApi.bulkAddContactsToProperty(
                    values.propertyId,
                    selectedContactIds
                  );
                  const updatedLogbook = await LogbooksApi.fetchLogbook(
                    values.propertyId
                  );
                  dispatch(setCurrentLogbook(updatedLogbook));

                  navigate(backPath);
                }}
                render={({ values, handleSubmit, submitting }) => (
                  <>
                    <Row>
                      <SearchBar
                        placeholder="Search for contacts"
                        onChange={updateFilterText}
                      />
                      <img
                        src={searchIcon}
                        style={{ marginTop: -52, marginLeft: 16 }}
                        alt="search"
                      />
                    </Row>

                    <FormContent style={{ textAlign: "left" }}>
                      <Row>
                        {(filterText?.length
                          ? companyContacts.filter(
                              (contact) =>
                                contact.firstName
                                  .toLowerCase()
                                  .includes(filterText.toLowerCase()) ||
                                contact.lastName
                                  .toLowerCase()
                                  .includes(filterText.toLowerCase()) ||
                                contact.email
                                  .toLowerCase()
                                  .includes(filterText.toLowerCase()) ||
                                contact.company
                                  ?.toLowerCase()
                                  .includes(filterText.toLowerCase()) ||
                                contact.typeOther
                                  ?.toLowerCase()
                                  .includes(filterText.toLowerCase())
                            )
                          : companyContacts
                        ).map((contact, index) => {
                          const initials = contact.company
                            ? contact.company.charAt(0) +
                              contact.company.charAt(1)
                            : (contact.firstName
                                ? contact.firstName.charAt(0)
                                : "") +
                              (contact.lastName
                                ? contact.lastName.charAt(0)
                                : "");
                          return (
                            <Col
                              xs={12}
                              key={contact.id}
                              className="padding-top-2"
                            >
                              <div className="flex">
                                {getPersonSymbol(initials, index)}

                                <div
                                  style={{ flexGrow: 1 }}
                                  className="margin-right-2"
                                >
                                  <p className="truncate">
                                    {contact.firstName + " " + contact.lastName}
                                  </p>
                                  {contact.company && (
                                    <p className="helper-text truncate">
                                      {contact.company}
                                    </p>
                                  )}
                                </div>

                                <Checkbox
                                  name={`contacts.contact-${contact.id}`}
                                />
                              </div>

                              <hr
                                className="margin-top-2"
                                style={{
                                  border: "none",
                                  height: 1,
                                  backgroundColor: "#dde0e3",
                                }}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </FormContent>

                    <Row>
                      <Col xs={12}>
                        <button
                          className="button button-primary button-large button-big full-width margin-top-3"
                          onClick={handleSubmit}
                        >
                          Add contact(s)
                        </button>
                        <button
                          className="button button-link button-large button-big full-width"
                          onClick={() => navigate(backPath)}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </>
                )}
              />
            )}
          </Content>
        </FiftyFifty>
      </DocumentTitle>

      <style
        dangerouslySetInnerHTML={{
          __html: `#persistent-template-footer { display: none; }`,
        }}
      />
    </>
  );
}
