import { Route, Routes } from 'react-router-dom';
import { PublicRoutes } from '.';
import SnoozeOrUnsnoozeMaintenanceItemFromEmail from '../pages/public/snooze-maintenance-item';
import PublicDefect from '../pages/public/defect';
import PublicFolder from '../pages/public/folder';

export default function PublicSiteMap() {
	return (
		<Routes>
			<Route
				path={PublicRoutes.snooze}
				element={<SnoozeOrUnsnoozeMaintenanceItemFromEmail />}
			/>
			<Route path={PublicRoutes.defects} element={<PublicDefect />} />
			<Route path={PublicRoutes.publicFolder} element={<PublicFolder />} />
		</Routes>
	);
}
