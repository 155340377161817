import { axiosInstance } from './base';

export const FoldersApi = {
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} folderId
	 * @returns {Promise<inndox.Folder>}
	 */
	fetchFolder: (propertyId, folderId) =>
		axiosInstance.get(`/properties/${propertyId}/folders/${folderId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} folderId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Folder>}
	 */
	updateFolder: (propertyId, folderId, data) =>
		axiosInstance.put(`/properties/${propertyId}/folders/${folderId}`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} folderId
	 * @returns {Promise<void>}
	 */
	removeFolder: (propertyId, folderId) =>
		axiosInstance.delete(`/properties/${propertyId}/folders/${folderId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string>} params
	 * @returns {Promise<inndox.Folder[]>}
	 */
	searchForFolders: (propertyId, params) =>
		axiosInstance.get(`/properties/${propertyId}/folders/search`, {
			params,
		}),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Folder>}
	 */
	createNewFolder: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/folders`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Folder>}
	 */
	shareFolderWithPermissions: (propertyId, data) =>
		axiosInstance.post(
			`/properties/${propertyId}/folders/sharePermissions`,
			data
		),
	/**
	 *
	 * @param {number} permissionId
	 * @param {boolean} allowUpload
	 * @returns {Promise<inndox.Folder>}
	 */
	updateFolderSharePermission: (permissionId, allowUpload) =>
		axiosInstance.put(`/folders/sharePermissions/${permissionId}`, {
			id: permissionId,
			allowUpload,
		}),
	/**
	 *
	 * @param {number} permissionId
	 * @returns {Promise<inndox.Folder>}
	 */
	removeFolderSharePermission: (permissionId) =>
		axiosInstance.delete(`/folders/sharePermissions/${permissionId}`, {
			data: {
				sharePermissionId: permissionId,
			},
		}),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} folderId
	 * @returns {Promise<string>}
	 */
	downloadSharePermissionForFolder: (propertyId, folderId) =>
		axiosInstance.get(
			`/properties/${propertyId}/folders/${folderId}/sharePermissions/csv`
		),
	/**
	 *
	 * @returns {Promise<inndox.SharedFolders>}
	 */
	fetchSharedFoldersForCurrentUser: () =>
		axiosInstance.get('/folders/sharedwithme'),
	/**
	 *
	 * @param {string} accessCode
	 * @returns {Promise<inndox.SharedFolderWithAccessCode>}
	 */
	fetchSharedFolderViaAccessCode: (accessCode) =>
		axiosInstance.get(`/folders/sharedwithme/${accessCode}`),
	/**
	 *
	 * @param {string} accessCode
	 * @returns {Promise<void>}
	 */
	acceptInvitationToSharedFolder: (accessCode) =>
		axiosInstance.post(`/folders/sharedwithme/${accessCode}`),
};
