import DocumentTitle from 'react-document-title';
import { selectCurrentLogbook } from '../../store/features/logbooks.slice';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes } from '../../routes';
import MaintenanceItemList from '../../components/maintenance-item-list';

export default function CompanyLogbookMaintenanceItems() {
	const logbook = useSelector(selectCurrentLogbook);
	const navigate = useNavigate();

	return (
		<DocumentTitle title="Logbook Maintenance | inndox">
			<Container className="folder">
				<Row className="margin-bottom-3">
					<Col xs={12} md={6} className="flex margin-bottom-2">
						<h2 className="name">Logbook Maintenance Items</h2>
					</Col>

					<Col xs={12} md={6} className="flex end margin-bottom-2">
						<button
							className="button button-secondary margin-right-2"
							onClick={() =>
								navigate(
									'/' +
										CompanyRoutes.basePath.replace('/*', '') +
										'/' +
										CompanyRoutes.logbook.replace(':logbookId', logbook.id) +
										'/' +
										CompanyRoutes.addLogbookMaintenanceItem
								)
							}>
							Add maintenance
						</button>
					</Col>
				</Row>

				<MaintenanceItemList
					contacts={logbook.contacts}
					items={logbook.maintenanceItems}
					logbookIsTransferred={!!logbook.transfer?.acceptedOn}
				/>
			</Container>
		</DocumentTitle>
	);
}
