import { styled } from "styled-components";
import {
  removeCompanyUser,
  selectCompanyUsers,
  setCompanyUsers,
} from "../../../store/features/company.slice";
import shareIcon from "../../../assets/images/share.svg";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import { useAuth } from "../../../components/authentication";
import { useState } from "react";
import Modal from "../../../components/modal";
import { CompanyUsersApi } from "../../../api/company-users";
import { Form } from "react-final-form";
import { handleError } from "../../../components/helpers";
import TextField from "../../../components/form/text-field";
import { requiredValidator } from "../../../components/form/validators";
import { ClipLoader } from "react-spinners";
import userAddIcon from "../../../assets/images/user-add-white.svg";

const Header = styled.p`
  font-size: 20px;
  font-weight: bold;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    margin-top: 16px;
  }
`;

const User = styled(Row)`
  height: 58px;
  border-bottom: 1px solid #dde0e3;
`;

const UserItem = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Name = styled.p`
  font-size: 16px;
  line-height: 22px;
`;

const Email = styled.a`
  font-size: 16px;
  line-height: 22px;
  color: #adadb0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const OwnerLabel = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #adadb0;
  width: 100%;
`;

const StyledButton = styled.button`
  border-radius: 5px;

  @media only screen and (max-width: 576px) {
    margin-top: 8px;
    width: 100%;
  }
`;

export default function AccessSettings() {
  const users = useSelector(selectCompanyUsers);
  const { session } = useAuth();
  const dispatch = useDispatch();
  const [wantsToAddACompanyUser, setWantsToAddACompanyUser] = useState(false);

  return (
    <>
      <Row className="margin-bottom-2">
        <Col xs={12} md={4} className="flex">
          <Header>Access settings</Header>
        </Col>
        <Col xs={12} md={8} className="flex end">
          <StyledButton
            className="button button-primary"
            style={{ borderRadius: 5 }}
            onClick={() => setWantsToAddACompanyUser(true)}
          >
            <img src={userAddIcon} alt="add user" style={{ marginRight: 8 }} />
            Invite new member
          </StyledButton>
        </Col>
      </Row>

      {users.map((user) => (
        <CompanyUser key={user.id} user={user} session={session} />
      ))}

      <Row className="margin-top-2">
        <Col></Col>
      </Row>

      <Modal
        isOpen={wantsToAddACompanyUser}
        title={
          <>
            <img src={shareIcon} alt="share" />
            Invite others to your company
          </>
        }
        onClose={() => setWantsToAddACompanyUser(false)}
      >
        <p className="margin-top-1">
          Give a team member access to your company. Team member will have
          control to share, download and edit any items.
        </p>

        <Form
          onSubmit={async (values, form) => {
            try {
              // add new user
              await CompanyUsersApi.inviteNewUserToCompany(values);

              // fetch company users
              const updatedCompanyUsers =
                await CompanyUsersApi.fetchCompanyUsers();
              dispatch(setCompanyUsers(updatedCompanyUsers));

              // reset form
              form.reset();
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <TextField
                name="firstName"
                label="First name"
                required
                validate={requiredValidator}
                className="margin-top-2"
              />

              <TextField
                name="lastName"
                label="Last name"
                required
                validate={requiredValidator}
                className="margin-top-2"
              />

              <TextField
                name="email"
                label="Email address"
                required
                validate={requiredValidator}
                className="margin-top-2"
              />

              <button
                className="button button-primary button-large button-big full-width margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Send invitation"
                )}
              </button>
            </>
          )}
        />
      </Modal>
    </>
  );
}

function CompanyUser({ user, session }) {
  const dispatch = useDispatch();
  const [isRemovingACompanyUser, setIsRemovingACompanyUser] = useState(false);

  const removeUser = async () => {
    setIsRemovingACompanyUser(true);

    try {
      await CompanyUsersApi.removeCompanyUser(user.id);
      dispatch(removeCompanyUser(user.id));
    } catch (e) {
      handleError(e);
    } finally {
      setIsRemovingACompanyUser(false);
    }
  };

  return (
    <User>
      <UserItem xs={8}>
        <Name>{`${user.firstName} ${user.lastName}`}</Name>
        <Email href={`mailto:${user.email}`}>{user.email}</Email>
      </UserItem>

      <UserItem xs={4} style={{ textAlign: "right", alignItems: "flex-end" }}>
        {user.id === session?.userId ? (
          <OwnerLabel>Owner</OwnerLabel>
        ) : isRemovingACompanyUser ? (
          <ClipLoader size={16} color="#FF3E3E" />
        ) : (
          <button
            className="button button-remove"
            style={{ paddingRight: 0 }}
            onClick={removeUser}
          >
            Remove
          </button>
        )}
      </UserItem>
    </User>
  );
}
