import noFiles from '../assets/images/no-files.svg';
import { Col, Row } from 'react-grid-system';
import FolderList from '../pages/common/folder-list';

/**
 *
 * @typedef {Object} SharedLogbookRowItemProps
 * @property {inndox.SharedFolderLogbook} logbook
 */

/**
 *
 * @param {SharedLogbookRowItemProps} props
 */
function SharedLogbookRowItem({ logbook }) {
	return (
		<div className="margin-bottom-4">
			<Row style={{ marginBottom: -16 }}>
				<Col xs={12}>
					<label>{logbook.description}</label>
				</Col>
			</Row>

			<FolderList folders={logbook.folders} logbookId={logbook.id} />
		</div>
	);
}

/**
 *
 * @typedef {Object} SharedLogbooksProps
 * @property {inndox.SharedFolderLogbook[]} logbooks
 */

/**
 *
 * @param {SharedLogbooksProps} props
 */
export default function SharedLogbooks({ logbooks }) {
	if (!logbooks?.length) {
		return (
			<Row>
				<Col xs={12} className="document-empty-state">
					<img width="287" height="210" src={noFiles} alt="no-files-img" />
					<div className="flex col">
						<h1>No logbooks shared</h1>
						<p className="margin-top-2">You have no folders shared with you yet</p>
					</div>
				</Col>
			</Row>
		);
	}

	return logbooks.map((logbook) => (
		<SharedLogbookRowItem logbook={logbook} key={logbook.id} />
	));
}
