import DocumentTitle from "react-document-title";
import { FiftyFifty } from "../../components/fifty-fifty";
import logbookSideImage from "../../assets/images/bg2.jpg";
import styled from "styled-components";
import BackButton from "../../components/back-button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CompanyRoutes, OwnerRoutes } from "../../routes";
import { Form } from "react-final-form";
import { ClipLoader } from "react-spinners";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import Checkbox from "../../components/form/checkbox";
import { handleError } from "../../components/helpers";
import { FoldersApi } from "../../api/folders";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentLogbook,
  updateLogbookFolder,
} from "../../store/features/logbooks.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function EditFolder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, key } = useLocation();
  const { logbookId, folderId: folderIdPathParam } = useParams();
  const folderId = parseInt(folderIdPathParam);
  const logbook = useSelector(selectCurrentLogbook);
  const folder = logbook?.folders.find((folder) => folder.id === folderId);
  const backPath =
    key !== "default"
      ? -1
      : pathname.includes("/" + CompanyRoutes.basePath.replace("/*", ""))
      ? pathname.replace("/" + CompanyRoutes.addFolder, "")
      : pathname.replace("/" + OwnerRoutes.addFolder, "");

  return (
    <DocumentTitle title="Add Folder | inndox">
      <FiftyFifty backgroundImage={logbookSideImage} stretch expanded>
        <Content className="margin-top-1">
          <BackButton backPath={backPath} />
          <h1>Edit Folder</h1>
          <p className="margin-top-1 margin-bottom-4">Update folder details.</p>

          <Form
            initialValues={{
              id: folderId,
              propertyId: logbookId,
              name: folder.name,
              description: folder.description,
              isPrivate: folder.isPrivate,
            }}
            onSubmit={async (values) => {
              try {
                const newFolder = await FoldersApi.updateFolder(
                  logbookId,
                  folderId,
                  values
                );
                dispatch(updateLogbookFolder(newFolder));

                navigate(backPath);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => {
              return (
                <>
                  <TextField
                    name="name"
                    label="Name"
                    required
                    validate={requiredValidator}
                    className="margin-top-2"
                  />

                  <TextField
                    name="description"
                    label="Description"
                    className="margin-top-2"
                  />

                  <Checkbox
                    label="Is this a private folder?"
                    name="isPrivate"
                    className="margin-top-1"
                  />

                  <button
                    className="button button-primary button-large button-big full-width margin-top-4"
                    onClick={props.submitting ? undefined : props.handleSubmit}
                  >
                    {props.submitting ? (
                      <ClipLoader loading size={16} color="#fff" />
                    ) : (
                      "Update folder"
                    )}
                  </button>
                </>
              );
            }}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
