import { useParams } from 'react-router-dom';
import AddOrEditMaintenanceItem from '../common/add-or-edit-maintenance-item';
import { useSelector } from 'react-redux';
import { selectCurrentTemplate } from '../../store/features/templates.slice';

export default function AddOrEditTemplateMaintenanceItem() {
	const { maintenanceId: maintenanceIdPathParam } = useParams();
	const maintenanceId = parseInt(maintenanceIdPathParam);
	const logbook = useSelector(selectCurrentTemplate);
	const items = logbook?.maintenanceItems;
	const item = maintenanceId
		? items?.find((item) => item.id === maintenanceId)
		: undefined;

	return (
		<AddOrEditMaintenanceItem
			logbookId={logbook.id}
			maintenanceItem={item}
			contacts={logbook.contacts}
		/>
	);
}
