import LogbookDetails from '../common/logbook-details';
import { useSelector } from 'react-redux';
import { selectCurrentLogbook } from '../../store/features/logbooks.slice';
import { ClipLoader } from 'react-spinners';
import { Col, Container, Row } from 'react-grid-system';

export default function OwnerLogbook() {
	const logbook = useSelector(selectCurrentLogbook);

	if (!logbook) {
		return (
			<Container>
				<Row>
					<Col xs={12} className="flex center">
						<ClipLoader loading size={16} color="#bdd23f" />
					</Col>
				</Row>
			</Container>
		);
	}

	return <LogbookDetails logbook={logbook} isCurrentUserACompany={false} />;
}
