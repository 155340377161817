import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogbookList from "../../components/logbook-list";
import OwnerHomeToggle from "../../components/owner-home-toggle";
import { SharedRoutes } from "../../routes";
import {
  selectLogbooks,
  selectSharedLogbooks,
} from "../../store/features/logbooks.slice";

const StyledContainer = styled(Container)`
  font-family: Lato;
  font-size: 15px;
  line-height: 27px;
  color: #2d3540;

  .warningTitle {
    font-weight: bold;
    display: inline-block;
  }

  .warningText {
    font-weight: normal;
    display: block;
  }
`;

export default function OwnerHome() {
  const logbooks = useSelector(selectLogbooks);
  const sharedLogbooks = useSelector(selectSharedLogbooks);
  const navigate = useNavigate();

  return (
    <StyledContainer>
      {/* Header */}
      <Row>
        {/* Radios for switching */}
        <Col xs={12} sm={6} className="margin-top-2 margin-bottom-1 flex">
          {sharedLogbooks?.length > 0 ? (
            <OwnerHomeToggle
              currentOption={1}
              onOptionClick={(selected) => {
                if (selected === 1) {
                  // do nothing
                  // as we are already on owner home
                } else {
                  // navigate away
                  navigate(
                    "/" +
                      SharedRoutes.basePath.replace("/*", "") +
                      "/" +
                      SharedRoutes.home
                  );
                }
              }}
            />
          ) : null}
        </Col>

        {/* New logbook action */}
        <Col xs={12} sm={6} className="flex end"></Col>
      </Row>

      <LogbookList logbooks={logbooks} />

      {/* Articles */}
      {/* {!logbooksPendingAcceptance?.length ? (
        <Articles max={3} tags={["owners"]} />
      ) : (
        <noscript />
      )} */}
    </StyledContainer>
  );
}
