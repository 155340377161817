import DocumentTitle from "react-document-title";
import { Container, Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import moment from "moment";
import DocumentList from "../common/document-list";
import SetupBlock from "../../components/setup-block";
import { useState } from "react";
import AddOrEditWarrantyModal from "../common/add-or-edit-warranty-modal";

export default function CompanyLogbookWarranties() {
  const logbook = useSelector(selectCurrentLogbook);
  const [wantsToAddOrEditWarranty, setWantsToAddOrEditWarranty] =
    useState(false);
  const documents = logbook.documents;
  const now = moment();
  const documentsWithWarranty = documents
    .filter(
      (document) =>
        document.warrantyExpiresOn &&
        moment(document.warrantyExpiresOn).unix() > now.unix()
    )
    .sort(
      (a, b) =>
        moment(a.warrantyExpiresOn).unix() - moment().unix(b.warrantyExpiresOn)
    );

  if (!documentsWithWarranty?.length) {
    return (
      <DocumentTitle title="Logbook Warranties | inndox">
        <Container className="folder">
          <Row className="margin-bottom-3">
            <Col xs={12} md={6} className="flex margin-bottom-2">
              <h2 className="name">Logbook Warranty Reminders</h2>
            </Col>

            <Col xs={12} md={6} className="flex end margin-bottom-2">
              <button
                className="button button-secondary margin-right-2"
                onClick={() =>
                  // navigate(
                  //   "/" +
                  //     CompanyRoutes.basePath.replace("/*", "") +
                  //     "/" +
                  //     CompanyRoutes.logbook.replace(":logbookId", logbook.id) +
                  //     "/" +
                  //     CompanyRoutes.newWarranty
                  // )
                  setWantsToAddOrEditWarranty(true)
                }
              >
                New warranty
              </button>
            </Col>
          </Row>

          <SetupBlock description="There are no warranties setup for this logbook yet." />

          <AddOrEditWarrantyModal
            isOpen={wantsToAddOrEditWarranty}
            onClose={() => setWantsToAddOrEditWarranty(false)}
          />
        </Container>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="Logbook Warranties | inndox">
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={12} md={6} className="flex margin-bottom-2">
            <h2 className="name">Logbook Warranty Reminders</h2>
          </Col>

          <Col xs={12} md={6} className="flex end margin-bottom-2">
            <button
              className="button button-secondary margin-right-2"
              onClick={() =>
                // navigate(
                // 	'/' +
                // 		CompanyRoutes.basePath.replace('/*', '') +
                // 		'/' +
                // 		CompanyRoutes.logbook.replace(':logbookId', logbook.id) +
                // 		'/' +
                // 		CompanyRoutes.newWarranty
                // )
                setWantsToAddOrEditWarranty(true)
              }
            >
              New warranty
            </button>
          </Col>
        </Row>

        <DocumentList documents={documentsWithWarranty} />

        <AddOrEditWarrantyModal
          isOpen={wantsToAddOrEditWarranty}
          onClose={() => setWantsToAddOrEditWarranty(false)}
        />
      </Container>
    </DocumentTitle>
  );
}
