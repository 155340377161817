import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	list: null,
	public: null,
	shared: null,
	logbookContentSearchResults: null,
	logbookSearchResults: null,
	current: null,
	currentLogbookDefects: null,
};

export const logbooksSlice = createSlice({
	name: 'logbooks',
	initialState,
	reducers: {
		setLogbooks: (state, action) => {
			state.list = action.payload;
		},
		updateLogbookInList: (state, action) => {
			state.list = state.list.map((item) => {
				if (item.id === action.payload.id) {
					return action.payload;
				}

				return item;
			});
			state.current = {
				...state.current,
				...action.payload,
			};
		},
		addLogbook: (state, action) => {
			if (state.list) {
				state.list.push(action.payload);
			} else {
				state.list = [action.payload];
			}
		},
		removeLogbook: (state, action) => {
			state.list = state.list.filter((logbook) => logbook.id !== action.payload);
		},
		setCurrentLogbook: (state, action) => {
			state.current = action.payload;
		},
		updateCurrentLogbook: (state, action) => {
			state.current = {
				...state.current,
				...action.payload,
			};
		},
		addFolderToLogbook: (state, action) => {
			state.current.folders.push(action.payload);
		},
		updateLogbookFolder: (state, action) => {
			state.current = {
				...state.current,
				folders: state.current.folders.map((folder) => {
					if (folder.id === action.payload.id) {
						return {
							...folder,
							...action.payload,
						};
					}

					return folder;
				}),
			};
		},
		removeLogbookFolder: (state, action) => {
			state.current.folders = state.current.folders.filter(
				(folder) => folder.id !== action.payload
			);
		},
		incrementLogbookFolderStatistics: (state, action) => {
			const folderId = parseInt(action.payload.id);
			state.current = {
				...state.current,
				folders: state.current.folders.map((folder) => {
					if (folder.id === folderId) {
						return {
							...folder,
							documentStatistics: {
								count: (folder.documentStatistics?.count || 0) + 1,
								newCount: (folder.documentStatistics?.newCount || 0) + 1,
							},
						};
					}

					return folder;
				}),
			};
		},
		decrementLogbookFolderStatistics: (state, action) => {
			const folderId = parseInt(action.payload.id);
			state.current = {
				...state.current,
				folders: state.current.folders.map((folder) => {
					if (folder.id === folderId) {
						return {
							...folder,
							documentStatistics: {
								...folder.documentStatistics,
								count: folder.documentStatistics?.count
									? folder.documentStatistics.count - 1
									: 0,
							},
						};
					}

					return folder;
				}),
			};
		},
		addDocumentToLogbook: (state, action) => {
			state.current.documents.push(action.payload);
		},
		updateLogbookDocument: (state, action) => {
			state.current = {
				...state.current,
				documents: state.current.documents.map((document) => {
					if (document.id === action.payload.id) {
						return {
							...document,
							...action.payload,
						};
					}

					return document;
				}),
			};
		},
		removeLogbookDocument: (state, action) => {
			state.current = {
				...state.current,
				documents: state.current.documents.filter(
					(document) => document.id !== action.payload
				),
			};
		},
		addLogbookMaintenanceItem: (state, action) => {
			state.current.maintenanceItems.push(action.payload);
		},
		updateLogbookMaintenanceItem: (state, action) => {
			state.current = {
				...state.current,
				maintenanceItems: state.current.maintenanceItems.map((maintenanceItem) => {
					if (maintenanceItem.id === action.payload.id) {
						return {
							...maintenanceItem,
							...action.payload,
						};
					}

					return maintenanceItem;
				}),
			};
		},
		removeLogbookMaintenanceItem: (state, action) => {
			state.current = {
				...state.current,
				maintenanceItems: state.current.maintenanceItems.filter(
					(maintenanceItem) => maintenanceItem.id !== action.payload
				),
			};
		},
		setLogbookContacts: (state, action) => {
			state.current.contacts = action.payload;
		},
		addLogbookContact: (state, action) => {
			state.current.contacts.push(action.payload);
		},
		updateLogbookContact: (state, action) => {
			state.current.contacts = state.current.contacts.map((contact) => {
				if (contact.id === action.payload.id) {
					return {
						...contact,
						...action.payload,
					};
				}

				return contact;
			});
		},
		removeLogbookContact: (state, action) => {
			state.current.contacts = state.current.contacts.filter(
				(contact) => contact.id !== action.payload
			);
		},
		setCurrentLogbookDefects: (state, action) => {
			state.currentLogbookDefects = action.payload;
		},
		addDefectToCurrentLogbook: (state, action) => {
			state.currentLogbookDefects.push(action.payload);
		},
		updateCurrentLogbookDefect: (state, action) => {
			state.currentLogbookDefects = state.currentLogbookDefects.map((defect) => {
				if (defect.id === action.payload.id) {
					return {
						...defect,
						...action.payload,
					};
				}

				return defect;
			});
		},
		removeDefectFromCurrentLogbook: (state, action) => {
			state.currentLogbookDefects = state.currentLogbookDefects.filter(
				(defect) => defect.id !== action.payload
			);
		},
		removeLogbookPermission: (state, action) => {
			state.current.permissions = state.current.permissions.filter(
				(permission) => permission.id !== action.payload
			);
		},
		setPublicViewLogbook: (state, action) => {
			state.public = action.payload;
		},
		setSharedLogbooks: (state, action) => {
			state.shared = action.payload;
		},
		addDocumentToSharedLogbook: (state, action) => {
			const properties = state.shared.properties.map((property) => {
				if (property.id === action.payload.propertyId) {
					return {
						...property,
						folders: property.folders.map((folder) => {
							if (folder.id === action.payload.folderId) {
								folder.documents.push(action.payload);
							}

							return folder;
						}),
					};
				}

				return property;
			});

			state.shared.properties = properties;
		},
		removeDocumentFromSharedLogbook: (state, action) => {
			const { logbookId, folderId, documentId } = action.payload;

			const properties = state.shared.properties.map((property) => {
				if (property.id === logbookId) {
					return {
						...property,
						folders: property.folders.map((folder) => {
							if (folder.id === folderId) {
								return {
									...folder,
									documents: folder.documents.filter(
										(document) => document.id !== documentId
									),
								};
							}

							return folder;
						}),
					};
				}

				return property;
			});

			state.shared.properties = properties;
		},
		incrementSharedLogbookFolderStatistics: (state, action) => {
			const propertyId = parseInt(action.payload.propertyId);
			const folderId = parseInt(action.payload.folderId);
			const properties = state.shared.properties.map((property) => {
				if (property.id === propertyId) {
					return {
						...property,
						folders: property.folders.map((folder) => {
							if (folder.id === folderId) {
								return {
									...folder,
									documentStatistics: {
										count: (folder.documentStatistics?.count || 0) + 1,
										newCount: (folder.documentStatistics?.newCount || 0) + 1,
									},
								};
							}

							return folder;
						}),
					};
				}

				return property;
			});

			state.shared.properties = properties;
		},
		decrementSharedLogbookFolderStatistics: (state, action) => {
			const propertyId = parseInt(action.payload.propertyId);
			const folderId = parseInt(action.payload.folderId);
			const properties = state.shared.properties.map((property) => {
				if (property.id === propertyId) {
					return {
						...property,
						folders: property.folders.map((folder) => {
							if (folder.id === folderId) {
								return {
									...folder,
									documentStatistics: {
										...folder.documentStatistics,
										count: folder.documentStatistics?.count
											? folder.documentStatistics.count - 1
											: 0,
									},
								};
							}

							return folder;
						}),
					};
				}

				return property;
			});

			state.shared.properties = properties;
		},
		updateDocumentToSharedFolder: (state, action) => {
			state.shared = {
				properties: state.shared.properties.map((logbook) => {
					if (logbook.id === action.payload.propertyId) {
						return {
							...logbook,
							folders: logbook.folders.map((folder) => {
								if (folder.id === action.payload.folderId) {
									return {
										...folder,
										documents: [...folder.documents, action.payload],
									};
								}

								return folder;
							}),
						};
					}

					return logbook;
				}),
			};
		},
		setSearchResults: (state, action) => {
			state.logbookContentSearchResults = action.payload;
		},
		setLogbookSearchResults: (state, action) => {
			state.logbookSearchResults = action.payload;
		},
	},
});

// Actions
export const {
	updateLogbookInList,
	setLogbooks,
	setPublicViewLogbook,
	setSharedLogbooks,
	addLogbook,
	removeLogbook,
	setSearchResults,
	updateLogbookFolder,
	setCurrentLogbook,
	addDocumentToLogbook,
	updateLogbookDocument,
	removeLogbookDocument,
	incrementLogbookFolderStatistics,
	removeLogbookMaintenanceItem,
	addLogbookMaintenanceItem,
	updateLogbookMaintenanceItem,
	updateDocumentToSharedFolder,
	setLogbookSearchResults,
	setLogbookContacts,
	addLogbookContact,
	updateLogbookContact,
	removeLogbookContact,
	setCurrentLogbookDefects,
	updateCurrentLogbookDefect,
	addDefectToCurrentLogbook,
	updateCurrentLogbook,
	addFolderToLogbook,
	addDocumentToSharedLogbook,
	removeDocumentFromSharedLogbook,
	removeLogbookFolder,
	removeLogbookPermission,
	incrementSharedLogbookFolderStatistics,
	decrementLogbookFolderStatistics,
	decrementSharedLogbookFolderStatistics,
	removeDefectFromCurrentLogbook,
} = logbooksSlice.actions;

/**
 *
 * @param {*} state
 * @returns {inndox.Logbook[]}
 */
export const selectLogbooks = (state) => state.logbooks.list;

/**
 *
 * @param {*} state
 * @returns {inndox.Logbook}
 */
export const selectCurrentLogbook = (state) => state.logbooks.current;

/**
 *
 * @param {*} state
 * @returns {inndox.SharedFolderWithAccessCode}
 */
export const selectPublicLogbook = (state) => state.logbooks.public;

/**
 *
 * @param {*} state
 * @returns {inndox.SharedFolderLogbook[]}
 */
export const selectSharedLogbooks = (state) =>
	state.logbooks.shared?.properties;

/**
 *
 * @param {*} state
 * @returns {inndox.LogbookContentSearchResults}
 */
export const selectLogbookContentSearchResults = (state) =>
	state.logbooks.logbookContentSearchResults;

/**
 *
 * @param {*} state
 * @returns {inndox.Logbook[]}
 */
export const selectLogbookSearchResults = (state) =>
	state.logbooks.logbookSearchResults;

/**
 *
 * @param {*} state
 * @returns {inndox.LogbookDefect[]}
 */
export const selectCurrentLogbookDefects = (state) =>
	state.logbooks.currentLogbookDefects;

export default logbooksSlice.reducer;

export { initialState as logbooksSliceInitialState };
