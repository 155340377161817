import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { UsersApi } from "../../api/users";
import accountSideImage from "../../assets/images/account-side.jpg";
import { FiftyFifty } from "../../components/fifty-fifty";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import { AccountRoutes } from "../../routes";
import { styled } from "styled-components";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function ForgotPassword() {
  const [hasInitiatedPasswordReset, setHasInitiatedPasswordReset] =
    useState(false);
  const navigate = useNavigate();

  if (hasInitiatedPasswordReset) {
    return (
      <DocumentTitle title="Forgot Password | inndox">
        <FiftyFifty backgroundImage={accountSideImage}>
          <Content>
            <h1>Check email</h1>
            <p className="margin-top-1">
              Check your email for a password reset link we have sent. It might
              take a few minutes to arrive.
            </p>

            <button
              className="button button-primary button-big full-width margin-top-4 button-large flex center"
              onClick={() =>
                navigate(
                  "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.login
                )
              }
            >
              Back to login
            </button>
          </Content>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="Forgot Password | inndox">
      <FiftyFifty backgroundImage={accountSideImage}>
        <Content>
          <h1>Forgot your password?</h1>
          <p className="margin-top-1">
            That’s ok, enter your email address and we will send you a link to
            reset your password.
          </p>

          <Form
            onSubmit={async (values) => {
              try {
                await UsersApi.initiatePasswordReset({
                  ...values,
                  type: "password",
                });

                setHasInitiatedPasswordReset(true);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <>
                <TextField
                  name="email"
                  label="Email address"
                  placeholder="john@example.com"
                  className="margin-top-4"
                  required
                  validate={requiredValidator}
                />

                <button
                  className="button button-primary button-big full-width margin-top-4 button-large flex center"
                  onClick={props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  className="button button-secondary-link button-big full-width button-large flex center"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>

                <div className="margin-top-4 button-large flex center are-you-a-builder">
                  Don't have an account?
                  <a
                    href={
                      "/" +
                      AccountRoutes.basePath.replace("/*", "") +
                      "/" +
                      AccountRoutes.register
                    }
                    className="link-style-elem"
                  >
                    &nbsp;Register here
                  </a>
                  .
                </div>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
