import { useLocation, useNavigate } from 'react-router-dom';
import AddPaymentDetails from '../../components/add-payment-details';
import { CompanyRoutes } from '../../routes';
import QueryString from 'query-string';

export default function Subscribe() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const { logbookId } = QueryString.parse(search);

	return (
		<AddPaymentDetails
			onFinish={() => {
				navigate(
					'/' +
						CompanyRoutes.basePath.replace('/*', '') +
						'/' +
						CompanyRoutes.logbook.replace(':logbookId', logbookId) +
						'/' +
						CompanyRoutes.logbookTransfer
				);
			}}
		/>
	);
}
