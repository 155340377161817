import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import logbooksReducer, {
	logbooksSliceInitialState,
} from './features/logbooks.slice';
import subscriptionReducer, {
	subscriptionSliceInitialState,
} from './features/subscription.slice';
import userReducer, { userSliceInitialState } from './features/user.slice';
import templatesReducer, {
	templatesSliceInitialState,
} from './features/templates.slice';
import companyReducer, {
	companySliceInitialState,
} from './features/company.slice';
import articlesReducer, {
	articlesSliceInitialState,
} from './features/articles.slice';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	whitelist: ['user'],
};

const rootReducer = combineReducers({
	user: userReducer,
	logbooks: logbooksReducer,
	subscription: subscriptionReducer,
	templates: templatesReducer,
	company: companyReducer,
	articles: articlesReducer,
});

const ROOT_INITIAL_STATE = {
	user: userSliceInitialState,
	logbooks: logbooksSliceInitialState,
	subscription: subscriptionSliceInitialState,
	templates: templatesSliceInitialState,
	company: companySliceInitialState,
	articles: articlesSliceInitialState,
};

const persistedReducer = persistReducer(persistConfig, (state, action) => {
	if (action.type === 'CLEAR_OUT') {
		return ROOT_INITIAL_STATE;
	}

	return rootReducer(state, action);
});

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);
