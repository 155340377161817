import DocumentTitle from 'react-document-title';
import { FiftyFifty } from '../../../components/fifty-fifty';
import registerImage from '../../../assets/images/register-side.png';
import chevronIcon from '../../../assets/images/chevron-right.svg';
import styled from 'styled-components';
import logbookTypeRealEstate from '../../../assets/images/logbook-type-real-estate.svg';
import logbookTypeVehicle from '../../../assets/images/logbook-type-vehicle.svg';
import logbookTypeMarine from '../../../assets/images/logbook-type-marine.svg';
import logbookTypeCaravan from '../../../assets/images/logbook-type-caravan.svg';
import logbookTypeOther from '../../../assets/images/logbook-type-other.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompanyRoutes, OwnerRoutes } from '../../../routes';
import { isCurrentUserACompany } from '../../../store/features/user.slice';
import { useSelector } from 'react-redux';

const generateLogbookTypeOptions = (isCompanyUser) => {
	const routes = isCompanyUser ? CompanyRoutes : OwnerRoutes;
	return [
		{
			next:
				'/' +
				routes.basePath.replace('/*', '') +
				'/' +
				routes.newLogbookDetails +
				'?type=property',
			title: 'Real estate',
			icon: logbookTypeRealEstate,
		},
		{
			next:
				'/' +
				routes.basePath.replace('/*', '') +
				'/' +
				routes.newLogbookDetails +
				'?type=vehicle',
			title: 'Vehicle',
			icon: logbookTypeVehicle,
		},
		{
			next:
				'/' +
				routes.basePath.replace('/*', '') +
				'/' +
				routes.newLogbookDetails +
				'?type=caravan',
			title: 'Caravan',
			icon: logbookTypeCaravan,
		},
		{
			next:
				'/' +
				routes.basePath.replace('/*', '') +
				'/' +
				routes.newLogbookDetails +
				'?type=marine',
			title: 'Marine',
			icon: logbookTypeMarine,
		},
		{
			next:
				'/' +
				routes.basePath.replace('/*', '') +
				'/' +
				routes.newLogbookDetails +
				'?type=other',
			title: 'Other',
			icon: logbookTypeOther,
		},
	];
};

const OWNER_LOGBOOK_TYPE_OPTIONS = generateLogbookTypeOptions(false);

const COMPANY_LOGBOOK_TYPE_OPTIONS = generateLogbookTypeOptions(true);

const LogbookTypeOptionContainer = styled.div`
	display: flex;
	align-items: center;
	height: 82px;
	width: 100%;
	max-width: 450px;
	padding: 20px 24px;
	background: #ffffff;
	border: 1px solid #d3d7e3;
	border-radius: 10px;
	margin-top: 10px;
	cursor: pointer;

	@media only screen and (max-width: 576px) {
		max-width: 100%;
	}

	&:hover {
		background-color: #f9f9f9;
	}
`;

const LogbookTypeOptionIcon = styled.div`
	width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}
`;

const LogbookTypeOptionTitle = styled.p`
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: #2d3540;
	margin-left: 32px;
`;

const Chevron = styled.div`
	width: 20px;
	height: 20px;
	margin-left: auto;
	margin-right: 0;
`;

export default function NewLogbookType() {
	const navigate = useNavigate();
	const { pathname, key } = useLocation();
	const isCompanyUser = useSelector(isCurrentUserACompany);
	const logbookTypeOptions = isCompanyUser
		? COMPANY_LOGBOOK_TYPE_OPTIONS
		: OWNER_LOGBOOK_TYPE_OPTIONS;
	const backPath =
		key !== 'default'
			? -1
			: pathname.startsWith('/company')
			? '/' + CompanyRoutes.home
			: '/' + OwnerRoutes.home;

	return (
		<DocumentTitle title="New Logbook Type | inndox">
			<FiftyFifty backgroundImage={registerImage}>
				<h1>Select your logbook type</h1>

				<p
					className="margin-top-1 margin-bottom-2"
					style={{
						maxWidth: 326,
						marginLeft: 'auto',
						marginRight: 'auto',
						marginBottom: 20,
					}}>
					Please select the most relevant option below.
				</p>

				{logbookTypeOptions.map((option, index) => (
					<LogbookTypeOptionContainer
						key={index}
						onClick={() => navigate(option.next)}>
						<LogbookTypeOptionIcon>
							<img src={option.icon} alt={`${option.title} logbook`} />
						</LogbookTypeOptionIcon>

						<LogbookTypeOptionTitle>{option.title}</LogbookTypeOptionTitle>

						<Chevron>
							<img src={chevronIcon} alt="chevron" />
						</Chevron>
					</LogbookTypeOptionContainer>
				))}

				<button
					className="button button-link button-big full-width margin-top-4"
					onClick={() => {
						if (key !== 'default') {
							navigate(-1);
						} else {
							navigate(backPath);
						}
					}}>
					Cancel
				</button>
			</FiftyFifty>
		</DocumentTitle>
	);
}
