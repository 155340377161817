import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MoreMenu, {
  ADD_LINK_TO_FOLDER_MENU_ITEM,
  DELETE_FOLDER_MENU_ITEM,
  EDIT_FOLDER_MENU_ITEM,
  SHARE_FOLDER_MENU_ITEM,
  UPLOAD_FILES_TO_FOLDER_MENU_ITEM,
} from "../../components/more-menu";
import { isCurrentUserACompany } from "../../store/features/user.slice";
import DocumentList from "./document-list";
import { useSelector } from "react-redux";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../../routes";
import SharedFolderPermissions from "../../components/shared-folder-permissions";
import ShareLogbookOrContent from "../../components/share-logbook-or-content";
import { useState } from "react";
import Modal from "../../components/modal";
import { Form } from "react-final-form";
import { FoldersApi } from "../../api/folders";
import { removeLogbookFolder } from "../../store/features/logbooks.slice";
import { useDispatch } from "react-redux";
import { handleError } from "../../components/helpers";
import { ClipLoader } from "react-spinners";

const PrivateFolderWarningContainer = styled.div`
  background-color: #203d5e;
  padding: 16px 24px;
  border-radius: 6px;
  color: #fff;
  margin-top: 16px;
  font-style: italic;
`;

/**
 * @typedef {Object} LogbookFolderProps
 * @property {inndox.Folder} folder
 * @property {inndox.LogbookDocument[]} documents
 * @property {boolean} [isInsideATemplate]
 * @property {boolean} canEdit
 */

/**
 *
 * @param {LogbookFolderProps} props
 */
export default function LogbookFolder({
  folder,
  documents,
  canEdit,
  isInsideATemplate,
}) {
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const navigate = useNavigate();
  const [wantsToShareFolder, setWantsToShareFolder] = useState(false);
  const [wantsToDeleteFolder, setWantsToDeleteFolder] = useState(false);
  const dispatch = useDispatch();
  const { key, pathname } = useLocation();

  const backPath =
    key !== "default" ? -1 : pathname.replace(`/folder/${folder.id}`, "");

  const actionSelectedMenuOption = (value) => {
    switch (value) {
      case EDIT_FOLDER_MENU_ITEM.value: {
        // edit folder
        if (isCompanyUser) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              CompanyRoutes.editFolder.replace(":folderId", folder.id)
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              OwnerRoutes.editFolder.replace(":folderId", folder.id)
          );
        }
        break;
      }

      case DELETE_FOLDER_MENU_ITEM.value: {
        // delete folder
        setWantsToDeleteFolder(true);
        break;
      }

      case UPLOAD_FILES_TO_FOLDER_MENU_ITEM.value: {
        // add files to folder
        if (isCompanyUser) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              CompanyRoutes.addLogbookFolderDocuments.replace(
                ":folderId",
                folder.id
              )
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              OwnerRoutes.addLogbookFolderDocuments.replace(
                ":folderId",
                folder.id
              )
          );
        }
        break;
      }

      case ADD_LINK_TO_FOLDER_MENU_ITEM.value: {
        // add link to folder folder
        if (isCompanyUser) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              CompanyRoutes.addLogbookFolderLink.replace(":folderId", folder.id)
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              OwnerRoutes.addLogbookFolderLink.replace(":folderId", folder.id)
          );
        }
        break;
      }

      case SHARE_FOLDER_MENU_ITEM.value: {
        // share folder
        setWantsToShareFolder(true);
        break;
      }

      default:
        break;
    }
  };

  const menuOptions = [];
  if (folder.canUpload) {
    menuOptions.push(ADD_LINK_TO_FOLDER_MENU_ITEM);
  }
  if (canEdit) {
    menuOptions.push(EDIT_FOLDER_MENU_ITEM);
  }
  if (folder.canDelete) {
    menuOptions.push(DELETE_FOLDER_MENU_ITEM);

    if (!folder.isPrivate && !isInsideATemplate) {
      menuOptions.push(SHARE_FOLDER_MENU_ITEM);
    }
  }

  return (
    <DocumentTitle title={`${folder.name} | inndox`}>
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={12} md={6} className="flex col margin-bottom-2">
            <h2 className="name">{folder.name}</h2>
            {folder.description ? (
              <p className="description">{folder.description}</p>
            ) : null}
          </Col>

          <Col xs={12} md={6} className="flex end margin-bottom-2">
            {folder.canUpload ? (
              <button
                className="button button-secondary margin-right-2"
                onClick={() => {
                  if (isCompanyUser) {
                    if (isInsideATemplate) {
                      navigate(TemplateRoutes.addDocumentsToTemplate);
                    } else {
                      navigate(CompanyRoutes.addLogbookDocuments);
                    }
                  } else {
                    navigate(OwnerRoutes.addLogbookDocuments);
                  }
                }}
              >
                Upload file
              </button>
            ) : (
              <noscript />
            )}

            <MoreMenu
              options={menuOptions}
              onSelect={actionSelectedMenuOption}
            />
          </Col>

          {/* Info note about private folder */}
          <Col xs={12}>
            {folder.isPrivate ? (
              <PrivateFolderWarningContainer>
                This is a private folder. The contents of this folder will not
                be transferred after the handover.
              </PrivateFolderWarningContainer>
            ) : (
              <noscript />
            )}
          </Col>
        </Row>

        {folder.canDelete ? (
          <SharedFolderPermissions
            permissions={folder.sharePermissions}
            folderId={folder.id}
            folderName={folder.name}
          />
        ) : (
          <noscript />
        )}

        <DocumentList
          documents={documents}
          documentsAreInsideAFolder
          showTitle
          isInsideATemplate={isInsideATemplate}
        />

        <ShareLogbookOrContent
          isOpen={wantsToShareFolder}
          onClose={() => setWantsToShareFolder(false)}
          folder={folder}
        />

        <Modal
          isOpen={wantsToDeleteFolder}
          title="Delete folder?"
          onClose={() => setWantsToDeleteFolder(false)}
        >
          <p className="margin-top-2">
            If you delete the folder, any files within the folder will be
            archived. Are you sure you want to proceed?
          </p>

          <div className="flex end margin-top-3">
            <button
              className="button button-alt margin-right-2"
              onClick={() => setWantsToDeleteFolder(false)}
            >
              Cancel
            </button>
            <Form
              onSubmit={async () => {
                try {
                  await FoldersApi.removeFolder(folder.propertyId, folder.id);

                  setWantsToDeleteFolder(false);

                  navigate(backPath);

                  dispatch(removeLogbookFolder(folder.id));
                } catch (e) {
                  handleError(e);
                }
              }}
              render={(props) => (
                <button
                  className="button button-archive"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#FF3E3E" />
                  ) : (
                    "Delete"
                  )}
                </button>
              )}
            />
          </div>
        </Modal>
      </Container>
    </DocumentTitle>
  );
}
