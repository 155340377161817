import { Field } from "react-final-form";
import styled from "styled-components";
import Error from "./error";
import Label, { Description } from "./label";
import Link from "./link";

const InputContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
`;

const Input = styled.input`
  width: 100%;
  background-color: #fff;
  border: 1px solid #dde0e3;
  font-size: 16px;
  color: #2d3540;
  padding: 15px 8px;
  line-height: 20px;
  caret-color: #bdd23f;
  border-radius: 4px;

  &:focus {
    border-color: #68c9d6;
  }

  &:disabled {
    background-color: #f7f9fc;
    cursor: not-allowed;
  }

  &.error {
    border-color: #ff3e3e;
  }
`;

const MoreInfoContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export default function TextField({
  label,
  description,
  name,
  type,
  validate,
  required,
  placeholder,
  disabled,
  icon,
  onKeyDown,
  className,
  moreInfo,
  tooltip,
  onFocus,
  onBlur,
  autoComplete,
}) {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const shouldDisplayError = meta.touched && meta.error;

        return (
          <div className={`form-element ${className}`}>
            <Label label={label} required={required} tooltip={tooltip} />
            {description && <Description>{description}</Description>}
            <InputContainer className="flex align-center">
              {icon ? <IconContainer>{icon}</IconContainer> : <noscript />}
              <Input
                {...input}
                type={type}
                className={shouldDisplayError ? "error" : undefined}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                style={
                  icon
                    ? {
                        paddingLeft: 48,
                      }
                    : undefined
                }
              />
            </InputContainer>
            {shouldDisplayError && <Error error={meta.error} invalid />}
            {moreInfo ? (
              <MoreInfoContainer>
                <Link
                  title={moreInfo.title}
                  link={moreInfo.link}
                  tabIndex={-1}
                />
              </MoreInfoContainer>
            ) : (
              <noscript />
            )}
          </div>
        );
      }}
    </Field>
  );
}
