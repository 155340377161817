import DocumentTitle from "react-document-title";
import { Container, Row, Col } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import { OwnerRoutes } from "../../routes";
import moment from "moment";
import DocumentList from "../common/document-list";
import { useAuth } from "../../components/authentication";

export default function OwnerLogbookWarranties() {
  const navigate = useNavigate();
  const logbook = useSelector(selectCurrentLogbook);
  const documents = logbook.documents;
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook =
    session.ownerId !== logbook.ownerId;

  const now = moment();
  const documentsWithWarranty = documents
    .filter(
      (document) =>
        document.warrantyExpiresOn &&
        moment(document.warrantyExpiresOn).unix() > now.unix()
    )
    .sort(
      (a, b) =>
        moment(a.warrantyExpiresOn).unix() - moment().unix(b.warrantyExpiresOn)
    );

  return (
    <DocumentTitle title="Logbook Warranties | inndox">
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={12} md={6} className="flex margin-bottom-2">
            <h2 className="name">Logbook Warranty Reminders</h2>
          </Col>

          <Col xs={12} md={6} className="flex end margin-bottom-2">
            {isCurrentUserAReadOnlyMemberForLogbook ? (
              <noscript />
            ) : (
              <button
                className="button button-secondary margin-right-2"
                onClick={() =>
                  navigate(
                    "/" +
                      OwnerRoutes.basePath.replace("/*", "") +
                      "/" +
                      OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
                      "/" +
                      OwnerRoutes.newWarranty
                  )
                }
              >
                New warranty
              </button>
            )}
          </Col>
        </Row>

        <DocumentList documents={documentsWithWarranty} />
      </Container>
    </DocumentTitle>
  );
}
