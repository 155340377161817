import { Route, Routes } from 'react-router-dom';
import {
	AccountRoutes,
	CompanyRoutes,
	OwnerRoutes,
	PublicRoutes,
	SharedRoutes,
} from '.';
import { RequireAuth } from '../components/authentication';
import PageLayout from '../components/page-layout';
import RouteDecider from '../pages/others/route-decider';
import AccountSiteMap from './account-site-map';
import CompanySiteMap from './company-site-map';
import OwnerSiteMap from './owner-site-map';
import SharedSiteMap from './shared-site-map';
import PublicSiteMap from './public-site-map';

export default function SiteMap() {
	return (
		<Routes>
			<Route path="/*" element={<PageLayout />}>
				{/* ******* */}
				{/* Account */}
				{/* ******* */}
				<Route path={AccountRoutes.basePath} element={<AccountSiteMap />} />

				{/* ******* */}
				{/* Company */}
				{/* ******* */}
				<Route
					path={CompanyRoutes.basePath}
					element={
						<RequireAuth>
							<CompanySiteMap />
						</RequireAuth>
					}
				/>

				{/* ***** */}
				{/* Owner */}
				{/* ***** */}
				<Route
					path={OwnerRoutes.basePath}
					element={
						<RequireAuth>
							<OwnerSiteMap />
						</RequireAuth>
					}
				/>

				{/* ****** */}
				{/* Shared */}
				{/* ****** */}
				<Route
					path={SharedRoutes.basePath}
					element={
						<RequireAuth>
							<SharedSiteMap />
						</RequireAuth>
					}
				/>

				{/* ****** */}
				{/* Unauthenticated/Public */}
				{/* ****** */}
				<Route path={PublicRoutes.basePath} element={<PublicSiteMap />} />

				{/* Anything else */}
				<Route path="*" element={<RouteDecider />} />
			</Route>
		</Routes>
	);
}
